export const nomeSistemas = [
  'reinaldoalguz',
  'enricomisasi',
  'rogeriossantos',
  'taniafruchi',
  'libcom',
  'arco',
  'infogab',
];

export const sistemasDeputados = [
  'reinaldoalguz',
  'enricomisasi',
  'rogeriossantos',
  'taniafruchi',
];

export const sistemasInstitutos = [
  'libcom',
  'arco',
];
