import React from 'react';

export const TEXTO_SPINNER_CPF = <>
Aguarde um momento, <br />validando CPF...
</>;

export const TEXTO_SPINNER_INSCRICAO = <>
Aguarde um momento, <br />concluindo inscrição...
</>;

export const TEXTO_SPINNER_PAGAMENTO_PENDENTE = <>
Aguardando concluir o pagamento...
</>;

export const TEXTO_CPF_INVALIDO = 'Esse CPF não está associado ao convite.';

export const TEXTO_SERVER_ERROR = 'Ops... ocorreu um erro no servidor, por favor tente novamente mais tarde.'

export const TEXTO_NAO_PERMITE_INSCRICAO = 'Ops... As inscrições para este evento foram encerradas. Caso tenha alguma dúvida entre em contato com o Libcom.';