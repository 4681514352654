import React, { memo } from 'react';

interface IProps {
  vereador: {
    sexo: number | null
    apelido: string | null
  }
}

const TextoVereador = ({ vereador }: IProps) => {

return (
  <>
    Ao prosseguir, você será cadastrado gratuitamente
    na base de dados {vereador.sexo === 1 ? 'do' : 'da'} <strong>{vereador.apelido}</strong>.
    <br /><br />
    Ao clicar em <strong>ACEITO</strong>, você manifesta expressa concordância
    e aderência com a "Política de Privacidade de Dados", bem como nos fornece seu consentimento,
    para coletar, usar, processar, armazenar, ceder, tratar seus dados pessoais.
  </>
);
} 

export default memo(TextoVereador);
