import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Switch, Route, useParams, Redirect, useHistory } from 'react-router-dom';

import { Celular, Email } from '../../paginas/Formulario/Contatos';
import { Cpf, DataNascimento, Escolaridade, EstadoCivil, Nome } from '../../paginas/Formulario/Dados';
import { Endereco } from '../../paginas/Formulario/Endereco';
import { TermosDeAdesao } from '../../paginas/Formulario/Termos';

import { getDominio, getSchema } from '../../_recursos/js/util';

import ArcoIcon from '../../_recursos/imagens/arco-icon.svg';
import LibcomIcon from '../../_recursos/imagens/libcom-icon.svg';

import { ITermo } from 'Types/Reducers/Termos';

import { Inicial, Final, Etapas } from './paginas';
import { IStoreRedux } from 'Types/Reducers';

interface IRouterParams {
  hash: string,
}

export default function Voluntariado() {
  const stateApp = useSelector((state: IStoreRedux) => state.app);

  const { hash } = useParams<IRouterParams>();
  const match = useRouteMatch();
  const historicoNavegacao = useHistory();

  const [schema, setSchema] = useState<string>('');
  const [termo, setTermo] = useState<ITermo | null>(null);
  const [icone, setIcone] = useState<string | null>(null);

  const etapas = useMemo(() => (
    [
      { path: '/termo/voluntariado/formulario/dados/cpf', component: <Cpf/>},
      { path: '/termo/voluntariado/formulario/dados/nome', component: <Nome/>},
      { path: '/termo/voluntariado/formulario/dados/data-nascimento', component: <DataNascimento/>},
      { path: '/termo/voluntariado/formulario/dados/estado-civil', component: <EstadoCivil/>},
      { path: '/termo/voluntariado/formulario/dados/escolaridade', component: <Escolaridade/>},
      { path: '/termo/voluntariado/formulario/contatos/celular', component: <Celular/>},
      { path: '/termo/voluntariado/formulario/contatos/email', component: <Email obrigatorio />},
      { path: '/termo/voluntariado/formulario/endereco', component: <Endereco/>},
      { path: '/termo/voluntariado/formulario/termos-de-adesao', component: <TermosDeAdesao />},
      { path: '/termo/voluntariado/confirmacao', component: <Redirect to='/termo/voluntariado/confirmacao' push/>},
    ]
  ), []);

  const init = useCallback(() => {
    document.title = 'Voluntariado';
    const dominio = getDominio();

    switch (dominio) {
      case 'arco':
        setSchema('arco');
        setIcone(ArcoIcon);
        break;

      case 'libcom':
        setSchema('libcom');
        setIcone(LibcomIcon);
        break;

      default:
        const codigoReferencia = stateApp.hash
          ? stateApp.hash.substring(0, 6)
          : stateApp.referencia;

        setSchema(getSchema(codigoReferencia?.substr(0, 6)))
        break;
    }
  }, []);

  const capturaUri = useMemo(() => historicoNavegacao.location.pathname, [historicoNavegacao.location.pathname])

  const capturaEtapaAtual = useCallback((): IState => {
    let etapaSelecionada = etapas.filter((etapa: any, index) => {
      if (etapa.path === capturaUri) {
        etapa.posicao = index;
        return etapa;
      }
    })[0];
    if (etapaSelecionada && Object.keys(etapaSelecionada).length)
      return etapaSelecionada;
    else
      return { posicao: 0, component: <Redirect to={etapas[0].path} push/> }
  }, [capturaUri, etapas]);

  const appConfig = {
    schema,
    referencia: hash,
    icone,
    termo,
    etapaPath: etapas[0].path,
    setTermo,
    init,
  }

  return (
    <Switch>
      {/* Página de Confirmação */}
      <Route
        path="/termo/voluntariado/confirmacao"
        render={() => <Final config={appConfig} />}
      />

      <Route
        path={`${match.path}/`}
        render={() => <Inicial config={appConfig} />}
        exact
      />

      {/* Etapas */}
      <Route path={capturaEtapaAtual().path} exact>
        <Etapas etapas={etapas} etapaAtual={capturaEtapaAtual()} />
      </Route>
    </Switch>
  )
}