import React, { useContext, useReducer, useState } from 'react';

import { Route, useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import type { IStoreRedux } from 'Types/Reducers';

import { Container, Content, Toolbar } from '../../../../componentes/Interface';

import { formataEtapas } from '../config';
import { reducerEtapas } from './reducer';

import InscricaoContext from '../../context';
import Final from '../Final';
import InscricaoHashLink from '../InscricaoHashLink';
import InscricaoHashEvento from '../InscricaoHashEvento';
import InscricaoHashConvite from '../InscricaoHashConvite';
import InscricaoPadrao from '../InscricaoPadrao';
import Inicial from '../Inicial';

import ArcoIcon from '../../../../_recursos/imagens/arco-icon.svg';
import LibcomIcon from '../../../../_recursos/imagens/libcom-icon.svg';
import InscricaoComCpf from '../InscricaoComCpf';

function getEtapaInscricao(tipohash: string) {
  switch (tipohash) {
    case 'hashlink': return <InscricaoHashLink />;
    case 'hashevento': return <InscricaoHashEvento />;
    case 'hashconvite': return <InscricaoHashConvite />;
    default:
      return <InscricaoPadrao />;
  }
}

function EstruturaEtapas() {
  const inscrito = useSelector((state: IStoreRedux) => state.app.inscrito);
  const appCarregando = useSelector((state: IStoreRedux) => state.app.carregando);
  const etapaAtual = useSelector((state: IStoreRedux) => state.app.etapaAtual);
  const etapaValida = useSelector((state: IStoreRedux) => state.app.etapaValida);
  const history = useHistory();

  const { etapas, inscricao } = useContext(InscricaoContext);

  const [tentarInscricaoComCpf, setTentarInscricaoComCpf] = useState(false);

  const [controle, dispatchEtapas] = useReducer(
    reducerEtapas,
    { posicao: -1 },
    (arg) => ({ ...arg, etapas: formataEtapas(etapas) }),
  );

  function navegaParaEtapaAnterior() {
    dispatchEtapas({ type: 'VOLTAR' });
    history.goBack()
  }

  function navegaParaEtapaSeguinte() {
    const proxEtapa = controle.posicao + 1;

    const tentarPorCpf = (
      etapas.cpf === 'inscricao-com-cpf'
      && (
        (etapaAtual === 'cpf')
        || (controle.solicitacoes && proxEtapa >= controle.solicitacoes.length)
      )
    );
    if (tentarPorCpf) return setTentarInscricaoComCpf(true);

    dispatchEtapas({ type: 'AVANCAR' });

    if (controle.solicitacoes && (0 <= proxEtapa && proxEtapa < controle.solicitacoes.length))
      return history.push(controle.solicitacoes[proxEtapa].path);

    if (0 <= proxEtapa && proxEtapa < controle.etapas.length)
      return history.push(controle.etapas[proxEtapa].path);
  }

  const instituicao = /arco|libcom/.test(inscricao.schema);

  const icone = (
    instituicao
      ? (inscricao.schema === 'arco' ? ArcoIcon : LibcomIcon)
      : undefined
  );

  if (tentarInscricaoComCpf) {
    return (
      <InscricaoComCpf
        dispatchEtapas={dispatchEtapas}
        setTentarInscricaoComCpf={setTentarInscricaoComCpf}
        continuarFluxo={() => {
          dispatchEtapas({ type: 'AVANCAR' });
          history.push(controle.etapas[controle.posicao + 1].path);
        }}
      />
    );
  }

  if (controle.posicao < 0) {
    return <Inicial avancar={navegaParaEtapaSeguinte} icone={icone} />
  }

  if (!inscrito && controle.posicao >= controle.etapas.length) {
    return getEtapaInscricao(inscricao.tipoHash);
  }

  if (inscrito) return <Final icone={icone} />;

  return (
    <Container>
      {/* Toolbar */}
      <Content>
        <Toolbar>
          {controle.posicao > 0 &&
            <IconButton onClick={navegaParaEtapaAnterior}>
              <ArrowBack />
            </IconButton>
          }
          {appCarregando && <CircularProgress color='primary' size={24}/>}
        </Toolbar>
      </Content>
      <Content full padding pb="3rem">
        {/* Campo do Input */}
        {
          (controle.solicitacoes && controle.solicitacoes.length > 0)
            ? controle.solicitacoes.map((solicitacao: any) => <Route {...solicitacao} key={solicitacao.path} />)
            : controle.etapas.map((etapa: any) => <Route {...etapa} key={etapa.path} />)
        }
        <br/><br/>

        <Button
          disabled={etapaValida !== true}
          size='large'
          variant='contained'
          color='primary'
          onClick={navegaParaEtapaSeguinte}
        >
          Continuar
        </Button>
      </Content>
    </Container>
  )
}

export default EstruturaEtapas;
