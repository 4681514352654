import React, { memo, MouseEventHandler, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';

import { Container, Content, Text, Title } from '../Interface';

import useStyles from './styles';
import MuiDialog from '../MuiDialog';

interface ITermo {
  icone?: any,
  textoTermo: any,
  rodape?: any,
  acaoBotaoPrimario: MouseEventHandler<HTMLButtonElement>,
  botaoPrimario: string,
  acaoBotaoSecundario?: MouseEventHandler<HTMLButtonElement>,
  botaoSecundario?: string,
  conteudoPoliticaDePrivacidade?: any,
}

function Termo({  
  icone,
  textoTermo, 
  rodape,
  acaoBotaoPrimario, 
  botaoPrimario, 
  acaoBotaoSecundario, 
  botaoSecundario,
  conteudoPoliticaDePrivacidade,
}: ITermo) {

  const stateApp = useSelector((state: any) => state.app);

  const classes = useStyles({ referencia: stateApp.referencia } as any);
  const [abrir, setAbrir] = useState(false);

  
  const abreDialog = useCallback(() => {
    setAbrir(true);
  }, [setAbrir]);
  
  return (
    <>
      {/* Concluido */}
        <Container>
          <Content full verticalCenter horizontalCenter padding pb='70px'>
            {icone &&
              <img src={icone} alt={icone} className={classes.icone} />
            }
            <Title gutterBottom>Olá,</Title>

            <Text gutterBottom align='center'>
              {textoTermo && textoTermo}
            </Text>
            
            {conteudoPoliticaDePrivacidade && 
              <>
                <Text gutterBottom align='center'>
                  Saiba mais em nossa: &nbsp;
                  <Button 
                    className={classes.politica}
                    onClick={abreDialog}
                  >Política de Privacidade de Dados</Button>
                </Text>
                <MuiDialog 
                  titulo="Política de Privacidade de Dados"
                  conteudo={conteudoPoliticaDePrivacidade}
                  abrir={abrir} 
                  setAbrir={setAbrir}
                />
              </>
            }

            <br />

            <Button
              fullWidth
              disabled={stateApp.etapaValida !== true}
              size='large'
              variant='contained'
              color='primary'
              onClick={acaoBotaoPrimario}>
              {botaoPrimario}
            </Button>
            <br />
            {botaoSecundario && 
              <Button
                fullWidth
                size='large'
                color='primary'
                onClick={acaoBotaoSecundario}>
                {botaoSecundario}
              </Button>
            }

            {rodape && rodape}
          </Content>
        </Container>
    </>
  );
}

export default memo(Termo);