import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';

import { primeiraMaiusculaTodasPalavras } from '../../../_recursos/js/util';

export default function Nome() {
  const valor = useSelector(state => state.dados.nome);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);
  let valorAlterado = null;

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'nome' }), [dispatch]);

  function altera(event) {
    valorAlterado = primeiraMaiusculaTodasPalavras(event.target.value);
    dispatch({ type: `ALTERA_NOME`, nome: valorAlterado });
    valida(valorAlterado);
  }

  function valida(valor) {
    const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}\s([A-Za-z\s]{2,3})?[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']{4,}$/;
    const resultado = regex.test(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <TextField
        fullWidth
        autoFocus
        label='Informe seu nome completo'
        error={valido !== null && !valido}
        value={valor || ''}
        onInput={altera}
        autoComplete="name"
      />
    </div>
  );
}
