import React from 'react';
import { Container, Content, Title, Text } from '../../../../componentes/Interface';

import { Button } from '@material-ui/core';

function RespostaInesperada({ retry }: any) {

  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom>Ops...</Title>
        <Text gutterBottom align='center'>
          Parece que o servidor respondeu de forma inesperada.
        </Text>
        <br/>

        <Button
          variant='outlined'
          color='inherit'
          onClick={retry}>
            TENTAR NOVAMENTE
        </Button>
      </Content>
    </Container>
  )
}

export default RespostaInesperada;
