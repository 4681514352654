import styled from "styled-components";
import { InputAdornment, Typography } from "@material-ui/core";

// import '../../util/cssGlobal/configuracao/cores.css';
// import '../../util/cssGlobal/configuracao/espacamento.css';

// TODO: crie componentes globais para cores, typografia, (mui ??).
// TODO: crie componente wrapper.

interface IContainerProps {
  readonly hidden?: boolean;
  readonly row?: boolean;
  readonly padding?: boolean;
  readonly base?: boolean;
  readonly verticalCenter?: boolean;
  readonly horizontalCenter?: boolean;
}
export const Container = styled.section<IContainerProps>`
  display: ${ props => props.hidden ? 'none' : 'flex' };
  flex: 1;
  flex-direction: ${ props => props.row ? 'row' : 'column' };
  flex-wrap: wrap;
  padding: ${ props => props.padding ? '5vmin' : 'initial' };
  margin: 0 auto;
  min-height: 100vh;
  font-size: 1rem;

  /* TODO: converter em funcao */
  /* max-width: 500px; */

  /* Estica conteudo */
  ${props => props.base && `> * { flex: 1; }`}

  /* Centraliza Vertical */
  ${props => props.verticalCenter && `justify-content: center;`}

  /* Centraliza Horizontal */
  ${props => props.horizontalCenter && `align-items: center;`}

  /* Normalize */
  Button {
    font-size: inherit;
  }
  .MuiTextField-root {
    margin-bottom: 10px;
  }


  /* Temp */
  code {
    opacity: 0.5;
    display: block;
    margin-top: 50px;
  }

  /* Debug */
  /* > * {
    outline: 2px dashed green;
  } */
`;

interface IContentProps extends IContainerProps {
  readonly pb?: string,
  readonly mw?: React.CSSProperties['maxWidth'],
  readonly full?: boolean
}
export const Content = styled.div<IContentProps>`
  display: ${ props => props.hidden ? 'none' : 'flex' };
  /* flex: 1; */
  flex-direction: ${ props => props.row ? 'row' : 'column' };
  flex-wrap: ${ props => props.row ? 'wrap' : 'no-wrap' };
  padding: ${ props => props.padding ? '2rem' : 'initial' };
  padding-bottom: ${ props => props.pb ? props.pb : 'initial' };
  margin: 0 auto;
  font-size: 1rem;

  /* TODO: converter em funcao */
  width: 100%;
  max-width: ${props => props.mw ? props.mw : '500px'};

  /* Ocupa todo espaço disponivel */
  ${props => props.full && `flex: 1;`}

  /* TODO: montar fullbleed */
  /* position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto; */

  /* Estica conteudo */
  ${props => props.base && `> * { flex: 1; }`}

  /* Centraliza Vertical */
  ${props => props.verticalCenter && `justify-content: center;`}

  /* Centraliza Horizontal */
  ${props => props.horizontalCenter && `align-items: center;`}
`;

export const Title = styled(Typography).attrs(props => ({ ...props, variant: (props.variant || 'h4') }))``;

interface ISubtitleProps {
  readonly fw?: string
}

export const Subtitle = styled(Typography).attrs(props => (
  { ...props, variant: 'h6' }))<ISubtitleProps>`
    ${(props) => props.fw && `
      font-size: 1.1rem;
      font-weight: ${props.fw};
    `}
  `;

interface ITextProps {
  readonly light?: boolean,
}

export const Text = styled(Typography).attrs(props => ({ ...props, variant: 'body1'}))<ITextProps>`
  ${props => props.light && ` opacity: 0.5;`}
`;

export const TextSmall = styled(Typography).attrs(props => ({ ...props, variant: 'body2' }))``;

export const Toolbar = styled.header`
  display: flex;
  align-items: center;
  min-height: 3rem;

  .MuiCircularProgress-root {
    position: fixed;
    right: 15px;
  }
`;

export const InputSuffix = styled(InputAdornment).attrs(props => ({ position: props.position}))`
  p {
    opacity: 0.5;
    font-size: 0.8rem;
  }
`;

// $mdc-typography-styles-headline1: ( font-size: 96 );
// $mdc-typography-styles-headline2: ( font-size: 60 );
// $mdc-typography-styles-headline3: ( font-size: 48 );
// $mdc-typography-styles-headline4: ( font-size: 34 );
// $mdc-typography-styles-headline5: ( font-size: 24 );
// $mdc-typography-styles-headline6: ( font-size: 20 );
// $mdc-typography-styles-body1: ( font-size: 16 );
// $mdc-typography-styles-body2: ( font-size: 14 );
// $mdc-typography-styles-subtitle1: ( font-size: 16 );
// $mdc-typography-styles-subtitle2: ( font-size: 14 );
// $mdc-typography-styles-button: ( font-size: 14 );
// $mdc-typography-styles-overline: ( font-size: 10 );
// $mdc-typography-styles-caption: ( font-size: 12 );