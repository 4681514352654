import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from "react-redux";

import Checkbox from '@material-ui/core/Checkbox';

import { getTermos } from "../../../../_recursos/js/util";

import { TextSmall } from '../../../../componentes/Interface';

import VerTermo from '../componentes/AbrirTermo';
import { FormTermo } from '../styles';

import { detectaDispositivo } from '../../../../_recursos/js/util';

interface AceiteTermo {
  idTermo: number;
  idTermoTipo: number;
  texto: string;
}

function TermosEscola() {
  const dispatch = useDispatch();

  const [aceiteTermos, setAceiteTermos] = useState<AceiteTermo[]>([]);

  const [privacidade, setPrivacidade] = useState(false);
  const [participacao, setParticipacao] = useState(false);

  const carregando = useRef(true);
  useEffect(() => {
    Promise.all([
      getTermos(1, 'libcom'),
      getTermos(8, 'libcom'),
    ])
    .then(resultado => {
      const termosValidos = resultado
        .filter(itemTermo => !Array.isArray(itemTermo));

      setAceiteTermos(termosValidos);
    })
    .finally(() => { carregando.current = false; });
  }, [dispatch]);

  useEffect(() => {
    const validado = privacidade && participacao;
    dispatch({ type: 'VALIDA_CAMPO', termos: validado });
    if (validado) {
      const dispositivo = detectaDispositivo();
      const termosAceitos = aceiteTermos.map(termo => ({
        idTermo: termo.idTermo,
        idTermoTipo: termo.idTermoTipo,
        aceito: true,
        dispositivo,
      }));

      dispatch({ type: 'ALTERA_TERMOS', termos: termosAceitos });
    }
  }, [aceiteTermos, dispatch, participacao, privacidade]);

  return (
    <>
      <FormTermo
        label={
          <TextSmall>
            Aceito os termos da
            {' '}
            <VerTermo
              nome="Política de Privacidade"
              termo={aceiteTermos.find(item => item.idTermoTipo === 1)}
            />
          </TextSmall>
        }
        control={
          <Checkbox
            color="primary"
            onChange={(_, checked) => setPrivacidade(checked)}
            value={privacidade}
          />
        }
      />
      <FormTermo
        label={
          <TextSmall>
            Declaro ter lido e aceito o
            {' '}
            <VerTermo
              nome="Termo de participação"
              termo={aceiteTermos.find(item => item.idTermoTipo === 8)}
            />
          </TextSmall>
        }
        control={
          <Checkbox
            color="primary"
            onChange={(_, checked) => setParticipacao(checked)}
            value={participacao}
          />
        }
      />
    </>
  )
}

export default TermosEscola;