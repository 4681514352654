import { Theme, makeStyles } from '@material-ui/core/styles';

interface IStyles {
  [key: string]: any,
}

const useStyles = makeStyles<Theme, IStyles>(() => ({
  icone: {
    marginBottom: (props:any) => props.referencia?.match(/^(libcom|arco)$/) ? '32px' : '0.2em',
    width: (props:any) => props.referencia === 'libcom' ? '100px' : props.referencia === 'arco' ? '120px' : '90vw',
    maxWidth: '400px',
  },
  politica: {
    textTransform: 'initial',
    textDecoration: 'underline',
    padding: '0',
  }
} as IStyles));

export default useStyles;