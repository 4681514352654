import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { IStoreRedux } from 'Types/Reducers';

function ComoConheceu() {
  const valor = useSelector<IStoreRedux, string>(state => state.dados.como_conheceu || '');
  const dispatch = useDispatch()

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'como_conheceu' }), [dispatch]);

  const aoAlterar = useCallback((e) => {
    dispatch({ type: 'ALTERA_COMO_CONHECEU', como_conheceu: e.target.value })
  }, [dispatch])

  return (
    <div className="etapa-formulario">
      <TextField
        select
        fullWidth
        label="Como nos conheceu?"
        value={valor}
        onChange={aoAlterar}
      >
        <MenuItem value="Google">Google</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Youtube">Youtube</MenuItem>
        <MenuItem value="Amigo ou Familiar">Amigo ou Familiar</MenuItem>
        <MenuItem value="Outro">Outro</MenuItem>
      </TextField>
    </div>
  )
}

export default ComoConheceu