import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { post } from '../../../../_axios/requisicao';

import { Button, CircularProgress, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';


import { Container, Content, Toolbar } from '../../../../componentes/Interface';

import Spinner from '../../../../componentes/Spinner';
import Toast from '../../../../componentes/Toast';

import { detectaDispositivo } from '../../../../_recursos/js/util';
import { IStoreRedux } from 'Types/Reducers';

export default function Etapas({ etapas, etapaAtual, referencia }: any) {
  const dispatch = useDispatch();
  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const provedor = useSelector((state: IStoreRedux) => state.app.provedor);
  const uid = useSelector((state: IStoreRedux) => state.app.uid);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);

  const historicoNavegacao = useHistory();

  const [posicaoEtapaAtual, setPosicaoEtapaAtual] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [exibeMensagemErro, setExibeMensagemErro] = useState<boolean>(false);
  const [mensagemErro, setMensagemErro] = useState<string>('');


  const resetApp = useCallback(() => {
    const idUsuario = 0;

    dispatch({ type: 'RESET_APP' });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_EVENTO' });
    dispatch({ type: 'ALTERA_IDUSUARIO', idUsuario });
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
  }, [dispatch]);

  const cadastrarPessoa = useCallback(async () => {

    const termos = [
      {
        idTermo: 6,
        idTermoTipo: 1,
        aceito: true,
        dispositivo: detectaDispositivo(),
      }
    ];

    const inscricao = { contatos, uid, provedor, dados, endereco, termos };

    const uriInscricaoAplicativo = '/inscricoes/aplicativo';

    const cabecalho = {
      schema: referencia,
      referencia: referencia.substr(2,6)
    }

    setSpinner(true);
    try {
      const retorno = await post('api-pessoas', uriInscricaoAplicativo, inscricao, cabecalho);
      if (retorno && retorno.status === 200) {

        setSpinner(false);
        setExibeMensagemErro(false);
        return true
      } else {
        return false;
      }
    } catch (error) {
      setExibeMensagemErro(true);
      setMensagemErro(error.error);
      setSpinner(false);
      return false;
    }
  }, [contatos, dados, endereco, provedor, referencia, uid]);

  const navegaParaEtapaSeguinte = useCallback(async () => {
    const uriEndereco = '/acesso-aplicativo/formulario/endereco';

    if (etapas[posicaoEtapaAtual].path === uriEndereco) {
      const resultadoCadastroPessoa = await cadastrarPessoa();

      if (resultadoCadastroPessoa) {
        window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
        resetApp();
        setSpinner(false);
      } else {
        setExibeMensagemErro(true);
        setMensagemErro('Ocorreu um erro no servidor. Tente novamente mais tarde.');
        return;
      }
    }

    if (posicaoEtapaAtual === etapas.length - 1) return;

    const posicaoEtapa = (posicaoEtapaAtual + 1);
    const urlDestino = etapas[posicaoEtapa].path;
    historicoNavegacao.push(urlDestino);
    setPosicaoEtapaAtual(posicaoEtapa);
  }, [cadastrarPessoa, etapas, historicoNavegacao, posicaoEtapaAtual, resetApp]);

  const navegaParaEtapaAnterior = useCallback(() => {
    if (posicaoEtapaAtual <= 0) return;
    const posicaoEtapa = (posicaoEtapaAtual - 1);
    setPosicaoEtapaAtual(posicaoEtapa);
    historicoNavegacao.goBack();
  }, [historicoNavegacao, posicaoEtapaAtual]);

  useEffect(() => {
    setPosicaoEtapaAtual(etapaAtual.posicao);
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, []);

  const TEXTO_SPINNER = <>
  Aguarde um momento, <br />estamos realizando o seu cadastro...
</>;

  return (
    <Container>
      {spinner && <Spinner texto={TEXTO_SPINNER} />}

      {/* Toolbar */}
      <Content>
        <Toolbar>
          {posicaoEtapaAtual > 0 &&
            <IconButton onClick={navegaParaEtapaAnterior}>
              <ArrowBack />
            </IconButton>
          }
          {stateApp.carregando && <CircularProgress color='primary' size={24}/>}
        </Toolbar>
      </Content>
      <Content full padding pb="3rem">
        {/* Campo do Input */}
        {etapaAtual.component}
        <br/><br/>

        <Button
          disabled={stateApp.etapaValida !== true}
          size='large'
          variant='contained'
          color='primary'
          onClick={() => navegaParaEtapaSeguinte()}
        >
          Continuar
        </Button>

        <Toast
          exibe={exibeMensagemErro}
          setExibe={setExibeMensagemErro}
          mensagem={mensagemErro}
          duration={10000}
          botao
        />
      </Content>
    </Container>
  )
}