import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Termo from '../../../../componentes/Termo';

import { _404 as Erro404 } from '../../../../paginas';
import InscricaoContext from '../../context';

import TextoInstituicao from './textos/TextoInstituicao';
import TextoDeputado from './textos/TextoDeputado';
import TextoVereador from './textos/TextoVereador';
import { detectaDispositivo } from '../../../../_recursos/js/util';

function Inicial({ avancar, icone }: any) {
  const { etapas, inscricao: { schema, proprietario } } = useContext(InscricaoContext);

  const dispatch = useDispatch();

  const privacidade = etapas.termos.find((t: any) => t.idTermoTipo === 1);

  useEffect(() => void dispatch({ type: 'VALIDA_ETAPA', etapaValida: !!privacidade }), [dispatch, privacidade]);

  const sexo = proprietario.sexo === 1 ? 'o' : 'a';
  const termo = (
    proprietario.apelido
      ? (
        privacidade?.texto
        ?.replace(/{sexo}/g, sexo)
        .replace(/{nome}/g, proprietario.nome || proprietario.apelido)
        .replace(/{e-mail}/g, proprietario.email)
      )
      : privacidade?.texto
  );

  const textoTermo = useMemo(() => {
    switch (schema) {
      case 'arco':
        return <TextoInstituicao instituicao="instituto arco" />

      case 'libcom':
        return <TextoInstituicao instituicao="libcom" />

      case 'z00001ra':
      case 'z01453em':
      case 'z03843tf':
      case 'z00254js':
        return <TextoDeputado />

      default:
        return <TextoVereador />
    }
  }, [schema]);

  function navegaParaFormulario() {
    dispatch({
      type: 'ADICIONA_TERMOS',
      termos: [
        {
          idTermo: privacidade.idTermo,
          idTermoTipo: privacidade.idTermoTipo,
          dispositivo: detectaDispositivo(),
          aceito: true,
        },
      ]
    });
    avancar();
  }

  if (!privacidade) return <Erro404 />;

  return (
    <Termo
      textoTermo={textoTermo}
      botaoPrimario='ACEITO'
      acaoBotaoPrimario={navegaParaFormulario}
      conteudoPoliticaDePrivacidade={termo}
      icone={icone}
    />
  );
}

export default Inicial;
