export interface IEventoEscola {
  cidade: string | null,
  data: string | null,
  data_inscricao: string | null,
  idevento: number | null,
  socio: boolean | null,
  status: string | null,
  termo_inscricao: number | null,
  titulo: string | null,
  valor_evento: number | null,
  valor_inscricao: string | null,
  boleto: {
    boletoURL: string,
    boletoBarcode: string,
    boletoExpirationDate: string,
  } | null,
  qtd_parcelas: number | null
}

type EventoEscolaActions = 'ALTERA_CIDADE'
  | 'ALTERA_DATA'
  | 'ALTERA_DATA_INSCRICAO'
  | 'ALTERA_IDEVENTO'
  | 'ALTERA_SOCIO'
  | 'ALTERA_STATUS'
  | 'ALTERA_TERMO_INSCRICAO'
  | 'ALTERA_TITULO'
  | 'ALTERA_VALOR_EVENTO'
  | 'ALTERA_VALOR_INSCRICAO'
  | 'ALTERA_BOLETO'
  | 'ALTERA_PARCELAS'
  | 'RESET_DADOS';

export interface IEventoEscolaAction extends IEventoEscola {
  type?: EventoEscolaActions,
}

const INITIAL_STATE: IEventoEscola = {
  cidade: null,
  data: null,
  data_inscricao: null,
  idevento: null,
  socio: null,
  status: null,
  termo_inscricao: null,
  titulo: null,
  valor_evento: null,
  valor_inscricao: null,
  boleto: null,
  qtd_parcelas: null
};

function dados(state = INITIAL_STATE, action: IEventoEscolaAction) {
  switch (action.type) {
    case 'ALTERA_CIDADE':
      return { ...state, cidade: action.cidade };

    case 'ALTERA_DATA':
      return { ...state, data: action.data };

    case 'ALTERA_IDEVENTO':
      return { ...state, idevento: action.idevento };

    case 'ALTERA_SOCIO':
      return { ...state, socio: action.socio };

    case 'ALTERA_STATUS':
      return { ...state, status: action.status };

    case 'ALTERA_TERMO_INSCRICAO':
      return { ...state, termo_inscricao: action.termo_inscricao };

    case 'ALTERA_TITULO':
      return { ...state, titulo: action.titulo };

    case 'ALTERA_VALOR_EVENTO':
      return { ...state, valor_evento: action.valor_evento };

    case 'ALTERA_VALOR_INSCRICAO':
      return { ...state, valor_inscricao: action.valor_inscricao };

    case 'ALTERA_PARCELAS':
      return { ...state, qtd_parcelas: action.qtd_parcelas };

    case 'ALTERA_BOLETO':
      return {
        ...state,
        boleto: {
          boletoURL: action.boleto?.boletoURL,
          boletoBarcode: action.boleto?.boletoBarcode,
          boletoExpirationDate: action.boleto?.boletoExpirationDate
        }
      };

    case 'RESET_DADOS':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default dados;