import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IStoreRedux } from 'Types/Reducers';

import Termo from '../../../../componentes/Termo';
import Spinner from '../../../../componentes/Spinner';
import { BadRequest, NotFound } from '../../../../componentes/Erro';

import TextoDeputado from '../../../Cadastro/paginas/Inicial/textos/TextoDeputado';

import { detectaDispositivo, getSchema, getTermos, validaHashOuReferencia, /* validaLinkInscricao, */ verificaPadraoReferenciaSchema } from '../../../../_recursos/js/util';
import TextoVereador from '../../../Cadastro/paginas/Inicial/textos/TextoVereador';
import TextoInstituicao from '../../../Cadastro/paginas/Inicial/textos/TextoInstituicao';

export default function Inicial({ config }: IPageConfigParams) {
  const { referencia, schema, /* idUsuario, assinaturaUrl, */ icone, termo, etapaPath, setTermo, init } = config;

  const dispatch = useDispatch();
  const vereador = useSelector((state: IStoreRedux) => state.vereador || {});

  const historicoNavegacao = useHistory();

  const [httpStatus, setHttpStatus] = useState(0);
  const [dispositivo] = useState(detectaDispositivo());

  const validaReferencia = useCallback(async () => {
    if (referencia.match(/libcom|arco/)) setHttpStatus(200);

    // validação da referencia ou hash de vereador/deputado
    else {
      // console.log('chamei requisição valida referencia');
      const seguePadraoReferencia = verificaPadraoReferenciaSchema(referencia);
      const tipoValidacao = seguePadraoReferencia ? 'referencia' : 'hash';
      const retorno: any = await validaHashOuReferencia(tipoValidacao, referencia);

      setHttpStatus(retorno.status);
      if (retorno.status === 200) {
        dispatch({ type: 'ALTERA_VEREADOR_APELIDO', apelido: retorno.data.apelido });
        dispatch({ type: 'ALTERA_VEREADOR_EMAIL', email: retorno.data.email });
        dispatch({ type: 'ALTERA_VEREADOR_NOME', nome: retorno.data.nome });
        dispatch({ type: 'ALTERA_VEREADOR_SEXO', sexo: retorno.data.sexo });

        // if (
        //   referencia === '0001ra'
        //   || referencia === '1453em'
        //   || referencia === '3843tf'
        //   || referencia === '0254js'
        // ) {
        //   const id = idUsuario && idUsuario;
        //   const retornoValidaLink = await validaLinkInscricao(referencia, id, assinaturaUrl)
        //   setHttpStatus(retornoValidaLink ? 200 : 404);
        // }
      }
    }
  }, [dispatch, referencia])

  const iniciaPagina = useCallback(async () => {
    if (schema && setTermo) {
      setTermo(await getTermos(1, schema));
      await validaReferencia()
    }
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false })
  }, [dispatch, schema, setTermo, validaReferencia]);

  const navegaParaFormulario = useCallback(() => {
    const termoAceito: IState = {
      ...termo,
      dispositivo,
      aceito: true,
    }
    delete termoAceito.texto;

    dispatch({ type: 'ALTERA_TERMOS', termos: [termoAceito] });
    historicoNavegacao.push(`${etapaPath}`);
  },[dispatch, dispositivo, etapaPath, historicoNavegacao, termo]);

  const getTextoTermo = useMemo(() => {
    if (getSchema(referencia).match(/libcom|arco/))
      return <TextoInstituicao instituicao={schema} />

    if (getSchema(referencia).match(/z0(0001ra|1453em|3843tf|0254js)/))
      return <TextoDeputado schema={schema} />

    return <TextoVereador vereador={vereador} />
  }, [referencia, schema, vereador]);

  useEffect(() => {
    init && init();
    iniciaPagina();
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: true });
  }, [dispatch, iniciaPagina, init, schema]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 && <Spinner />}

      {/* Falhou */}
      {httpStatus === 400 && <BadRequest referencia={referencia}/>}
      {httpStatus === 404 || httpStatus === 401 ? <NotFound /> : null}

      {/* Concluido */}
      <Termo
        textoTermo={getTextoTermo}
        botaoPrimario='ACEITO'
        acaoBotaoPrimario={navegaParaFormulario}
        conteudoPoliticaDePrivacidade={termo?.texto}
        icone={icone}
      />
    </>
  )
}