import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

export const IconeCopiar = styled(FilterNoneIcon)`
  && {
    transform: rotate(90deg);
  }
`;

export const ContainerIcone = styled.span`
  display: flex;
  margin-right: 10px;
`;

export const BotaoEventoAgenda = styled(Button)`
  && {
    svg {
      margin-right: 10px;
    }
    
    color: #fff;
    font-size: 1rem;
    margin-top: 15px;
    padding: 10px 25px;
  }
`;