import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isValid } from 'cpf';

import { post, get } from '../../../../../_axios/requisicao';

import { Button } from '@material-ui/core';

import LibcomIcon from '../../../../../_recursos/imagens/libcom-icon.svg';

import { Container, Content, Toolbar } from '../../../../../componentes/Interface';
import Spinner from '../../../../../componentes/Spinner';

import Toast from '../../../../../componentes/Toast';
import initWebcheckout, { configCheckoutPagarMe } from '../../../../../_recursos/js/configCheckoutPagarMe';
import {
  TEXTO_SPINNER_INSCRICAO,
  TEXTO_CPF_INVALIDO,
  TEXTO_SERVER_ERROR,
  TEXTO_NAO_PERMITE_INSCRICAO,
} from '../textos';

import { InscricaoEFLContext } from '../../..';
import { IconeLibcom, TextoCabecalho } from './styles';
import { IStoreRedux } from 'Types/Reducers';

let checkoutPagarme: any;

function SingleEtapas({ component }: { component: JSX.Element }) {
  const { schema, validaHashEvento, lote } = useContext(InscricaoEFLContext);


  const dispatch = useDispatch();
  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);
  const eventoEscola = useSelector((state: IStoreRedux) => state.eventoEscola);

  const historicoNavegacao = useHistory();

  const [pedidoOdoo, setPedidoOdoo] = useState<IPedidoOdoo>();
  const [abreCheckout, setAbreCheckout] = useState<boolean>(false);

  const [spinner, setSpinner] = useState<boolean>(false);
  const [mesangemSpinner, setMensagemSpinner] = useState<JSX.Element>(<></>);
  const [exibeMensagemErro, setExibeMensagemErro] = useState<boolean>(false);
  const [mensagemErro, setMensagemErro] = useState<string>('');

  const exibeMetodoPagamento = useCallback((pedidoOdoo: any) => {
    const values = { dados, contatos, endereco, eventoEscola };
    const config = configCheckoutPagarMe(values as any, stateApp.idPessoa as string, stateApp.corPrimaria as string, pedidoOdoo);

    const errorCallback = (error: any) => {
      setMensagemErro(TEXTO_SERVER_ERROR);
      setExibeMensagemErro(true);
      console.error(error);
    };

    const checkout = new checkoutPagarme.Checkout({
      encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
      success: async (success: any) => {
        setAbreCheckout(false);
        setExibeMensagemErro(false);
        try {
          const response: any = await get('api-pessoas', `/captura-transacao-pagarme/${success.token}?valor=${config.amount}`, {});
          const { data, status } = response;

          if (status === 200) {
            const boletoProps = {
              boletoURL: data?.boletoURL,
              boletoBarcode: data?.boletoBarcode,
              boletoExpirationDate: data?.boletoExpirationDate,
            };

            dispatch({ type: 'ALTERA_BOLETO', boleto: boletoProps });
            historicoNavegacao.push('/convite-publico/confirmacao');
          }
        } catch (error) {
          errorCallback(error)
        }
      },
      error: errorCallback,
      close: () => setTimeout(() => checkout.open(config), 700),
    });

    checkout.open(config);
  }, [contatos, dados, dispatch, endereco, eventoEscola, historicoNavegacao, stateApp.corPrimaria, stateApp.idPessoa]);

  // exibe o metodo de pagamento quando houver pedido da odoo
  useEffect(() => {
    if (pedidoOdoo && abreCheckout) {
      exibeMetodoPagamento(pedidoOdoo);
    }
  }, [pedidoOdoo, abreCheckout, exibeMetodoPagamento]);

  const verificaCpf = useCallback(async () => {
    try {
      const valido = await validaHashEvento({ cpf: dados.cpf });

      if (valido) {
        setExibeMensagemErro(false);

        // reutilizar o pedidoOdoo salvo (ver validaHashEvento)
        // if (valido.pedidoOdoo) {
        //   setPedidoOdoo(valido.pedidoOdoo);
        //   setAbreCheckout(true);
        //   setMensagemSpinner(TEXTO_SPINNER_PAGAMENTO_PENDENTE);
        //   setSpinner(true);
        //   return true;
        // }

        if (!valido.permiteInscricao) {
          setMensagemErro(TEXTO_NAO_PERMITE_INSCRICAO);
          setExibeMensagemErro(true);
          return false;
        }

        return true;
      } else {
        setMensagemErro(TEXTO_CPF_INVALIDO);
        setExibeMensagemErro(true);
        return false;
      }

    } catch (error) {
      console.error(error)
      setMensagemErro(TEXTO_CPF_INVALIDO);
      setExibeMensagemErro(true);
      return false;
    }
  }, [dados.cpf, validaHashEvento]);

  const inscreveAluno = useCallback(async () => {
    const uriInscricaoAluno = '/inscricoes';
    const inscricao = {
      dados,
      contatos,
      endereco,
      termos,
      cupom: stateApp.cupom,
      hashEvento: stateApp.hash,
      lote: Number(lote),
    };

    const header = {
      referencia: stateApp.referencia,
      usuario: stateApp.idUsuario || '',
      responsavel: dados.visitadoporid || '',
      schema,
    }
    setMensagemSpinner(TEXTO_SPINNER_INSCRICAO);
    setSpinner(true);

    try {
      const retorno = await post('api-pessoas', uriInscricaoAluno, inscricao, header);
      if (retorno) {
        setSpinner(false);
        setExibeMensagemErro(false);

        dispatch({ type: 'ALTERA_IDPESSOA', idPessoa: retorno.data.dados.idconvidado });
        dispatch({ type: 'ALTERA_STATUS', status: 'pagamento-andamento' });
        return retorno.data.pedidoOdoo;
      } else {
        setMensagemErro(TEXTO_SERVER_ERROR);
        setExibeMensagemErro(true);
        return false;
      }

    } catch (error) {
      console.error(error);
      setMensagemErro(TEXTO_SERVER_ERROR);
      setExibeMensagemErro(true);
      setSpinner(false);
      return false;
    }
  }, [
    lote,
    dados,
    contatos,
    endereco,
    termos,
    stateApp.cupom,
    stateApp.hash,
    stateApp.referencia,
    stateApp.idUsuario,
    schema,
    dispatch,
  ]);

  const concluiFormulario = useCallback(async () => {
    const pedidoOdoo = await inscreveAluno();

    if (pedidoOdoo) {
      setAbreCheckout(true);
      setPedidoOdoo(pedidoOdoo);
      return;
    } else if (!pedidoOdoo) {
      setMensagemErro(TEXTO_SERVER_ERROR);
      setExibeMensagemErro(true);
      return;
    }
  }, [inscreveAluno]);

  useEffect(() => {
    if(stateApp.inscrito) {
      historicoNavegacao.push('/convite-publico/confirmacao');
    }

    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, [dispatch, historicoNavegacao, stateApp.inscrito]);

  useEffect(() => {
    if (isValid(dados.cpf || ''))
      verificaCpf();
  }, [dados.cpf, verificaCpf]);

  useEffect(() => {
    initWebcheckout(() => {
      checkoutPagarme = window.PagarMeCheckout;
    });
  }, []);

  return (
    <Container>
      {spinner && <Spinner texto={mesangemSpinner}/>}

      {/* Toolbar */}
      <Content padding mw="600px">
        <Toolbar>
          <IconeLibcom src={LibcomIcon} alt="libcom-logo" />
          <TextoCabecalho>
            <h3>Inscrição para Escola de Formação de Líderes</h3>
            Instituto Liberdade e Comunidade
            <br />
            {
              eventoEscola.valor_evento && (
                (eventoEscola.valor_inscricao !== eventoEscola.valor_evento)
                  ? <>
                    <b style={{ color: 'crimson', fontStyle: 'italic' }}>
                      De <span style={{ textDecoration: 'line-through' }}>{eventoEscola.valor_evento} em até {eventoEscola.qtd_parcelas}x</span>
                    </b>
                    <br/>
                    <b style={{ color: 'green' }}>
                      Por
                      R$ {eventoEscola.valor_inscricao}&nbsp;
                      em até {eventoEscola.qtd_parcelas}x
                    </b>
                  </>
                  :
                  <b style={{ color: 'green' }}>
                    Valor
                    R$ {eventoEscola.valor_evento}&nbsp;
                    em até {eventoEscola.qtd_parcelas}x
                  </b>
              )
            }

          </TextoCabecalho>
        </Toolbar>
      </Content>
      <Content full padding mw="550px">

        {/* Campo do Input */}
        {component}

        <Button
          style={{ margin: '1rem 0 3rem' }}
          disabled={Object.values(stateApp.camposValidos as Record<string, boolean>).some(validado => !validado)}
          size='large'
          variant='contained'
          color='primary'
          onClick={concluiFormulario}
        >
          Confirmar & Pagar
        </Button>
        <Toast
          exibe={exibeMensagemErro}
          setExibe={setExibeMensagemErro}
          mensagem={mensagemErro}
          duration={10000}
          botao
        />
      </Content>
    </Container>
  )
}

export default SingleEtapas;