import { createMuiTheme } from "@material-ui/core/styles";

// Enrico Misasi: #235F6F
const temaEnrico = createMuiTheme({
  palette: {
    primary: {
      main: '#009384',
      light: '#02af71',
    },
  },
  typography: {
    fontSize: 20,
    htmlFontSize: 20
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 24,
      },
    },
  },
});

export default temaEnrico;
