import React, { memo } from 'react';

import { IVereador } from 'Types/Reducers/Vereador';

export const AceiteTermoVereador = 'é bom contar com sua ajuda nesse projeto.';
export const NaoAceiteTermoVereador = 'Sua escolha foi salva.';

interface ITermoProps {
  vereador: IVereador
}

function TermoVereador({ vereador }: ITermoProps) {
  return (
    <>
      Ao clicar em <strong>ACEITO</strong> você autoriza que {vereador.sexo === 1 ? 'o ' : 'a '}
      <strong> {vereador.apelido}</strong> envie comunicações, pelos meios já
      fornecidos, sobre atividades políticas, temas de interesse público e, no
      período legalmente permitido, comunicação eleitoral.
    </>
  )
}

export default memo(TermoVereador);
