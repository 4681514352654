import styled from 'styled-components';

import { DialogContent } from '@material-ui/core';

export const DialogContentStyled = styled(DialogContent)`
  && {
    pre {
      white-space: pre-wrap;
    }
    div ol {
      padding-left: 24px;

      li {
        margin-bottom: 8px;
        text-align: left;

        &::marker {
          font-weight: bold;
        }
      }
    }

    strong {
      text-decoration: underline;
    }
  }
`;