import { makeStyles, Theme } from '@material-ui/core/styles';

interface IStyles {
  container: {
    [key: string]: any,
  },
}

const useStyles = makeStyles<Theme, IStyles>(() => ({
  container: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '2',
    backgroundColor: '#fff',
  }
} as any));

export default useStyles;
