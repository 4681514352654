import React, { useCallback } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { CampoDeTextoOutlined } from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreRedux } from 'Types/Reducers'

function CampoComoConheceu() {
  const valor = useSelector<IStoreRedux, string>(state => state.dados.como_conheceu || '')
  const dispatch = useDispatch()

  const aoAlterar = useCallback((e) => {
    dispatch({ type: 'ALTERA_COMO_CONHECEU', como_conheceu: e.target.value })
  }, [dispatch])

  return (
    <div className="campo-formulario">
      <CampoDeTextoOutlined
        select
        fullWidth
        label={valor === '' && 'Selecione uma opção'}
        value={valor}
        onChange={aoAlterar}
        autoComplete="off"
      >
        <MenuItem value="Google">Google</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Youtube">Youtube</MenuItem>
        <MenuItem value="Amigo ou Familiar">Amigo ou Familiar</MenuItem>
        <MenuItem value="Outro">Outro</MenuItem>
      </CampoDeTextoOutlined>
    </div>
  )
}

export default CampoComoConheceu