
// Detalhes
// idEstadoCivil: primary_key.
// Filhos: true, false, null.
// Sexo: F:2, M:1, null,

import { IDados, IDadosAction } from 'Types/Reducers/Dados';

const INITIAL_STATE: IDados = {
  apelido: null,
  cpf: null,
  dataNascimento: null,
  idEstadoCivil: null,
  filhos: null,
  nome: null,
  idProfissao: null,
  profissao: null,
  grauinstrucao: null,
  escolaridade: null,
  novaformacao: null,
  formacao: null,
  sexo: null,
  isento: null,
  visitadoporid: null,
  como_conheceu: null
};

function dados(state = INITIAL_STATE, action: IDadosAction) {
  switch (action.type) {
    case 'ALTERA_APELIDO':
      return { ...state, apelido: action.apelido };

    case 'ALTERA_CPF':
      return { ...state, cpf: action.cpf };

    case 'ALTERA_DATA_NASCIMENTO':
      return { ...state, dataNascimento: action.dataNascimento };

    case 'ALTERA_ESTADO_CIVIL':
      return { ...state, idEstadoCivil: action.idEstadoCivil };

    case 'ALTERA_FILHOS':
      return { ...state, filhos: action.filhos };

    case 'ALTERA_NOME':
      return { ...state, nome: action.nome };

    case 'ALTERA_PROFISSAO':
      return { ...state, idProfissao: action.idProfissao, profissao: action.profissao };

    case 'ALTERA_ESCOLARIDADE':
      return { ...state, grauinstrucao: action.grauinstrucao, escolaridade: action.escolaridade };

      case 'ALTERA_FORMACAO':
        return { ...state, novaformacao: action.novaformacao, formacao: action.formacao };

    case 'ALTERA_SEXO':
      return { ...state, sexo: action.sexo };

    case 'ALTERA_ISENTO':
      return { ...state, isento: action.isento };

    case 'ALTERA_VISITADOPORID':
      return { ...state, visitadoporid: action.visitadoporid };

    case 'ALTERA_COMO_CONHECEU':
      return { ...state, como_conheceu: action.como_conheceu };

    case 'RESET_DADOS':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default dados;