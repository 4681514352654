import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Termo from '../../../../componentes/Termo';
import Spinner from '../../../../componentes/Spinner';
import { BadRequest, NotFound } from '../../../../componentes/Erro';

// Types
import { IVereador } from 'Types/Reducers/Vereador';

import TextoDeputado from './textos/TextoDeputado';
import TextoVereador from './textos/TextoVereador';
import TextoInstituicao from './textos/TextoInstituicao';

import { detectaDispositivo, getTermos, validaHashOuReferencia, verificaPadraoHashLink, verificaPadraoReferenciaSchema } from '../../../../_recursos/js/util';
import { IStoreRedux } from 'Types/Reducers';

export default function Inicial({ config }: IPageConfigParams) {
  const { referencia, schema, /* idUsuario, assinaturaUrl, */ icone, termo, etapaPath, setTermo, init } = config;

  const dispatch = useDispatch();
  const vereador: IVereador = useSelector((state: IStoreRedux) => state.vereador);

  const historicoNavegacao = useHistory();

  const [httpStatus, setHttpStatus] = useState(0);
  const [dispositivo] = useState(detectaDispositivo());

  const iniciaPagina = useCallback(async () => {
    const seguePadraoReferencia = verificaPadraoReferenciaSchema(referencia);
    const seguePadraoHash = verificaPadraoHashLink(referencia);
    const naoSegueHashNemReferencia = !seguePadraoReferencia && !seguePadraoHash

    if (schema && setTermo) {
      setTermo(await getTermos(1, schema));

      if (naoSegueHashNemReferencia && referencia.match(/libcom|arco/)) {
        setHttpStatus(200);
      } else {
        const validacao = seguePadraoReferencia ? 'referencia' : 'hash'
        const retorno = await validaHashOuReferencia(validacao, referencia);
        setHttpStatus(retorno.status);

        if (retorno.status === 200) {
          dispatch({ type: 'ALTERA_VEREADOR_APELIDO', apelido: retorno.data.apelido });
          dispatch({ type: 'ALTERA_VEREADOR_EMAIL', email: retorno.data.email });
          dispatch({ type: 'ALTERA_VEREADOR_NOME', nome: retorno.data.nome });
          dispatch({ type: 'ALTERA_VEREADOR_SEXO', sexo: retorno.data.sexo });
        }
      }
    }

    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
  }, [dispatch, referencia, schema, setTermo]);

  const navegaParaFormulario = useCallback(() => {
    const termoAceito: IState = {
      ...termo,
      dispositivo,
      aceito: true,
    }
    delete termoAceito.texto;

    dispatch({ type: 'ALTERA_TERMOS', termos: [termoAceito] });
    historicoNavegacao.push(`${etapaPath}`);
  },[dispatch, dispositivo, etapaPath, historicoNavegacao, termo]);

  const textoPolitica = useMemo(() => {
    const sexo = vereador.sexo === 1 ? 'o' : 'a';

    if (termo && termo.texto) {
      return termo.texto
        .replace(/{sexo}/g, sexo)
        .replace(/{nome}/g, vereador.nome || vereador.apelido || '')
        .replace(/{e-mail}/g, vereador.email);
    }

    return termo && termo?.texto;
  }, [termo, vereador]);

  const getTextoTermo = useCallback(() => {
    const dados = {
      sexo: vereador.sexo,
      apelido: vereador.apelido,
    }

    switch (schema) {
      case 'arco':
        return <TextoInstituicao instituicao="instituto arco" />

      case 'libcom':
        return <TextoInstituicao instituicao="libcom" />

      case 'z00001ra':
      case 'z01453em':
      case 'z03843tf':
      case 'z00254js':
        return <TextoDeputado schema={schema} />

      default:
        return <TextoVereador vereador={dados} />
    }
  }, [schema, vereador.apelido, vereador.sexo]);

  useEffect(() => {
    init && init();
    iniciaPagina();
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: true });
  }, [dispatch, iniciaPagina, init, schema]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 && <Spinner />}

      {/* Falhou */}
      {httpStatus === 400 && <BadRequest referencia={referencia}/>}
      {httpStatus === 404 || httpStatus === 401 ? <NotFound /> : null}

      {/* Concluido */}
      {httpStatus === 200 && (
        <Termo
          textoTermo={getTextoTermo()}
          botaoPrimario='ACEITO'
          acaoBotaoPrimario={navegaParaFormulario}
          conteudoPoliticaDePrivacidade={textoPolitica}
          icone={icone}
        />
      )}
    </>
  )
}