import React from 'react';

import { Container, Content, Text, Title } from "../../../componentes/Interface";

export default function _404() {
  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom>404</Title>
        <Text gutterBottom align='center'>
          Ops... página não encontrada!
        </Text>
      </Content>
    </Container>
  );
}
