import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Subtitle } from '../../../componentes/Interface';

export default function Sexo() {
  const valor = useSelector(state => state.dados.sexo);
  const dispatch = useDispatch();
  let valorAlterado = null;

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'sexo' }), [dispatch]);

  function altera(event) {
    valorAlterado = event.target.value;
    dispatch({ type: `ALTERA_SEXO`, sexo: valorAlterado })
    valida(valorAlterado);
  }

  function valida(valor) {
    const regex = /[1,2]/;
    const resultado = regex.test(valor);
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <Subtitle gutterBottom>Sexo</Subtitle>

      <RadioGroup
        value={valor}
        onChange={altera}>
        <FormControlLabel
          label='Feminino'
          value='2'
          control={<Radio color='primary' />}
        />
        <FormControlLabel
          label='Masculino'
          value='1'
          control={<Radio color='primary' />}
        />
      </RadioGroup>
    </div>
  );
}
