import styled from "styled-components";

import FormControlLabel from "@material-ui/core/FormControlLabel";

export const FormTermo = styled(FormControlLabel)`
  & {
    user-select: none;
    cursor: default;

    .MuiCheckbox-indeterminate {
      cursor: default;
    }

    b {
      cursor: pointer;

      :hover {
        color: black;
      }
    }
  }
`;