import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { post } from '../../../../_axios/requisicao';

import { NotFound, ServerError } from '../../../../componentes/Erro';
import Termo from '../../../../componentes/Termo';
import Spinner from '../../../../componentes/Spinner';
import Feedback from '../../../../componentes/Feedback';

import { validaHashOuReferencia, detectaDispositivo, getTermos, getSchema } from '../../../../_recursos/js/util';

import { IVereador } from 'Types/Reducers/Vereador';
import { ITermo } from 'Types/Reducers/Termos';

import TermoVereador, { AceiteTermoVereador, NaoAceiteTermoVereador } from './termos/Vereador';
import { IStoreRedux } from 'Types/Reducers';

interface IParams {
  hash: string
}

interface ITermoProps extends ITermo {
  texto: string,
}

type ParamTermosHandle = 'ACEITO' | 'NAO_ACEITO';

function Privacidade() {
  // TODO: Transformar em uma container
  const stateApp = useSelector((state: IStoreRedux) => state.app);

  const dispatch = useDispatch();
  const vereador: IVereador = useSelector((state: IStoreRedux) => state.vereador);

  const historicoNavegacao = useHistory();
  const url = useLocation();
  const { hash } = useParams<IParams>();

  const [httpStatus, setHttpStatus] = useState(0);
  const [confirmacao, setConfirmacao] = useState(false);
  const [textoAceite, setTextoAceite] = useState('');
  const [tituloAceite, setTituloAceite] = useState('');
  const [dispositivo] = useState(detectaDispositivo());
  const [termo, setTermo] = useState<ITermoProps>();

  const resetApp = useCallback(() => {
    dispatch({ type: 'RESET_APP' });

    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: true });
  }, [dispatch]);

  const init = useCallback(async () => {
    resetApp();
    const referencia = hash.substr(-6);

    setTermo(await getTermos(1, getSchema(referencia.substr(0, 6))));
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: true });

    if (
      referencia === '0001ra'
      || referencia === '1453em'
      || referencia === '3843tf'
      || referencia === '0254js'
    )
      return historicoNavegacao.push('/');

    const retorno = await validaHashOuReferencia('referencia', referencia);

    setHttpStatus(retorno.status);

    if(retorno.status === 200) {
      dispatch({ type: `ALTERA_VEREADOR_APELIDO`, apelido: retorno.data.apelido });
      dispatch({ type: `ALTERA_VEREADOR_EMAIL`, email: retorno.data.email });
      dispatch({ type: `ALTERA_VEREADOR_NOME`, nome: retorno.data.nome });
      dispatch({ type: `ALTERA_VEREADOR_SEXO`, sexo: retorno.data.sexo });
    }
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, [dispatch, hash, historicoNavegacao, resetApp]);

  useEffect(() => {
    // Rota antiga redireciona para rota nova
    if (url.pathname.includes('/termo/')) {
      historicoNavegacao.push(`/privacidade/${hash}`);
    }
    httpStatus === 0 && init();
  }, [httpStatus, init, url, hash, historicoNavegacao]);

  const textoTermo = useMemo(() => {
    const sexo = vereador.sexo === 1 ? 'o' : 'a';

    if (termo && termo.texto) {
      return termo.texto
        .replace(/{sexo}/g, sexo)
        .replace(/{nome}/g, vereador.nome || vereador.apelido || '')
        .replace(/{e-mail}/g, vereador?.email || '');
    }

    return termo?.texto || '';
  }, [termo, vereador]);

  /**
   * Usuário aceita ou não o termo da LGPD
   * Grava preferência do usuário no endpoint /privacidade da
   * api-pessoas, mandando o hash, aceito, idTermo, idTipo e dispositivo.
   * @param {ParamTermosHandle} condicao - ACEITO ou NÃO_ACEITO
   */
  const handleTermos = useCallback(async (condicao: ParamTermosHandle) => {

    if (stateApp.inscrito) {
      setHttpStatus(200);
      return;
    }

    try {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
      const aceito = condicao === 'ACEITO';

      if (aceito) setTituloAceite('Obrigado,');

      const dados = {
        ...termo,
        hash: hash,
        aceito: aceito ? true : false,
        dispositivo: dispositivo
      };

      delete dados.texto;

      const uriPrivacidade = '/privacidade';

      const cabecalho = { referencia: hash.substr(-6) };

      const retorno = await post('api-pessoas', uriPrivacidade, dados, cabecalho);
      if (retorno) {
        setHttpStatus(retorno.status);
      }
      setConfirmacao(true);
      setTextoAceite(aceito ? AceiteTermoVereador : NaoAceiteTermoVereador);
    }
    catch (error) {
      setHttpStatus(500);
    }
    finally {
      dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
    }
  },[dispatch, dispositivo, hash, termo, stateApp.inscrito]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 || stateApp.carregando ? <Spinner /> : null}

      {/* Falhou */}
      {httpStatus === 404 || httpStatus === 400 ? <NotFound /> : null}

      {httpStatus === 500 ? <ServerError /> : null}

      {/* Concluido */}
      {httpStatus === 200  && !stateApp.carregando ?
        confirmacao
        ? <Feedback titulo={tituloAceite} texto={textoAceite} />
        : <Termo
            textoTermo={<TermoVereador vereador={vereador} />}
            botaoPrimario='ACEITO'
            acaoBotaoPrimario={() => handleTermos('ACEITO')}
            botaoSecundario='NÃO ACEITO'
            acaoBotaoSecundario={() => handleTermos('NAO_ACEITO')}
            conteudoPoliticaDePrivacidade={textoTermo}
          />
      : null }
    </>
  );
}

export default Privacidade;