import { IApp, IAppAction } from 'Types/Reducers/App';

const INITIAL_STATE: IApp = {
  carregando: false,
  corPrimaria: null,
  deputado: false,
  etapaValida: null,
  hash: null,
  cupom: null,
  cupomValido: null,
  idPessoa: null,
  idUsuario: null,
  inscrito: false,
  inscritoVoluntariado: false,
  naoPossuoEmail: null,
  provedor: null,
  referencia: null,
  uid: null,
};

function app(state = INITIAL_STATE, action: IAppAction) {
  switch (action.type) {
    case 'ALTERA_CARREGANDO':
      return { ...state, carregando: action.carregando };

    case 'ALTERA_COR_PRIMARIA':
      return { ...state, corPrimaria: action.corPrimaria };

    case 'ALTERA_DEPUTADO':
        return { ...state, deputado: action.deputado };

    case 'VALIDA_ETAPA':
      return { ...state, etapaValida: action.etapaValida };

    case 'ALTERA_HASH':
        return { ...state, hash: action.hash };

    case 'ALTERA_CUPOM':
        return { ...state, cupom: action.cupom };

    case 'ALTERA_CUPOM_VALIDO':
        return { ...state, cupomValido: action.cupomValido };

    case 'ALTERA_IDPESSOA':
      return { ...state, idPessoa: action.idPessoa };

    case 'ALTERA_IDUSUARIO':
      return { ...state, idUsuario: action.idUsuario };

    case 'ALTERA_INSCRITO':
      return { ...state, inscrito: action.inscrito };

    case 'ALTERA_INSCRITO_VOLUNTARIADO':
      return { ...state, inscritoVoluntariado: action.inscritoVoluntariado };

    case 'ALTERA_NAO_POSSUO_EMAIL':
      return { ...state, naoPossuoEmail: action.naoPossuoEmail };

    case 'ALTERA_PROVEDOR':
      return { ...state, provedor: action.provedor };

    case 'ALTERA_REFERENCIA':
      return { ...state, referencia: action.referencia };

    case 'ALTERA_UID':
      return { ...state, uid: action.uid };

    case 'ALTERA_CAMPOS':
      return { ...state, camposValidos: action.camposValidos };

    case 'VALIDA_CAMPO':
      const trueAction = { ...action } as Partial<IAppAction>;
      delete trueAction.type;

      return { ...state, camposValidos: { ...state.camposValidos, ...trueAction }}

    case 'ALTERA_ETAPA_ATUAL':
      return { ...state, etapaAtual: action.etapaAtual }

    case 'RESET_APP':
      return { ...INITIAL_STATE, corPrimaria: state.corPrimaria };

    default:
      return state;
  }
}

export default app;