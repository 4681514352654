import React, { memo } from 'react';

export const AceiteTermoIntituicao = 'é bom contar com sua ajuda nesse projeto.'
export const NaoAceiteTermoInstituicao = 'Sua escolha foi salva.'

function TermoInstituicao({ instituicao }: { instituicao: string }) {
  return (
    <>
      Você está recebendo este link de acesso para efetivar seu Voluntariado junto ao <b>Instituto {instituicao.toUpperCase()}</b>.
      <br /><br />
      Para prosseguimento, preencha com suas informações o Termo de Adesão à Serviço Voluntário, leia-o com atenção, bem como o Termo de Confidencialidade e Sigilo e a Autorização de Uso de Imagem, nome e voz. Estando de acordo, é só clicar em continuar.
    </>
  );
}

export default memo(TermoInstituicao);