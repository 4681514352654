import React from 'react';

import { Content, Title } from '../../../../componentes/Interface';

import { ContainerStyled, IconeVoluntario } from './styles';

export default function Confirmacao() {

return (
    <ContainerStyled>
      <Content full verticalCenter horizontalCenter padding>
        <IconeVoluntario />
        <Title align="center">
          Cadastro Realizado com Sucesso!
        </Title>
      </Content>
    </ContainerStyled>
  );
}
