import React from 'react';
import { Redirect } from 'react-router';

import { Nome, Apelido, Sexo, DataNascimento, EstadoCivil, Escolaridade, Profissao, Filhos } from '../../../../paginas/Formulario/Dados';
import { Celular, Email } from '../../../../paginas/Formulario/Contatos';
import { Endereco } from '../../../../paginas/Formulario/Endereco';

interface IEtapa {
  path: string,
  component: JSX.Element,
  enviaInscricao?: boolean,
}

export const etapasDeputado: Array<IEtapa> = [
  { path: '/cadastro/formulario/contatos/celular', component: <Celular />},
  { path: '/cadastro/formulario/dados/nome', component: <Nome />},
  { path: '/cadastro/formulario/dados/apelido', component: <Apelido />},
  { path: '/cadastro/formulario/dados/sexo', component: <Sexo />},
  { path: '/cadastro/formulario/dados/data-nascimento', component: <DataNascimento />},
  { path: '/cadastro/formulario/dados/filhos', component: <Filhos />},
  { path: '/cadastro/formulario/endereco', component: <Endereco />},
  { path: '/cadastro/formulario/confirmacao', component: <Redirect to="/cadastro/formulario/confirmacao" push />},
];

export const etapasPadroes: Array<IEtapa> =  [
  { path: '/cadastro/formulario/contatos/celular', component: <Celular />},
  { path: '/cadastro/formulario/dados/nome', component: <Nome />},
  { path: '/cadastro/formulario/dados/apelido', component: <Apelido />},
  { path: '/cadastro/formulario/dados/sexo', component: <Sexo />},
  { path: '/cadastro/formulario/dados/data-nascimento', component: <DataNascimento />},
  { path: '/cadastro/formulario/dados/estado-civil', component: <EstadoCivil />},
  { path: '/cadastro/formulario/dados/escolaridade', component: <Escolaridade />},
  { path: '/cadastro/formulario/dados/profissao', component: <Profissao />},
  { path: '/cadastro/formulario/dados/filhos', component: <Filhos />},
  { path: '/cadastro/formulario/contatos/email', component: <Email />},
  { path: '/cadastro/formulario/endereco', component: <Endereco />},
  { path: '/cadastro/formulario/confirmacao', component: <Redirect to="/cadastro/formulario/confirmacao" push />},
];