import React from 'react';

import { Importante, Mensagem } from './styles';

export function EFL() {
  return (
    <>
      Você recebeu um convite para a <br />
      <b>Escola de Formação de Líderes</b> <br />
      do LIBCOM.
    </>
  );
}

export function EFLRodape() {
  return (
    <>
      <Importante>Importante:</Importante>
      <Mensagem>Esse convite é pessoal e intransferível, portanto não envie para outras pessoas.</Mensagem>
    </>
  )
}