import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { TextSmall } from '../../../../componentes/Interface';

import { CampoDeTextoOutlined } from '../styles';
import { IStoreRedux } from 'Types/Reducers';

// função para validar dominio de email
// import { validaDominioPorDnsGoogle } from '../../../../_recursos/js/util';
// import useDebounce from '../../../../_hooks/useDebounce';

interface IEmailProps {
  obrigatorio?: boolean
}

// regex padrão RFC5322
const regex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([\]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/;

function CampoEmail({ obrigatorio }: IEmailProps) {
  const email = useSelector<IStoreRedux, string>((store) => (store.contatos.email?.endereco || ''));
  const dispatch = useDispatch();

  const [valido, setValido] = useState<boolean>(false);
  const [checkboxNaoPossuo, setCheckboxNaoPossuo] = useState(false);

  const aoAlterarEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ALTERA_EMAIL', endereco: event.target.value.toLowerCase() });
  }, [dispatch]);

  const aoAlterarNaoPossuoEmail = useCallback((_, checked: boolean) => {
    setCheckboxNaoPossuo(checked);
    dispatch({ type: `REMOVE_EMAIL` });
    dispatch({ type: `ALTERA_NAO_POSSUO_EMAIL`, naoPossuoEmail: checked });
  }, [dispatch]);

  const erro = useMemo(() => (
    obrigatorio
      ? (email !== '' && !valido)
      : (!checkboxNaoPossuo ? (email !== '' && !valido) : false)
  ), [checkboxNaoPossuo, email, obrigatorio, valido]);

  /**
   * Algoritmo de Validação por DNS.
   * Possivelmente utilizar uma API como proxy para validar ao invés
   * de diretamente chamar na aplicação resolva o problema e serviria de melhoria
   * para validação de emails, impossibilitando emails sem registros MX
   */
  // const validaDominio = useCallback(async (dominio: string) => {
  //   const valido = await validaDominioPorDnsGoogle(dominio);
  //   dispatch({ type: 'VALIDA_CAMPO', email: valido });
  //   setValido(valido);
  //   if (valido)
  //     dispatch({ type: `ALTERA_EMAIL`, tipo: 1, endereco: email });
  // }, [dispatch, email]);

  // const debouncedValidaDominio = useDebounce(validaDominio);

  useEffect(() => {
    const resultado = regex.test(email);
    dispatch({ type: 'VALIDA_CAMPO', email: resultado });
    setValido(resultado);
    if (resultado)
      dispatch({ type: `ALTERA_EMAIL`, tipo: 1, endereco: email });
  }, [checkboxNaoPossuo, dispatch, email]);

  useEffect(() => {
    if (email === '')
      dispatch({ type: 'REMOVE_EMAIL' });
  }, [dispatch, email]);

  const campoInput = useMemo(() => (
    <CampoDeTextoOutlined
      fullWidth
      label='E-mail'
      type='email'
      autoComplete='email'
      disabled={!obrigatorio && checkboxNaoPossuo}
      error={erro}
      value={email}
      onChange={aoAlterarEmail}
    />
  ), [aoAlterarEmail, checkboxNaoPossuo, email, obrigatorio, erro]);

  const content = useMemo(() => {
    switch (obrigatorio) {
      case true:
        return <div className="campo-formulario">{campoInput}</div>

      default:
        return (
          <div className="campo-formulario">
            {campoInput}
            <FormControlLabel
              label={<TextSmall>Não possuo e-mail</TextSmall>}
              control={
                <Checkbox
                  checked={checkboxNaoPossuo}
                  color='primary'
                  onChange={aoAlterarNaoPossuoEmail}
                />
              }
            />
          </div>
        )

    }
  }, [aoAlterarNaoPossuoEmail, campoInput, checkboxNaoPossuo, obrigatorio]);

  return content;
}

export default CampoEmail;
