import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';

import { post } from '../../../../_axios/requisicao';

import { Container, Content, Text, Title } from '../../../../componentes/Interface';

import Confirmacao from './Confirmacao';
import { IStoreRedux } from 'Types/Reducers';

export default function Final({ config }: IPageConfigParams) {
  const { schema, icone } = config;

  const appState = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);

  const dispatch = useDispatch();

  const [httpStatus, setHttpStatus] = useState(0);

  useEffect(() => {
    const aceitoTermoVoluntario = termos.find(termo => termo.idTermoTipo === 2);

    if (aceitoTermoVoluntario)
      cadastraVoluntario();
  }, []);

  useEffect(()=>{
    if (httpStatus === 200)
      limpaInscricao();
  }, [httpStatus]);

  const cadastraVoluntario = useCallback(async () => {
    try {
      dispatch({ type: `ALTERA_CARREGANDO`, carregando: true });

      const inscricaoVoluntario = { dados, contatos, endereco, termos };

      const retorno = await post(
        'api-pessoas',  '/pessoas/voluntarios',
        { ...inscricaoVoluntario, voluntario: true },
        { schema: schema || appState.referencia }
      );

      retorno && setHttpStatus(retorno.status);
    }
    catch (error) {
      setHttpStatus(500);
    }
    finally {
      dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
    }
  }, []);

  const limpaInscricao = useCallback(async () => {
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_TERMOS'})
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: true });
  },[dispatch]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <CircularProgress color='primary' size={42}/>
            <br/><Title variant='h6'>Aguarde um momento...</Title>
          </Content>
        </Container>
      }

      {/* Concluido */}
      {httpStatus === 200 &&
        <Confirmacao icone={icone} />
      }

      {/* Falhou */}
      {httpStatus === 500 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <Title gutterBottom>Ops...</Title>
            <Text gutterBottom align='center'>
              Parece que o servidor respondeu de forma inesperada.
            </Text>
            <br/>

            <Button
              variant='outlined'
              color='inherit'
              onClick={() => window.location.reload()}>
                TENTAR NOVAMENTE
            </Button>
          </Content>
        </Container>
      }
    </>
  );
}
