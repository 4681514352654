import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { InscricaoEFLContext } from '../..';
import { getReferenciaPeloDominio } from '../../../../_recursos/js/util';

function Inicial() {
  const { validaHashEvento } = useContext(InscricaoEFLContext);
  const { hash } = useParams<{ hash: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_EVENTO_ESCOLA' });
    dispatch({ type: 'RESET_TERMOS' });
    dispatch({ type: 'RESET_EVENTO' });
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: false });

    dispatch({ type: 'ALTERA_HASH', hash });
    dispatch({ type: 'ALTERA_CUPOM', cupom: null });
    dispatch({ type: 'ALTERA_CUPOM_VALIDO', cupomValido: null });

    /* Esperamos que sempre venha pelo dominio libcom e arco */
    dispatch({ type: 'ALTERA_REFERENCIA', referencia: getReferenciaPeloDominio() });

    validaHashEvento({ hash });
  }, [dispatch, hash, validaHashEvento]);

  return <></>;
}

export default Inicial;