import styled from 'styled-components';

export const DetalhesEvento = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px dashed rgba(255,255,255,0.2);
  padding-top: 40px;
  padding: 0;
`;

export const DetalhesEventoItem = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.07rem;
`;

export const TextoBold = styled.b`
  font-weight: 500;
  text-transform: uppercase
`;