import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content, Title, Subtitle } from '../../../../../componentes/Interface';

import { ContainerStyled, IconeVoluntario, IconeInstituicao } from './styles';
import { IStoreRedux } from 'Types/Reducers';

interface IConfirmacaoProps {
  icone?: any,
}

export default function Confirmacao({ icone }: IConfirmacaoProps) {
  const { referencia } = useSelector((state: IStoreRedux) => state.app)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'ALTERA_INSCRITO_VOLUNTARIADO', inscritoVoluntariado: true });
  }, []);

return (
    <ContainerStyled>
      <Content full verticalCenter horizontalCenter padding>
        {
          referencia?.match(/^(libcom|arco)$/)
            ? <IconeInstituicao src={icone} alt="Icone" className={referencia as string} />
            : <IconeVoluntario />
        }
        <Title align="center">
          Muito obrigado pelo seu voluntariado!
        </Title>
        <br />
        <Subtitle fw="300" align="center">
          Seu apoio nos ajuda a levar os ideais do Instituto {referencia?.toUpperCase()} para mais pessoas.
        </Subtitle>
      </Content>
    </ContainerStyled>
  );
}
