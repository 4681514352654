import React, { useCallback, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CircularProgress, Dialog } from '@material-ui/core';


import { CampoDeTextoOutlined, ConteudoDialog } from '../../paginas/FormularioSingular/Campos/styles';
import { Alert } from '@material-ui/lab';
import { InscricaoEFLContext } from '../../fluxos/EventoPublico';
import { IStoreRedux } from 'Types/Reducers';

function FormCupom(props: any) {
  const cupomValido = useSelector((store: IStoreRedux) => store.app.cupomValido);
  const dispatch = useDispatch();

  const { validaHashEvento } = useContext(InscricaoEFLContext);

  const [cupom, setCupom] = useState<string>('');
  const [abrirDialogCupom, setAbrirDialogCupom] = useState<boolean>(false);
  const [validacaoCupomEmAndamento, setValidacaoCupomEmAndamento] = useState<boolean>(false);

  const aoAlterar = useCallback((e) => setCupom(e.target.value), []);

  const lidarComFechamentoDialog = () => {
    setAbrirDialogCupom(false);
  }

  const validarCupom = useCallback(() => {
    setValidacaoCupomEmAndamento(true);

    validaHashEvento({ cupom })
      .then(({ cupomValido }) => dispatch({ type: 'ALTERA_CUPOM', cupom: cupomValido ? cupom : null }))
      .finally(() => setValidacaoCupomEmAndamento(false));
  }, [cupom, dispatch, validaHashEvento])

  return (
    <>
      {/* Botao */}
      <span onClick={() => setAbrirDialogCupom(true)}>
        {props.children}
      </span>

      {/* Dialog */}
      <Dialog
        open={abrirDialogCupom}
        onClose={lidarComFechamentoDialog}
      >

        <ConteudoDialog>

          {/* Carregando */}
          {validacaoCupomEmAndamento
            ? <CircularProgress color='primary' size={36} />
            : <>
              {/* Erro */}
              {
                (cupomValido === null || cupomValido === false) &&
                  <>
                    {cupomValido === false &&
                      <Alert severity="error">
                        Cupom inválido
                      </Alert>
                    }

                    {/* Form */}
                    <h1>Adicionar cupom de desconto</h1>

                    <CampoDeTextoOutlined
                      fullWidth
                      label='Cupom'
                      value={cupom}
                      onChange={aoAlterar}
                    />

                    <div className='actions'>
                      <Button onClick={validarCupom} color="primary" variant="contained">
                        VALIDAR CUPOM
                      </Button>
                      <Button onClick={lidarComFechamentoDialog}>
                        FECHAR
                      </Button>
                    </div>
                  </>
              }

              {/* Sucesso */}
              {
                cupomValido === true &&
                  <>
                    <Alert severity="success">
                      Cupom adicionado e preço atualizado!
                    </Alert>

                    <div className='actions'>
                      <Button onClick={lidarComFechamentoDialog}>
                        FECHAR
                      </Button>
                    </div>
                  </>
              }


            </>
          }
        </ConteudoDialog>
      </Dialog>
    </>
  );
}

export default FormCupom;