import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Subtitle } from '../../../componentes/Interface';

export default function Filhos() {
  const valor = String(useSelector(state => state.dados.filhos));
  const dispatch = useDispatch();
  let valorAlterado = null;

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'filhos' }), [dispatch]);

  function altera(event) {
    valorAlterado = (event.target.value === 'true');
    dispatch({ type: `ALTERA_FILHOS`, filhos: valorAlterado });
    valida(valorAlterado);
  }

  function valida(valor) {
    const regex = /(true|false)/;
    const resultado = regex.test(valor);
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <Subtitle gutterBottom>Filhos</Subtitle>

      <RadioGroup
        value={valor}
        onChange={altera}>
        <FormControlLabel
          label='Sim'
          value='true'
          control={<Radio color='primary' />}
        />
        <FormControlLabel
          label='Não'
          value='false'
          control={<Radio color='primary' />}
        />
      </RadioGroup>
    </div>
  );
}
