import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((Tema) => ({
  box: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    height: '4px',
  },
}));

export default useStyles;
