import { createMuiTheme } from "@material-ui/core/styles";

// Tânia Fruchi: #3A8B8B
const temaTania = createMuiTheme({
  palette: {
    primary: {
      main: '#0567b4',
      light: '#00acea',
    },
  },
  typography: {
    fontSize: 20,
    htmlFontSize: 20
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 24,
      },
    },
  },
});

export default temaTania;
