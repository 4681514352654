import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { ITermo } from 'Types/Reducers/Termos';
import { detectaDispositivo, getTermos } from '../../../_recursos/js/util';
import { IStoreRedux } from 'Types/Reducers';

const Titulo = styled.h1`
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
`;

const SubTitulo = styled.h2`
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
`;

const DataEvento = styled.p`
  margin: 0;
  font-size: 0.7rem;
  opacity: 0.6;
  font-weight: 400;
`;

const TermoContainer = styled.article`
  border: 1px solid #ccc;
  border-right-color: transparent;
  border-left-color: transparent;
  padding: 8px 4px;
  height: calc(100vh - 5rem - 288px);
  margin-top: 22px;
  overflow: auto;

  font-size: 0.8rem;

  .op-5  {
    opacity: 0.5;
    font-weight: 600;
  }

  .uppercase {
    text-transform: uppercase;
  }

  /* SCROLL */
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 30px;
`

const AvisoTermo = styled.span`
  display: block;
  margin-top: 12px;
  margin-bottom: 6px;

  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
`;

interface ITermoEscolaProps {
  schema: string,
}

interface ITermoProps extends ITermo {
  texto?: string,
}

function TermoEscola({ schema }: ITermoEscolaProps) {
  const dispatch = useDispatch();
  const evento = useSelector((state: IStoreRedux) => state.eventoEscola);

  const [termo, setTermo] = useState<ITermoProps | null>(null);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'termo_escola' }), [dispatch]);

  const init = useCallback(async () => {
    setTermo(await getTermos(8, schema));
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: false });
  }, [dispatch, schema]);

  /**
   * Valida etapa
   * Verificar se o usuario leu todo o termo
   */
  const validaEtapa = useCallback(() => {
    const etapaValida = scrollEnd;

    dispatch({ type: 'VALIDA_ETAPA', etapaValida });
  }, [dispatch, scrollEnd]);

  useEffect(() => {
    const $termo = document.querySelector('article');

    if (scrollEnd) return;

    if($termo) {
      const onScroll = (e: any) => {
        setScrollTop(e.target.scrollTop);
        setScrollEnd(e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight);
      };
      $termo.addEventListener("scroll", onScroll);

      return () => $termo.removeEventListener("scroll", onScroll);
    }
  }, [scrollEnd, scrollTop, termo]);

  useEffect(() => {
    const termoAceito = {
      ...termo,
      aceito: true,
      dispositivo: detectaDispositivo(),
    }
    dispatch({ type: 'ADICIONA_TERMOS', termos: [termoAceito]});

  }, [dispatch, termo])

  useEffect(() => {
      validaEtapa();
  }, [scrollEnd, validaEtapa]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="etapa-formulario">
      <Titulo>Escola de Formação de Líderes</Titulo>
      <SubTitulo>{evento.cidade}</SubTitulo>
      <DataEvento>{evento.data}</DataEvento>
      {termo ? (
        <div>
          <TermoContainer dangerouslySetInnerHTML={{ __html: termo.texto || '' }} />
          <AvisoTermo>* Necessário leitura completa para continuar.</AvisoTermo>
        </div>
      ) : <SpinnerContainer><CircularProgress /></SpinnerContainer>}
    </div>
  );
}

export default TermoEscola;
