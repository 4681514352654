import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ehSistemaInstituto, getDominio, obtemAmbiente } from '../../../../../_recursos/js/util';

import { Content, Title, Subtitle } from '../../../../../componentes/Interface';

import EventoZoom from './EventoZoom';

import { ContainerStyled, ObterLinkEvento, IconeVoluntario, MarcaTexto, IconeInstituicao } from './styles';
import { IStoreRedux } from 'Types/Reducers';

interface IConfirmacaoProps {
  tipoConfirmacao: string;
  icone?: any;
  redirParaEvento?: { irParaEvento: boolean, cpf?: string };
}

export default function Confirmacao({ redirParaEvento = { irParaEvento: false }, tipoConfirmacao, icone = IconeVoluntario }: IConfirmacaoProps) {
  const dispatch = useDispatch();
  const stateEvento = useSelector((state: IStoreRedux) => state.evento);

  useEffect(() => {
    dispatch({ type: 'ALTERA_INSCRITO_VOLUNTARIADO', inscritoVoluntariado: true });
  }, [dispatch]);

  const getLayoutFinal = useMemo(() => {
    let botaoObterLinkEvento = null;
    if (redirParaEvento.cpf && redirParaEvento.irParaEvento) {
      const nome = getDominio()
      const dominio = ehSistemaInstituto(nome)
        ? `${nome}.org.br`
        : `${nome !== 'default' ? nome : 'infogab'}.com.br`
      const prefixo = obtemAmbiente() === 'production'
        ? 'evento'
        : 'evento-develop';

      const goto = `https://${prefixo}.${dominio}/?cpf=${redirParaEvento.cpf}`;

      botaoObterLinkEvento = (
        <ObterLinkEvento onClick={() => window.open(goto, '_self')}>
          Obter link para evento
        </ObterLinkEvento>
      );
    }

    if (tipoConfirmacao === 'deputado') {
      return (
        <>
          <Title>
            Muito obrigado,
          </Title>
          <Subtitle fw="300" align='center'>
            Sua inscrição foi concluída <br/>com sucesso!
          </Subtitle>
          {stateEvento.data && <EventoZoom evento={stateEvento} />}
          {botaoObterLinkEvento}
        </>
      )
    } else if (tipoConfirmacao.match(/libcom|arco/)) {
      return (
        <>
          <Subtitle fw="300">
            Seu cadastro foi realizado com sucesso.
          </Subtitle>
        </>
      )
    } else {
      return (
        <Subtitle fw="300">
          Agora você é um <MarcaTexto>voluntário.</MarcaTexto>
        </Subtitle>
      )
    }
  }, [redirParaEvento.cpf, redirParaEvento.irParaEvento, stateEvento, tipoConfirmacao]);

  return (
    <ContainerStyled>
      <Content full verticalCenter horizontalCenter padding>
        {
          (tipoConfirmacao.match(/libcom|arco/) )
            ? <IconeInstituicao src={icone} alt={`Icone ${tipoConfirmacao}`} className={tipoConfirmacao} />
            : <IconeVoluntario />
        }
        {getLayoutFinal}
      </Content>
    </ContainerStyled>
  );
}

