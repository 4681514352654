import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { primeiraMaiusculaTodasPalavras } from '../../../../_recursos/js/util';
import { CampoDeTextoOutlined } from '../styles';
import { IStoreRedux } from 'Types/Reducers';

const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}\s([A-Za-z\s]{2,3})?[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']{4,}$/;

function CampoNome() {
  const nome = useSelector<IStoreRedux, string>((store) => store.dados.nome || '');
  const dispatch = useDispatch();

  const [valido, setValido] = useState<boolean>(false);

  const aoAlterar = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const novoValor = primeiraMaiusculaTodasPalavras(e.target.value);
    dispatch({ type: 'ALTERA_NOME', nome: novoValor });
  }, [dispatch]);

  useEffect(() => {
    const resultado = regex.test(nome);
    setValido(resultado);
    dispatch({ type: 'VALIDA_CAMPO', nome: resultado });
    if (resultado)
      dispatch({ type: 'ALTERA_NOME', nome });
  }, [dispatch, nome]);

  useEffect(() => {
    if (nome === '')
      dispatch({ type: 'ALTERA_NOME', nome: null });
  }, [dispatch, nome]);

  return (
    <div className="campo-formulario">
      <CampoDeTextoOutlined
        fullWidth
        label='Nome completo'
        error={nome !== '' && !valido}
        value={nome}
        onChange={aoAlterar}
        autoComplete="name"
      />
    </div>
  );
}

export default CampoNome;
