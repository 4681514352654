import React from 'react';

import { _404 as Erro404 } from '../../paginas';

import InscricaoProvider from './context/provider';
import InscricaoContext from './context';
import Spinner from '../../componentes/Spinner';
import EstruturaEtapas from './etapas/EstruturaEtapas';


function Inscricao() {
  return (
    <InscricaoProvider>
      <InscricaoContext.Consumer>
        {
          (config) => (
            config !== null
              // se o config carregado, inicia etapas senao esta carregando
              ? ('etapas' in config) ? <EstruturaEtapas /> : <Spinner />
              : <Erro404 /> // se config === null, erro
          )
        }
      </InscricaoContext.Consumer>
    </InscricaoProvider>
  )
}

export default Inscricao
