import React, { memo } from 'react';
import { Container, Content, Text, Title } from '../../Interface';

function BadRequest({ referencia }) {
  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom align='center'>Ops...</Title>
        <Text gutterBottom align='center'>
          O código <strong>{referencia}</strong> que
          você inseriu no endereço está incorreto.
        </Text>
      </Content>
    </Container>
  )
}

export default memo(BadRequest);