import React, { memo } from 'react';

const TextoInstituicao = ({ instituicao }: { instituicao: string }) => (
  <>
    Ao prosseguir o seu cadastro será armazenado na base de dados do <strong>{instituicao.toUpperCase()}</strong>.
    As suas informações serão armazenados em ambiente seguro e não serão compartilhados com terceiros.
    <br /><br />
    Clicando em <strong>ACEITO</strong>, você declara estar ciente da Política de Privacidade de Dados
    e autoriza o envio de comunicações sobre suas atividades, convites para reuniões e eventos.
  </>
);

export default memo(TextoInstituicao);
