import React, { useCallback, useState } from 'react';
import Spinner from '../../../../componentes/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreRedux } from 'Types/Reducers';
import { post } from '../../../../_axios/requisicao';
import RespostaInesperada from '../RespostaInesperada';
import useTentarNovamente from '../../assets/useTentarNovamente';


function InscricaoHashConvite() {
  // const carregando = useSelector((state: IStoreRedux) => state.app.carregando);
  const hash = useSelector((state: IStoreRedux) => state.app.hash);
  const dadosInscricao = useSelector((state: IStoreRedux) => ({
    dados: state.dados,
    contatos: state.contatos,
    endereco: state.endereco,
    termos: state.termos,
  }));

  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(true);

  const retry = useTentarNovamente(
    useCallback(
      () => {
        // dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
        post('api-pessoas', `/inscricoes/${hash}`, dadosInscricao, { referencia: 'bcom' })
          .then((res) => dispatch({ type: 'ALTERA_INSCRITO', inscrito: res.status === 200 }))
          .finally(() => {
            setCarregando(false);
            // dispatch({ type: 'ALTERA_CARREGANDO', carregando: false })
          });
      },
      [dadosInscricao, dispatch, hash]
    )
  );

  return carregando ? <Spinner /> : <RespostaInesperada retry={retry} />;
}

export default InscricaoHashConvite;
