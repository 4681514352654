import styled from 'styled-components';

import { Button } from '@material-ui/core';
import withTheme from '@material-ui/styles/withTheme';

export const BotaoCupom = withTheme(styled(Button)`
  & {
    align-self: flex-start;
    margin: 2rem 0;
    padding: .5rem .8rem;
    display: block;
    width: 100%;
    color: ${props => props.theme.palette.primary.main};
    border: 1px solid ${props => props.theme.palette.primary.main};
    background-color: #fafafa !important;

    &:hover {
      filter: brightness(1.1);
    }

    & svg {
      margin-right: .5rem;
      vertical-align: bottom;
    }
  }
`);
