import styled from "styled-components";

export const IconeLibcom = styled.img`
  width: 80px;
`;

export const TextoCabecalho = styled.span`
  user-select: none;
  height: 100%;
  width: calc(100% - 100px);
  line-height: 1.5rem;

  margin-left: 2rem;

  h3 {
    margin: 0;
  }
`;
