import React from 'react';
import { Text } from '../../../../../../componentes/Interface';

import { DetalhesEvento, DetalhesEventoItem, TextoBold } from './styles';
import { IStoreRedux } from 'Types/Reducers';


type TStateEvento = Pick<IStoreRedux, 'evento'>

export default function EventoZoom(stateEvento: TStateEvento) {
  let horario, duracao;

  if (stateEvento.evento.horario) {
    const horarioSplit = stateEvento.evento.horario.split(':');
    horario = `${horarioSplit[0]}h${horarioSplit[1]}`;
  }

  if(stateEvento.evento.duracao) {
    const duracaoSplit = stateEvento.evento.duracao.split(':');
    const duracaoHoras = +duracaoSplit[0];
    const duracaoMinutos = +duracaoSplit[1];

    const horas = duracaoHoras > 0 ? `${duracaoHoras} hora${duracaoHoras > 1 ? 's' : ''}` : '';
    const minutos = duracaoMinutos > 0 ? `${duracaoMinutos} minutos` : '';

    duracao = `${horas} ${horas && minutos ? 'e' : ''} ${minutos}`;
  }

  return (
    <>
      <br />
      <DetalhesEvento>
        <DetalhesEventoItem>
          <TextoBold>Data</TextoBold> {stateEvento.evento.data}
        </DetalhesEventoItem>
        <DetalhesEventoItem>
          <TextoBold>Horário</TextoBold> {horario}
        </DetalhesEventoItem>
        <DetalhesEventoItem>
          <TextoBold>Duração</TextoBold> {duracao}
        </DetalhesEventoItem>
      </DetalhesEvento>
      <br />
      <Text align='center'>
        <TextoBold>Instruções:</TextoBold> Você receberá o link para participação pouco antes do evento.
      </Text>
    </>
  )
}