import axios from 'axios';

import { browserName, browserVersion, osName, osVersion, deviceType } from 'react-device-detect';

import { get } from '../../_axios/requisicao';
import { nomeSistemas, sistemasDeputados, sistemasInstitutos } from './config';

export function ehSistemaInstituto(sistema: string) {
  return sistemasInstitutos.includes(sistema)
}

export function ehSistemaDeputado(sistema: string) {
  return sistemasDeputados.includes(sistema)
}

export function primeiraMaiusculaTodasPalavras(valor: string)
{
  if (typeof valor !== 'string')
    return valor;

  const regex = /^da$|^de$|^do$|^das$|^dos$/;
  const palavras = valor.toLowerCase().split(' ');

  for (var i = 0; i < palavras.length; i++) {
    if (!regex.test(palavras[i])) {
      const primeiraLetra = palavras[i].charAt(0).toUpperCase();
      const restanteDaPalavra = palavras[i].substr(1);
      palavras[i] = `${primeiraLetra}${restanteDaPalavra}`;
    }
  }

  return palavras.join(' ');
}

export function detectaDispositivo() {
  const dispositivo = `${primeiraMaiusculaTodasPalavras(deviceType)}, ${browserName} ${browserVersion}, ${osName} ${osVersion}`;

  return dispositivo;
}

const DNS_GOOGLE = 'https://dns.google.com/resolve';
/**
 * Função que valida os registros de MX de um dominio,
 * permitindo verificar se um sistema de email existe para o dominio.
 *
 * **Problemas da implementação**
 * - Pode ocorrer problemas de conexão causando erro HTTP2_PROTOCOL_ERROR em alguns casos.
 *
 * @param dominio String do dominio a ser validado
 * @returns Validação do dominio de email
 */
export async function validaDominioPorDnsGoogle(dominio: string) {
  const params = { type: 'MX', name: dominio }
  const valido = await axios.get(DNS_GOOGLE, { params })
    .then(({ data }) =>
      // DNS SUCCESS standard status
      data.Status === 0
      // && existe IP
      && (data.Answer && data.Answer.some((item: any) => item.data))
    )
    .catch(() => false) as boolean;

  return valido;
}

export async function validaLinkInscricao(referencia: string, idUsuario?: string, assinaturaUrl?: string) {
  try {
    return await get(
      'api-pessoas',
      `/valida-link-inscricao/${referencia}/${idUsuario}?signature=${assinaturaUrl}`,
      {}
    );
  }
  catch (error) { return false; }
}

export async function validaHashOuReferencia(validacao: string, referencia: string) {
  // TODO: abstração dos sets
  try {
    return await get(
      'api-pessoas',
      `/valida-${validacao}/${referencia}`,
      {}
    );
  } catch (error) {
    // @ts-ignore
    return { data: error.data, status: error.status }
  }
}

/**
 * Retorna o domínio atual
 * @returns nome do domínio
 */
export function getDominio() {
  const dominioCompleto = window.location.hostname;
  const [dominio] = dominioCompleto.match(new RegExp(nomeSistemas.join('|'))) || [];

  return dominio || 'default';
}

export function obtemAmbiente() {
  // TODO: checa se tem queryParam ?ambiente=local/develop

  const host = window.location.host;
  const local = !host.match(/(cadastro(-develop)?\..+\.(org|com)\.br)|(app-inscricao)/)
  if (local)
    return 'local'

  if (host.includes('develop'))
    return 'develop';

  return 'production'
}

/**
 * Retorna schema baseado no domínio ou na referencia
 * @param referencia
 * @returns
 */
export function getSchema(referencia?: string): string {
  const dominio = getDominio();

  if (dominio === 'libcom' || (referencia && (referencia === 'libcom' || referencia.startsWith('bcom')))) {
    return 'libcom'
  } else if (dominio === 'arco' || (referencia && (referencia === 'arco' || referencia.startsWith('co')))) {
    return 'arco'
  } else {
    return `z0${referencia}`;
  }
}

export function getReferenciaPeloDominio() {
  const dominio = getDominio();

  switch (dominio) {
    case 'enricomisasi':
      return '1453em';

    case 'reinaldoalguz':
      return '0001ra';

    case 'rogeriossantos':
      return '0254js';

    case 'taniafruchi':
      return '3843tf';

    case 'libcom':
      return 'libcom';

    case 'arco':
      return 'arco'

    default:
      return 'default';
  }
}

export function verificaPadraoReferenciaSchema(possivelReferencia: string) {
  const regex = /^([0-9]{4}[a-z]{2})$/
  return regex.test(possivelReferencia)
}

export function verificaPadraoHashLink(possivelHash: string) {
  const regex = /^([0-9]{4}[a-z]{2}|bcom|co).+$/
  return regex.test(possivelHash)
}

/**
 * Recebe idTermoTipo e retorna Termo
 * @param idTermoTipo
 * @returns objeto com o idTermo e texto
 */
export async function getTermos(idTermoTipo: number, schema: string = 'libcom') {
  try {
    const retorno = await get('api-pessoas', `/pessoas/termos?tipo=${idTermoTipo}&atual=1`, { schema });
    if (retorno) {
      return retorno.data[0];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

const data = new Date();

/**
 * Retorna dia atual
 * @returns {string} - dia atual
 */
export function getDiaAtual(): number {
  return data.getDate();
}

/**
 * Retorna o mês atual
 * @reutn {string} - label do mês atual
 */
export function getMesAtual(): string {
  const meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];

  return meses[data.getMonth()];
}

/**
 * Retorna o ano atual
 * @returns - ano atual
 */
export function getAnoAtual() {
  return data.getFullYear();
}
