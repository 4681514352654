import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import InputMask from 'react-input-mask';

import MenuItem from '@material-ui/core/MenuItem';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { InputSuffix } from '../../../../componentes/Interface';

import { CamposEmLinha, CampoDeTextoOutlined } from '../styles';

import { get } from '../../../../_axios/requisicao';
import { primeiraMaiusculaTodasPalavras } from '../../../../_recursos/js/util';

import { regex, estadosOpcoes } from './constants';
import { IStoreRedux } from 'Types/Reducers';

interface Endereco {
  cep: string;
  cidade: string;
  estado: string;
  idCidade: number | null;
  idBairro: number | null;
  bairro: string;
  idLogradouro: number | null;
  logradouro: string;
  numero: string;
  complemento: string;
}

interface ValidaEndereco {
  cep: boolean;
  cidade: boolean;
  estado: boolean;
  idCidade: boolean;
  bairro: boolean;
  logradouro: boolean;
  numero: boolean;
}

type Cidade = Pick<Endereco, 'cidade' | 'idCidade'>;
type Bairro = Pick<Endereco, 'bairro' | 'idBairro'>;
type Logradouro = Pick<Endereco, 'logradouro' | 'idLogradouro'>;

function CampoEndereco() {
  const cep = useSelector<IStoreRedux, string>((store) => store.endereco?.cep || '');
  const estado = useSelector<IStoreRedux, string>((store) => store.endereco?.estado || '');
  const idCidade = useSelector<IStoreRedux, string>((store) => store.endereco?.idCidade);
  const cidade = useSelector<IStoreRedux, string>((store) => store.endereco?.cidade || '');
  const idBairro = useSelector<IStoreRedux, string>((store) => store.endereco?.idBairro);
  const bairro = useSelector<IStoreRedux, string>((store) => store.endereco?.bairro || '');
  const logradouro = useSelector<IStoreRedux, string>((store) => store.endereco?.logradouro || '');
  const numero = useSelector<IStoreRedux, string>((store) => store.endereco?.numero || '');
  const complemento = useSelector<IStoreRedux, string>((store) => store.endereco?.complemento || '');
  const dispatch = useDispatch();

  const [valido, setValido] = useState<ValidaEndereco>({
    cidade: false,
    estado: false,
    idCidade: false,
    bairro: false,
    cep: false,
    logradouro: false,
    numero: false,
  });

  const autocompletarPorCEP = useCallback(async (cep) => {
    const { data, status } = await get('api-enderecos', `/buscar-localidade?cep=${cep}`, {})
    if (status === 200) {
      dispatch({
        type: 'ALTERA_ENDERECO',
        idCidade: data.cidade_id || null,
        cidade: data.cidade || null,
        estado: data.estado || null,
        idBairro: data.bairro_id || null,
        bairro: data.bairro || null,
        idLogradouro: data.logradouro_id || null,
        logradouro: data.logradouro || null,
        numero: null,
        complemento: null,
      });
    }
  }, [dispatch]);

  const aoAlterarCEP = useCallback((e) => {
    const cep = e.target.value;
    dispatch({ type: 'ALTERA_ENDERECO', cep });
    if (regex.cep.test(cep))
      autocompletarPorCEP(cep);
  }, [autocompletarPorCEP, dispatch]);

  const aoAlterarNumero = useCallback((e) => {
    dispatch({ type: 'ALTERA_ENDERECO', numero: e.target.value });
  }, [dispatch]);

  const aoAlterarComplemento = useCallback((e) => {
    dispatch({ type: 'ALTERA_ENDERECO', complemento: e.target.value });
  }, [dispatch]);

  const aoSelecionarEstado = useCallback((e) => {
    dispatch({
      type: 'ALTERA_ENDERECO',
      estado: e.target.value,
      cidade: null,
      idCidade: null,
      bairro: null,
      idBairro: null,
      logradouro: null,
      idLogradouro: null,
      numero: null,
      complemento: null,
    });
  }, [dispatch]);

  const aoSelecionarCidade = useCallback((_, valor: Cidade | null) => {
    if (!valor)
      return;

    const { cidade, idCidade } = valor;
    dispatch({
      type: 'ALTERA_ENDERECO',
      cidade,
      idCidade,
      bairro: null,
      idBairro: null,
      logradouro: null,
      idLogradouro: null,
      numero: null,
      complemento: null,
    })
  }, [dispatch]);

  const aoSelecionarBairro = useCallback((_, value: Bairro | null) => {
    if (!value)
      return;

    const { bairro, idBairro } = value;
    dispatch({
      type: 'ALTERA_ENDERECO',
      bairro,
      idBairro,
      logradouro: null,
      idLogradouro: null,
      numero: null,
      complemento: null,
    })
  }, [dispatch]);

  const aoSelecionarLogradouro = useCallback((_, value: Logradouro | null) => {
    if (!value)
      return;

    const { logradouro, idLogradouro } = value;
    dispatch({
      type: 'ALTERA_ENDERECO',
      logradouro,
      idLogradouro,
      numero: null,
      complemento: null,
    })
  }, [dispatch]);

  const [cidadesOpcoes, setCidadesOpcoes] = useState<Cidade[]>([]);
  const [bairrosOpcoes, setBairrosOpcoes] = useState<Bairro[]>([]);
  const [logradourosOpcoes, setLogradourosOpcoes] = useState<Logradouro[]>([]);

  const refPesquisaCidade = useRef(-1);
  const refPesquisaBairro = useRef(-1);
  const refPesquisaLogradouro = useRef(-1);

  const pesquisaCidades = useCallback(async (cidade) => {
    if (!estado)
      return;

    const retorno = await get('api-enderecos', `/cidades?uf=${estado}&nome=${cidade}`, {});

    if (retorno.status === 200) {
      const opcoes = retorno.data
        .map((item: any) => ({
          idCidade: item.idcidade,
          cidade: item.cidade
        }));
      setCidadesOpcoes(opcoes);

      if (opcoes.length > 0 && opcoes[0].cidade.trim().toLowerCase() === cidade.toLowerCase())
        aoSelecionarCidade(undefined, opcoes[0]);
    }
  }, [aoSelecionarCidade, estado]);

  const pesquisaBairros = useCallback(async (bairro) => {
    if (!idCidade)
      return;

    const retorno = await get('api-enderecos', `/bairros?id_cidade=${idCidade}&nome=${bairro}`, {});

    if (retorno.status === 200) {
      const opcoes = retorno.data
        .map((item: any) => ({
          idBairro: item.idbairro,
          bairro: item.bairro
        }));
      setBairrosOpcoes(opcoes);

      if (opcoes.length > 0 && opcoes[0].bairro.trim().toLowerCase() === bairro.toLowerCase())
        aoSelecionarBairro(undefined, opcoes[0]);
    }
  }, [aoSelecionarBairro, idCidade]);

  const pesquisaLogradouros = useCallback(async (logradouro) => {
    if (!idCidade || !idBairro)
      return;

    const retorno = await get('api-enderecos', `/logradouros?id_cidade=${idCidade}&id_bairro=${idBairro}&nome=${logradouro}`, {});

    if (retorno.status === 200) {
      const opcoes = retorno.data
        .map((item: any) => ({
          idLogradouro: item.idlogradouro,
          logradouro: item.logradouro
        }));
      setLogradourosOpcoes(opcoes);

      if (opcoes.length > 0 && opcoes[0].logradouro.trim().toLowerCase() === logradouro.toLowerCase())
        aoSelecionarLogradouro(undefined, opcoes[0]);
    }
  }, [aoSelecionarLogradouro, idBairro, idCidade]);

  const aoBuscarCidade = useCallback((_, value: string) => {
    const valor = primeiraMaiusculaTodasPalavras(value);

    window.clearTimeout(refPesquisaCidade.current);
    dispatch({ type: 'ALTERA_ENDERECO', cidade: valor, idCidade: null });
    refPesquisaCidade.current = window.setTimeout(() => pesquisaCidades(valor), 800);
  }, [dispatch, pesquisaCidades]);

  const aoBuscarBairro = useCallback((_, value: string) => {
    const valor = primeiraMaiusculaTodasPalavras(value);

    window.clearTimeout(refPesquisaBairro.current);
    dispatch({ type: 'ALTERA_ENDERECO', bairro: valor, idBairro: null });
    refPesquisaBairro.current = window.setTimeout(() => pesquisaBairros(valor), 800);
  }, [dispatch, pesquisaBairros]);

  const aoBuscarLogradouro = useCallback((_, value: string) => {
    const valor = primeiraMaiusculaTodasPalavras(value);

    window.clearTimeout(refPesquisaLogradouro.current);
    dispatch({ type: 'ALTERA_ENDERECO', logradouro: valor, idLogradouro: null });
    refPesquisaLogradouro.current = window.setTimeout(() => pesquisaLogradouros(valor), 800);
  }, [dispatch, pesquisaLogradouros]);

  const listaDeEstados = useMemo(() => (
    estadosOpcoes.map(estado => (
      <MenuItem key={estado.sigla} value={estado.sigla}>
        {estado.nome}
      </MenuItem>
    ))
  ), []);

  useEffect(() => {
    const resultado = {
      cep: regex.cep.test(cep),
      cidade: regex.cidade.test(cidade),
      estado: regex.estado.test(estado),
      idCidade: idCidade ? regex.idCidade.test(idCidade.toString()) : false,
      bairro: regex.bairro.test(bairro),
      logradouro: regex.logradouro.test(logradouro),
      numero: regex.numero.test(numero),
    };

    setValido(resultado);
    const validado = Object.values(resultado).every(booleano => booleano);
    dispatch({ type: 'VALIDA_CAMPO', endereco: validado });
    if (validado)
      dispatch({
        type: 'ALTERA_ENDERECO',
        cep,
        cidade,
        estado,
        idCidade,
        bairro,
        logradouro,
        numero,
      });
  }, [bairro, cep, cidade, dispatch, estado, idCidade, logradouro, numero]);

  // Reset de listas de autocompletes quando dependencias mudam
  useEffect(() => { pesquisaCidades('') }, [dispatch, pesquisaCidades]);
  useEffect(() => { pesquisaBairros('') }, [dispatch, pesquisaBairros]);
  useEffect(() => { pesquisaLogradouros('') }, [dispatch, pesquisaLogradouros]);

  return (
    <>
      <div className="campo-formulario">
        <InputMask
          mask='99999-999'
          maskChar=' '
          value={cep}
          onChange={aoAlterarCEP}
        >
          {
            (inputProps: any) =>
              <CampoDeTextoOutlined
                {...inputProps}
                type='tel'
                autoComplete='postal-code'
                label='CEP'
                fullWidth
                error={cep !== '' && !valido}
              />
          }
        </InputMask>
      </div>

      <CamposEmLinha>
        <CampoDeTextoOutlined
          select
          label='Estado'
          value={estado}
          onChange={aoSelecionarEstado}
          style={{ marginRight: 12, width: '75%' }}
          SelectProps={{
            MenuProps: {
              style: { maxHeight: 400 },
            }
          }}
        >
          {listaDeEstados}
        </CampoDeTextoOutlined>

        <Autocomplete
          freeSolo
          clearOnEscape
          disableClearable
          fullWidth
          getOptionLabel={option => option.cidade}
          options={cidadesOpcoes}
          onChange={aoSelecionarCidade}
          onInputChange={aoBuscarCidade}
          renderInput={(params) =>
            <CampoDeTextoOutlined
              {...params}
              InputProps={{ ref: params.InputProps.ref }}
              fullWidth
              label='Cidade'
              error={cidade !== '' && (!valido.cidade || !valido.idCidade)}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                value: cidade
              }}
            />
          }
        />
      </CamposEmLinha>

      <div className="campo-formulario">
        <Autocomplete
          freeSolo
          clearOnEscape
          disableClearable
          fullWidth
          getOptionLabel={option => option.bairro}
          options={bairrosOpcoes}
          onChange={aoSelecionarBairro}
          onInputChange={aoBuscarBairro}
          renderInput={(params) =>
            <CampoDeTextoOutlined
              {...params}
              InputProps={{ ref: params.InputProps.ref }}
              fullWidth
              label='Bairro'
              error={bairro !== '' && !valido.bairro}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                value: bairro
              }}
            />
          }
        />
      </div>

      <div className="campo-formulario">
        <Autocomplete
          freeSolo
          clearOnEscape
          disableClearable
          fullWidth
          getOptionLabel={option => option.logradouro}
          options={logradourosOpcoes}
          onChange={aoSelecionarLogradouro}
          onInputChange={aoBuscarLogradouro}
          renderInput={(params) =>
            <CampoDeTextoOutlined
              {...params}
              InputProps={{ ref: params.InputProps.ref }}
              fullWidth
              label='Logradouro'
              placeholder='Rua, Avenida, Alameda...'
              error={logradouro !== '' && !valido.logradouro}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                value: logradouro
              }}
            />
          }
        />
      </div>

      <CamposEmLinha>
        <CampoDeTextoOutlined
          fullWidth
          label='Numero'
          style={{ marginRight: 12, width: '60%' }}
          error={numero !== '' && !valido.numero}
          value={numero}
          onChange={aoAlterarNumero}
          autoComplete="off"
        />

        <CampoDeTextoOutlined
          fullWidth
          label='Complemento'
          InputProps={{ endAdornment: <InputSuffix position='end'>(opcional)</InputSuffix> }}
          value={complemento}
          onChange={aoAlterarComplemento}
          autoComplete="off"
        />
      </CamposEmLinha>
    </>
  );
}

export default CampoEndereco;