import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';

export default function DataNascimento() {
  const valor = useSelector(state => {
    const dataNascimento = state.dados.dataNascimento;
    if (dataNascimento === null || dataNascimento === '' || dataNascimento === undefined)
      return dataNascimento;
    else
      return dataNascimento.split('-').reverse().join('/');
  });
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'data_nascimento' }), [dispatch]);

  function altera(event) {
    let valorAlterado = event.target.value;
    valida(valorAlterado);
    if (valorAlterado !== null && valorAlterado !== '' && valorAlterado !== undefined)
      valorAlterado = valorAlterado.split('/').reverse().join('-');
    dispatch({ type: `ALTERA_DATA_NASCIMENTO`, dataNascimento: valorAlterado });
  }

  function validaCoerenciaData(valor = '') {
    const anoAtual = new Date().getFullYear();
    const anoInformado = Number(valor.split('/')[2]);
    return (anoInformado < anoAtual && anoInformado > (anoAtual - 130));
  }

  function valida(valor) {
    const regex = /(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))/;
    const resultado = regex.test(valor) && validaCoerenciaData(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <InputMask
        fullWidth
        label='Data de nascimento'
        mask='99/99/9999'
        maskChar=' '
        type='tel'
        error={valido !== null && !valido}
        value={valor || ''}
        onChange={altera}>
        {(inputProps) => <TextField {...inputProps} autoComplete="bday"/>}
      </InputMask>
    </div>
  );
}
