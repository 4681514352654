import { createStore, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { app, contatos, dados, endereco, termos, vereador, evento, eventoEscola } from "./reducers";

const combinedReducers = combineReducers({
  app,
  contatos,
  dados,
  endereco,
  termos,
  vereador,
  evento,
  eventoEscola
});

const persistConfig = {
  key: 'app-inscricao',
  storage
};

const store = createStore(
  persistReducer(
    persistConfig,
    combinedReducers
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export { persistor, store };