import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { isMobileOnly } from 'react-device-detect';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import MultiEtapas from './paginas/Etapas/MultiEtapas';
import SingleEtapas from './paginas/Etapas/SingleEtapas';
import Final from './paginas/Final';
import Inicial from './paginas/Inicial';

import { multiEtapas, singleEtapas } from './paginas/Etapas/config';
import { get } from '../../_axios/requisicao';

import { Campos } from 'Types/Reducers/App';
import { IStoreRedux } from 'Types/Reducers';

function renderizaPixelCode() {
  const code = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '3067434826891058');
    fbq('track', 'PageView');
  `;

  return { __html: code };
}

interface ConfigEtapas {
  etapas: Array<{ path: string, component: JSX.Element, posicao?: number }>;
  modo: 'single' | 'multi';
}

interface ValidaHashEventoParams {
  cpf?: string | null;
  cupom?: string | null;
  hash?: string | null;
}

type InscricaoEFLResources =
  IPageConfigParams['config']
  & ConfigEtapas
  & {
    validaHashEvento(params?: ValidaHashEventoParams): Promise<any>;
    lote: string | null
  }


export const InscricaoEFLContext = createContext({} as InscricaoEFLResources);

export default function EventoPublico() {
  const { search } = useLocation();
  const match = useRouteMatch();
  const { push } = useHistory();

  const [lote, setLote] = useState(new URLSearchParams(search).get('lote'))

  const dispatch = useDispatch();
  const hash = useSelector((state: IStoreRedux) => state.app.hash);
  const cupom = useSelector((state: IStoreRedux) => state.app.cupom || '');
  const stateCpf = useSelector((state: IStoreRedux) => state.dados.cpf || '');

  const matches = useMediaQuery('(min-height: 520px) and (min-width: 640px)', { noSsr: true });
  const { etapas, modo } = useMemo<ConfigEtapas>(() => {
    if (isMobileOnly)
      return { etapas: multiEtapas, modo: 'multi' };

    return matches
      ? { etapas: singleEtapas, modo: 'single' }
      : { etapas: multiEtapas, modo: 'multi' };
  }, [matches]);

  useEffect(() => {
    if (modo === 'single') {
      const campos = {
        celular: false,
        cpf: false,
        email: false,
        endereco: false,
        nome: false,
        termos: false,
        dataNascimento: false,
      } as Record<Campos, boolean>;
      dispatch({ type: 'ALTERA_CAMPOS', camposValidos: campos });
    } else
      dispatch({ type: 'ALTERA_CAMPOS', camposValidos: null });

      push(etapas[0].path);
  }, [modo, etapas, push, dispatch]);

  const validaHashEvento = useCallback(async (params: ValidaHashEventoParams = {}) => {
    const searchParams = new URLSearchParams({
      cpf: params.cpf ?? (stateCpf || ''),
      hashEvento: params.hash ?? (hash || ''),
      cupom: params.cupom ?? (cupom || ''),
      lote: lote ?? ''
    });

    const uriInscricaoEvento = `/eventos/valida-hash-evento?${searchParams.toString()}`

    try {
      const retorno = await get('api-eventos', uriInscricaoEvento, { schema: 'libcom' });

      if (retorno) {
        const {
          cidade,
          data,
          idevento,
          titulo,
          socio,
          valor_evento,
          valor_inscricao,
          qtd_parcelas,
          status,
          permite_inscricao,
          lote,
        } = retorno.data.evento;

        /*
          Código comentado
          - Lógica para pular inscricao caso exista um pedido odoo
            e dados do convidado/pessoa para o pagamento diretamente
        */
        // const {
        //   idconvidado,
        //   nome,
        //   celular,
        //   email,
        //   dtNascimento,
        //   endereco_cep,
        //   endereco_estado,
        //   endereco_cidade,
        //   endereco_logradouro,
        //   endereco_numero,
        // } = retorno.data.convidado;

        // const { pedidoOdoo } = retorno.data;

        // const celularFormatado = celular ? celular.replace(/\D/g, '').replace(/^(\d\d)(\d)/, '($1) $2').replace(/(\d{5})(\d)/, '$1-$2').replace(/(\s\d{4})\d+?$/, '$1') : celular;
        const statusFormatado = status ? status.toLowerCase().replace(/\s/g, '-') : status;

        // dispatch({ type: 'ALTERA_IDPESSOA', idPessoa: idconvidado });

        // dispatch({ type: 'ALTERA_NOME', nome: nome.trim() });
        // dispatch({ type: 'ALTERA_CELULAR', numero: celularFormatado, tipo: 4, whatsapp: true });
        // dispatch({ type: 'ALTERA_EMAIL', tipo: 1, endereco: email });
        // if (dtNascimento && dtNascimento.length === 9) {
        //   const dataFormatada = dtNascimento.split('-');
        //   dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: `${dataFormatada[0]}-${dataFormatada[1]}-0${dataFormatada[2]}` });
        // } else {
        //   dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: dtNascimento });
        // }

        // const endereco = {
        //   cep: endereco_cep,
        //   estado: endereco_estado,
        //   cidade: endereco_cidade,
        //   // bairro: action.bairro,
        //   logradouro: endereco_logradouro,
        //   numero: endereco_numero,
        // }
        // dispatch({ type: 'ALTERA_ENDERECO', ...endereco });

        dispatch({ type: 'ALTERA_CIDADE', cidade });
        dispatch({ type: 'ALTERA_DATA', data });
        dispatch({ type: 'ALTERA_IDEVENTO', idevento });
        dispatch({ type: 'ALTERA_TITULO', titulo });
        dispatch({ type: 'ALTERA_VALOR_EVENTO', valor_evento });
        dispatch({ type: 'ALTERA_VALOR_INSCRICAO', valor_inscricao });
        dispatch({ type: 'ALTERA_PARCELAS', qtd_parcelas });
        dispatch({ type: 'ALTERA_SOCIO', socio });
        dispatch({ type: 'ALTERA_STATUS', 'status': statusFormatado });
        // dispatch({ type: 'ALTERA_PARCELAS', qtd_parcelas });

        // redefine o lote caso necessario
        setLote(lote);

        // const statusPagamento = (
        //   statusFormatado === 'pagamento-andamento'
        //   || statusFormatado === 'pagamento-pendente'
        //   || statusFormatado === 'pagamento-cancelado'
        //   || statusFormatado === 'pagamento-devolvido'
        // );

        // if (pedidoOdoo.id && statusPagamento) {
        //   return { pedidoOdoo };
        // }

        if (statusFormatado === 'isento' || statusFormatado === 'inscrito') {
          push('/convite-publico/confirmacao');
          return;
        }

        if (retorno.data.cupom) {
          const { valido } = retorno.data.cupom
          dispatch({ type: 'ALTERA_CUPOM_VALIDO', cupomValido: valido });
        }


        return {
          permiteInscricao: permite_inscricao,
          cupomValido: retorno.data.cupom?.valido ?? false,
        };
      }
    } catch (error) {
      console.error(error)
    }
  }, [stateCpf, hash, cupom, lote, dispatch, push]);

  const appConfig = useMemo(() => ({
    etapas,
    modo,
    validaHashEvento,
    referencia: 'libcom',
    schema: 'libcom',
    lote,
  }), [etapas, lote, modo, validaHashEvento]);

  return (
    <>
      {/* Meta Pixel Code */}
      <script dangerouslySetInnerHTML={renderizaPixelCode()} />
      <noscript>
        <img
          alt=""
          height={1}
          width={1}
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=3067434826891058&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Meta Pixel Code */}
      <InscricaoEFLContext.Provider value={appConfig}>
        <Switch>
          <Route
            path="/convite-publico/confirmacao"
            render={() => <Final />}
            exact
          />

          <Route path="/convite-publico/formulario" exact>
            <SingleEtapas component={etapas[0].component} />
          </Route>

          <Route
            path="/convite-publico/formulario/(dados|contatos|endereco|como-conheceu|termo-adesao)"
            render={() => <MultiEtapas />}
          />

          <Route
            path={match.path}
            render={() => <Inicial />}
            exact
          />
        </Switch>
      </InscricaoEFLContext.Provider>
    </>
  );
}