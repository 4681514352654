import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Termo from '../../../../componentes/Termo';
import ServerError from '../../../../componentes/Erro/_500';
import Spinner from '../../../../componentes/Spinner';

import TermoInstituicao from './termos/Instituicao';

import { detectaDispositivo, getTermos } from '../../../../_recursos/js/util';

interface IParams {
  referencia: string,
}

function Inicial({ config }: IPageConfigParams) {
  const { schema, icone, etapaPath, termo, setTermo, init } = config;

  const dispatch = useDispatch();

  const historicoNavegacao = useHistory();
  let { referencia } = useParams<IParams>();

  const [httpStatus, setHttpStatus] = useState<number>(0);
  const [conteudoPolitica, setConteudoPolitica] = useState<string>('');

  /**
   * Busca no endpoint /termos a politica de privacidade
   * Popula estado "conteudoPolitica" com o texto retornado
   * Popula o estado "termo" com o idTermo e idTermoTipo
   */
  const getPoliticaDePrivacidade = useCallback(async () => {
    const termo = await getTermos(1, schema);

    setTermo && setTermo({
      ...termo,
      aceito: true,
      dispositivo: detectaDispositivo(),
    });

    setConteudoPolitica(termo.texto);
    setHttpStatus(200);
  }, [schema, setTermo]);

  /**
   * Altera o estado "inscritoVoluntariado" para false
   * Ao carregar componente chama a funcao para retornar o texto da Política de Privacidade
   */
  useEffect(() => {
    dispatch({ type: 'RESET_APP' });
    init && init();
    getPoliticaDePrivacidade();
  }, []);


  /**
   * Monitora o estado "conteudoPolitico", para só deixar a etapa valida quando houver algum conteudo no estado
   */
  useEffect(() => {
    if (!referencia) {
      dispatch({ type: 'ALTERA_REFERENCIA', referencia: schema });
    }

    if(conteudoPolitica === '') {
      dispatch({ type: 'VALIDA_ETAPA', etapaValida: false })
    } else {
      dispatch({ type: 'VALIDA_ETAPA', etapaValida: true })
    }
  }, [conteudoPolitica, dispatch, referencia, schema]);

  /**
   * Inicia o formulario colocando o termo aceito e indo para rota do formulario
   */
  const iniciaFormularioVoluntario = useCallback(() => {
    dispatch({ type: 'ALTERA_TERMOS', termos: [termo] })
    historicoNavegacao.push(`${etapaPath}`)
  }, [dispatch, etapaPath, historicoNavegacao, termo]);

  const getTermoTexto = useMemo(() => {
    return <TermoInstituicao instituicao={schema} />
  }, [schema]);

  return (
    <>
      {httpStatus === 0 && <Spinner />}

      {httpStatus === 200 && (
        <Termo
          textoTermo={getTermoTexto}
          botaoPrimario='Continuar'
          acaoBotaoPrimario={iniciaFormularioVoluntario}
          conteudoPoliticaDePrivacidade={conteudoPolitica}
          icone={icone}
        />
      )}

      {/* Falhou */}
      {httpStatus === 500 && <ServerError />}
    </>
  );
}

export default Inicial;