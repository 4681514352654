import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook personalizado para construir, a partir do useLocation, a query string.
 */
export default function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}