import styled from "styled-components";

interface Props {
  readonly open: boolean,
  readonly cor?: string,
  readonly botao?: boolean
}

export const Container = styled.div<Props>`
  position: absolute;
  display: ${(props) => props.open ? 'block': 'none'};
  bottom: ${(props) => props.open ? '32px': '-200px'};
  left: 32px;
  right: 32px;

  padding: 12px;
  margin: 0 auto;

  background-color: ${(props) => props.cor ?? '#dd453b'};
  color: #ffffff;

  max-width: 450px;

  border-radius: 4px;
  box-shadow: 1px 1px 10px rgb(0 0 0 / 15%);

  transition: 2s ease-in;

  p {
    ${(props) => props.botao && (`     
      border-bottom: 1px solid rgb(255 255 255 / 30%);
      padding-bottom: 12px;
    `)};
    margin: 0;
  };

  button {
    color: #fff;
    float: right;
    margin-top: 8px;
    padding: 0 2px;
  }
`;
