import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get } from "../../../_axios/requisicao";
import { IStoreRedux } from 'Types/Reducers';

interface IEscolaridade {
  nome: string
  id: number
}

interface IFormacao {
  nome: string
  id: number
}

interface IValidaEtapa {
  idFormacao: boolean,
  idEscolaridade: boolean,
}

export default function Profissao() {
  // Reducer data
  const escolaridade = useSelector((state: IStoreRedux) => state.dados.escolaridade);
  const idEscolaridade = useSelector((state: IStoreRedux) => state.dados.grauinstrucao);
  const formacao = useSelector((state: IStoreRedux) => state.dados.formacao);
  const idFormacao = useSelector((state: IStoreRedux) => state.dados.novaformacao);

  // Reducer action
  const dispatch = useDispatch();

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'escolaridade' }), [dispatch]);

  // States internos
  const [valido, setValido] = useState<any>({
    idEscolaridade: false,
    idFormacao: false,
  });
  const [escolaridades, setEscolaridades] = useState<IEscolaridade[]>([]);
  const [formacoes, setFormacoes] = useState<IFormacao[]>([]);
  const [exibeFormacoes, setExibeFormacoes] = useState<boolean>(false);
  const [idEscolaridadesSemFormacao] = useState<number[]>([ 1, 2, 3, 4, 5, 6, 7 ]);

  /**
   * Ao carregar componente obtem escolaridades
  */
  useEffect(() => {
    obtemEscolaridades();
  }, []);

  /**
   * Valida se a etapa é válida ou não
   */
  const validaEtapa = useCallback(() => {
    // Verifica se for apenas a escolaridade e o idEscolaridade está válido
    // ou caso a escolaridade exigir uma formação, verifica se o idEscolridade e o idFormacao estão válidos
    let atributosObrigatoriosValidos = (
      (
        !exibeFormacoes && valido.idEscolaridade === true
      ) || (
        (Object.values(valido).indexOf(false) === -1) &&
        (Object.values(valido).indexOf(null) === -1)
      )
    );
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: atributosObrigatoriosValidos });
  }, [dispatch, exibeFormacoes, valido]);

  /**
   * Valida se o campo está com um valor válido
   */
  const valida = useCallback((atributo: string, valor: string | string[] | number | number[] | null) => {
    if (!(atributo in valido)) return;

    const regex = /^\d{1,}$/;
    const resultado = valor === null ? true : regex.test(String(valor));
    valido[atributo] = (valor === null ? null : resultado);

    setValido(valido);
  }, [valido]);

  /**
   * Retorna lista de Formações
  */
  const obtemFormacoes = useCallback(async (valorDigitado: string = '') => {
    const retorno = await get('api-pessoas', `/formacoes?nome=${valorDigitado}`, {});
    if (retorno && retorno?.status === 200) {
      setFormacoes(retorno.data);
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
    }
  }, [dispatch]);

  /**
   * Retorna lista de escolaridades
  */
  const obtemEscolaridades = useCallback(async (valorDigitado: string = '') => {
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });

    const retorno = await get('api-pessoas', `/escolaridades?nome=${valorDigitado}`, {});
    if (retorno && retorno?.status === 200) {
      setEscolaridades(retorno.data);
    }
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
  }, [dispatch]);

  /**
   * Debounce para requisicao das formações
  */
  useEffect(() => {
    let debounce: any;

    debounce = setTimeout(() => {
      if (formacao === null) {
        return obtemFormacoes('');
      }
      obtemFormacoes(formacao);
    }, 450);

    return () => {
      clearTimeout(debounce);
    }
  }, [formacao, obtemFormacoes]);

  /**
   * Altera a escolaridade selecionada
  */
  const selecionaEscolaridade = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    const grauinstrucao = value.id;
    const escolaridade = value.nome;

    dispatch({ type: 'ALTERA_ESCOLARIDADE', grauinstrucao, escolaridade });
    valida('idEscolaridade', grauinstrucao);
  }, [dispatch, valida]);

  /**
   * Altera a formação selecionada
   */
  const selecionaFormacao = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    const novaformacao = value.id;
    const formacao = value.nome;

    dispatch({ type: 'ALTERA_FORMACAO', novaformacao: [novaformacao], formacao});
    valida('idFormacao', novaformacao);
  }, [dispatch, valida]);

  /**
   * Monitora o estado idEscolaridade e idFormacao, ao serem alterados verifica se ele é válido ou não
   */
  useEffect(() => {
    valida('idFormacao', idFormacao);
    valida('idEscolaridade', idEscolaridade);
    validaEtapa();
  }, [idEscolaridade, idFormacao, valida, validaEtapa]);

  /**
   * Monitora o estado idEscolaridade e verifica se a escolaridade exige ou não o preenchimento da formação
   */
  useEffect(() => {
    const toggleFormacao = !idEscolaridade ? false : !idEscolaridadesSemFormacao.includes(idEscolaridade);
    setExibeFormacoes(toggleFormacao);
  }, [idEscolaridade, idEscolaridadesSemFormacao])

  return (
    <div className="etapa-formulario">
      <Autocomplete
        freeSolo
        disableClearable
        autoHighlight
        getOptionLabel={option => option.nome}
        options={escolaridades}
        onChange={selecionaEscolaridade}
        onInputChange={(event, value) => {
          dispatch({ type: 'ALTERA_ESCOLARIDADE', grauescolaridade: null, escolaridade: value });
          dispatch({ type: 'ALTERA_FORMACAO', novaformacao: null, formacao: null });
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Qual seu nível de escolaridade?'
            error={valido !== null && !valido}
            autoComplete='off'
            inputProps={{ ...params.inputProps, value: (escolaridade || '') }}
          />
        }
      />
      {exibeFormacoes &&
        <Autocomplete
          freeSolo
          clearOnEscape
          disableClearable
          autoHighlight
          getOptionLabel={option => option.nome}
          options={formacoes}
          onChange={selecionaFormacao}
          onInputChange={(event, value) => {
            dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
            dispatch({ type: 'ALTERA_FORMACAO', novaformacao: null, formacao: value });
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              fullWidth
              label='Qual sua formação?'
              error={valido !== null && !valido}
              autoComplete='off'
              inputProps={{ ...params.inputProps, value: (formacao || '') }}
            />
          }
        />
      }
    </div>
  );
}
