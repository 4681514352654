import React, { useCallback, useState } from "react";

import MuiDialog from "../../../../componentes/MuiDialog";

interface VerTermoProps {
  termo: any;
  nome: string;
}

function VerTermo({ termo, nome }: VerTermoProps) {
  const [aberto, setAberto] = useState(false);

  const aoClicar = useCallback((e) => {
    e.preventDefault();
    setAberto(true);
  }, []);

  return (
    <>
      <b onClick={aoClicar}>{nome}</b>
      <MuiDialog
        abrir={aberto}
        setAbrir={setAberto}
        conteudo={termo?.texto}
        titulo={nome}
        idTermoTipo={termo?.idTermoTipo}
      />
    </>
  );
}

export default VerTermo;