import React, { useCallback, useContext, useState } from 'react';
import Spinner from '../../../../componentes/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreRedux } from 'Types/Reducers';
import { post } from '../../../../_axios/requisicao';
import RespostaInesperada from '../RespostaInesperada';
import InscricaoContext from '../../context';
import useTentarNovamente from '../../assets/useTentarNovamente';


function InscricaoPadrao() {
  const { inscricao } = useContext(InscricaoContext);

  // const carregando = useSelector((state: IStoreRedux) => state.app.carregando);
  const dadosInscricao = useSelector((state: IStoreRedux) => ({
    dados: state.dados,
    contatos: state.contatos,
    endereco: state.endereco,
    termos: state.termos,
  }));

  const dispatch = useDispatch();
  const [carregando, setCarregando] = useState(true);

  const retry = useTentarNovamente(
    useCallback(
      () => {
        // dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
        const referencia = inscricao.schema.substring(2);

        post('api-pessoas', '/inscricoes', dadosInscricao, { referencia })
          .then((res) => dispatch({ type: 'ALTERA_INSCRITO', inscrito: res.status === 200 }))
          .finally(() => {
            setCarregando(false);
            // dispatch({ type: 'ALTERA_CARREGANDO', carregando: false })
          });
        },
      [dadosInscricao, dispatch, inscricao.schema]
    )
  );

  return carregando ? <Spinner /> : <RespostaInesperada retry={retry} />;
}

export default InscricaoPadrao;
