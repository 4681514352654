import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';

import { primeiraMaiusculaTodasPalavras } from '../../../_recursos/js/util';

export default function Apelido() {
  const valor = useSelector(state => state.dados.apelido);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);
  let valorAlterado = null;

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'apelido' }), [dispatch]);

  function altera(event) {
    valorAlterado = primeiraMaiusculaTodasPalavras(event.target.value);
    dispatch({ type: `ALTERA_APELIDO`, apelido: valorAlterado });
    valida(valorAlterado);
  }

  function valida(valor) {
    const regex = /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]{2,}$/;
    const resultado = regex.test(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <TextField
        fullWidth
        className='input-secundario'
        label='Como gosta de ser chamado?'
        error={valido !== null && !valido}
        value={valor || ''}
        onInput={altera}
        autoComplete="nickname"
      />
    </div>
  );
}
