import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, CircularProgress, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { Container, Content, Toolbar } from '../../../../componentes/Interface';
import { IStoreRedux } from 'Types/Reducers';

export default function Etapas({ etapas, etapaAtual }: any) {
  const dispatch = useDispatch();
  const stateApp = useSelector((state: IStoreRedux) => state.app);

  const historicoNavegacao = useHistory();

  const [posicaoEtapaAtual, setPosicaoEtapaAtual] = useState(0);

  const navegaParaEtapaSeguinte = useCallback(async () => {
    if (posicaoEtapaAtual === etapas.length - 1) return;

    const posicaoEtapa = (posicaoEtapaAtual + 1);
    const urlDestino = etapas[posicaoEtapa].path;
    historicoNavegacao.push(urlDestino);
    setPosicaoEtapaAtual(posicaoEtapa);
  }, [etapas, historicoNavegacao, posicaoEtapaAtual]);

  const navegaParaEtapaAnterior = useCallback(() => {
    if (posicaoEtapaAtual <= 0) return;
    const posicaoEtapa = (posicaoEtapaAtual - 1);
    setPosicaoEtapaAtual(posicaoEtapa);
    historicoNavegacao.goBack();
  }, [historicoNavegacao, posicaoEtapaAtual]);

  useEffect(() => {
    setPosicaoEtapaAtual(etapaAtual.posicao);
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, []);


  return (
    <Container>
      {/* Toolbar */}
      <Content>
        <Toolbar>
          {posicaoEtapaAtual > 0 &&
            <IconButton onClick={navegaParaEtapaAnterior}>
              <ArrowBack />
            </IconButton>
          }
          {stateApp.carregando && <CircularProgress color='primary' size={24}/>}
        </Toolbar>
      </Content>
      <Content full padding pb="3rem">
        {/* Campo do Input */}
        {etapaAtual.component}
        <br/><br/>

        <Button
          disabled={stateApp.etapaValida !== true}
          size='large'
          variant='contained'
          color='primary'
          onClick={() => navegaParaEtapaSeguinte()}
        >
          Continuar
        </Button>
      </Content>
    </Container>
  )
}