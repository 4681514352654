import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core';

import { Content, Subtitle, Title } from '../../../../componentes/Interface';
import Toast from '../../../../componentes/Toast';

import { ContainerStyled, IconeVoluntario, IconeLibcom, BoletoContainer, BoletoUrl } from './styles';
import { IStoreRedux } from 'Types/Reducers';


export default function Final({ config }: IPageConfigParams) {
  const dispatch = useDispatch();
  let { icone, info } = config;
  const boleto = useSelector((state: IStoreRedux) => state.eventoEscola.boleto);

  const [alertaCopiado, setAlertaCopiado] = useState(false);

  const mensagem = useMemo(() => {
    switch(info.eventoEscola.status)
    {
      case 'inscrito':
      case 'isento':
        return (
          <>
            Você já está {info.dados.sexo === '1' ? 'inscrito' : 'inscrita'} na <strong>Escola de Formação de Líderes</strong>.
            <br /><br />
            Em breve você receberá um guia de informações para facilitar sua participação.
          </>
        )

      case 'pagamento-cancelado':
        return (
          <>
            <strong>Seu pagamento foi cancelado pela instituição financeira.</strong>
            <br />
            Caso queira tentar um novo pagamento entre em contato com quem lhe convidou para obter mais informações e solicitar um novo convite.
          </>
        );

      case 'pagamento-devolvido':
        return (
          <>
            <strong>A instituição financeira alterou o status do seu pagamento para devolvido. </strong>
            <br /><br />
            Caso tenha alguma dúvida ou queira mais informações entre em contato com o Libcom pelo telefone: <strong><a href="tel:+551150813732" style={{ color: 'inherit' }}>(11) 5081-3732</a></strong>
          </>
        );

      case 'cancelado':
        return (
          <>
            <strong>Este convite expirou. </strong>
            <br />
            Entre em contato com a pessoa que lhe convidou para obter mais informações e solicitar um novo convite.
          </>
        );

      case 'pagamento-andamento':
      default:
        return (
          <>
            Assim que o pagamento for confirmado, você estará {info.dados.sexo === '1' ? 'inscrito' : 'inscrita'} na &nbsp;
            <strong>Escola de Formação de Líderes</strong>
            <br /><br />

            {boleto && boleto.boletoURL && (
              <BoletoContainer>
                <BoletoUrl href={boleto.boletoURL} target="_blank">
                    Clique aqui para acessar o seu boleto!
                </BoletoUrl>
                <span>ou</span>
                  <CopyToClipboard
                    text={boleto.boletoBarcode}
                    onCopy={() => setAlertaCopiado(true)}
                  >
                    <Button
                      variant="contained"
                      color="primary"                    >
                    copie o código de barras
                    </Button>
                  </CopyToClipboard>
              </BoletoContainer>
            )}

            Em breve você receberá um guia de informações para facilitar sua participação.
          </>
        );
    }

  }, [boleto, info.dados.sexo, info.eventoEscola.status]);

  const limpaInscricao = useCallback(async () => {
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: true });
  },[dispatch]);

  useEffect(() => {
    limpaInscricao();
  }, [limpaInscricao]);

  return (
    <ContainerStyled>
      <Content full verticalCenter horizontalCenter padding>
        {typeof icone === 'string'? (
          <IconeLibcom src={icone} alt="Icone" />
        ) : (
          <IconeVoluntario />
        )}
        <Title align="center">
          Parabéns {info?.dados?.nome?.split(' ')[0]}!
        </Title>
        <br />
        <Subtitle fw="300" align="center" style={{ fontSize: '1em'}}>
          {mensagem}
        </Subtitle>
      </Content>
      <Toast
        exibe={alertaCopiado}
        setExibe={setAlertaCopiado}
        mensagem='Copiado!'
        cor='#4caf50'
      />
    </ContainerStyled>
  );
}
