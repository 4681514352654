import moment from 'moment';
import { obtemUrlApi } from '../../_axios/url';
import { IStoreRedux } from 'Types/Reducers';

/**
 * Função para inicializar o web checkout da Pagarme
 * @param callback
 */
 export default function initWebcheckout(callback: Function) {
  const script = document.createElement('script');
  // script.async = true;
  script.crossOrigin = 'anonymous';
  script.src = 'https://assets.pagar.me/checkout/1.1.0/checkout.js';

  if (callback) {
    script.addEventListener('load', () => {
      callback();
    });
  }

  document.head.appendChild(script);
}

interface configCheckoutProps extends Pick<IStoreRedux, 'dados' | 'endereco' | 'eventoEscola' | 'contatos'> {
  dados: { [K in keyof IStoreRedux['dados']]: Exclude<IStoreRedux['dados'][K], null> };
}

export function configCheckoutPagarMe(values: configCheckoutProps, idPessoa: string | undefined, corPrimaria: string, pedidoOdoo: any) {

  const { dados, contatos, endereco, eventoEscola } = values;
  const valor = Number(eventoEscola.valor_inscricao.replace(/\./g, '').replace(/,/g, '.'))

  const configCheckoutPagarme: IConfigCheckoutPagarme = {
    uiColor: `${corPrimaria}`,
    paymentButtonText: 'Efetuar Pagamento',
    amount: formataValorDaContribuicao(valor),
    createToken: true,
    customerData: false,
    customer: {
      external_id: idPessoa,
      name: dados.nome,
      type: 'individual',
      country: 'br',
      email: contatos.email?.endereco,
      documents: [
        {
          type: 'cpf',
          number: dados.cpf,
        },
      ],
      phone_numbers: [formataCelularParaPagarme(contatos.celular.numero)],
      birthday: dados.dataNascimento?.split('T')[0], // Para tratar datas no padrão UTC YYYY-MM-DDThh:MM:mmm
    },
    billing: {
      name: dados.nome,
      address: {
        country: 'br',
        state: endereco?.estado,
        city: endereco?.cidade,
        neighborhood: endereco?.bairro,
        street: endereco?.logradouro,
        street_number: endereco && (endereco?.numero && endereco?.numero.toString()),
        zipcode: endereco?.cep?.replace('-', ''),
      },
    },
    maxInstallments: eventoEscola.qtd_parcelas,
    freeInstallments: eventoEscola.qtd_parcelas,
    items: [
      {
        id: `${pedidoOdoo.id}`,
        title: `[${pedidoOdoo.codigo}] ${pedidoOdoo.nome}`,
        unit_price: formataValorDaContribuicao(valor),
        quantity: 1,
        tangible: false,
      },
    ],
    boletoExpirationDate: getDataAtualMaisTresDias(),
    metadata: {
      pedido_odoo: pedidoOdoo.id,
    },
  };

  if (pedidoOdoo.id) {
    configCheckoutPagarme.postbackUrl = `${obtemUrlApi('api-pessoas')}/odoo/postback-pagarme/${pedidoOdoo.id}`;
  }

  if (endereco && endereco.complemento !== '' && endereco.complemento !== null) {
    configCheckoutPagarme.billing.address.complementary = endereco.complemento;
  }

  return configCheckoutPagarme;
}

const formataValorDaContribuicao = (valor: number) => valor * 100;

const formataCelularParaPagarme = (numero: string) => {
  let pagarMeCellphone = numero;

  while (pagarMeCellphone.includes(' ')) {
    pagarMeCellphone = pagarMeCellphone.replace(' ', '');
  }

  pagarMeCellphone = pagarMeCellphone.replace('(', '').replace(')', '');

  pagarMeCellphone = pagarMeCellphone.replace('-', '');

  return `+55${pagarMeCellphone}`;
}

const getDataAtualMaisTresDias = () => {
  return moment().add(3, 'days').format("YYYY-MM-DD");
}