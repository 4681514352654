import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch, useParams } from 'react-router';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { getDominio, getSchema } from '../../_recursos/js/util';

import LibcomIcon from '../../_recursos/imagens/libcom-icon.svg';

import { Etapas, Final, Inicial } from './paginas';
import { useSelector, useDispatch } from 'react-redux';
import { Cpf, DataNascimento, Escolaridade, EstadoCivil, Filhos, Nome, Profissao, Sexo } from '../../paginas/Formulario/Dados';
import { Celular, Email } from '../../paginas/Formulario/Contatos';
import { Endereco } from '../../paginas/Formulario/Endereco';
import { TermoEscola } from '../../paginas/Formulario/Termos';
import { IStoreRedux } from 'Types/Reducers';

interface IParams {
  hash: string,
  referencia: string,
}

export default function Evento() {
  const match = useRouteMatch();
  const { hash, referencia } = useParams<IParams>();
  const historicoNavegacao = useHistory();

  const dispatch = useDispatch();

  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const eventoEscola = useSelector((state: IStoreRedux) => state.eventoEscola);

  document.title = 'Inscrição EFL'

  const [icone, setIcone] = useState<string | null>(null);
  const [schema, setSchema] = useState<string>('');

  const etapas = useMemo(() => (
    [
      { path: '/inscricao/formulario/dados/cpf', component: <Cpf/>},
      { path: '/inscricao/formulario/dados/nome', component: <Nome/>},
      { path: '/inscricao/formulario/contatos/email', component: <Email obrigatorio />},
      { path: '/inscricao/formulario/contatos/celular', component: <Celular/>},
      { path: '/inscricao/formulario/dados/data-nascimento', component: <DataNascimento/>},
      { path: '/inscricao/formulario/dados/sexo', component: <Sexo/>},
      { path: '/inscricao/formulario/dados/estado-civil', component: <EstadoCivil/>},
      { path: '/inscricao/formulario/dados/filhos', component: <Filhos />},
      { path: '/inscricao/formulario/dados/escolaridade', component: <Escolaridade/>},
      { path: '/inscricao/formulario/dados/profissao', component: <Profissao />},
      { path: '/inscricao/formulario/endereco', component: <Endereco/>},
      { path: '/inscricao/formulario/termo-adesao', component: <TermoEscola schema={schema}/>},
      { path: '/inscricao/confirmacao', component: <Redirect to='/inscricao/confirmacao' push/>},
    ]
  ), []);

  const init = useCallback(() => {
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: true });

    const dominio = getDominio();

    switch (dominio) {
      case 'libcom':
        setSchema(getSchema('libcom'));
        dispatch({ type: 'ALTERA_HASH', hash: hash });
        dispatch({ type: 'ALTERA_REFERENCIA', referencia: 'libcom' });
        setIcone(LibcomIcon);
        break;

      default:
        const codigoReferencia = stateApp.hash
          ? stateApp.hash.substring(0, 6)
          : stateApp.referencia;

        setSchema(getSchema(codigoReferencia?.substr(0, 6)))
        dispatch({ type: 'ALTERA_HASH', hash: hash });
        dispatch({ type: 'ALTERA_REFERENCIA', referencia: schema });
        break;
    }
  }, []);

  const capturaUri = useMemo(() => historicoNavegacao.location.pathname, [historicoNavegacao.location.pathname])

  const capturaEtapaAtual = useCallback((): IState => {
    let etapaSelecionada = etapas.filter((etapa: any, index) => {
      if (etapa.path === capturaUri) {
        etapa.posicao = index;
        return etapa;
      }
    })[0];
    if (etapaSelecionada && Object.keys(etapaSelecionada).length)
      return etapaSelecionada;
    else
      return { posicao: 0, componente: <Redirect to={etapas[0].path} push/> }
  }, [capturaUri, etapas]);

  const resetApp = useCallback(() => {
    dispatch({ type: 'ALTERA_HASH', hash: null });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_EVENTO_ESCOLA' });
    dispatch({ type: 'RESET_TERMOS' });
    dispatch({ type: 'RESET_EVENTO' });
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: false });
  }, [dispatch]);

  const appConfig = {
    icone,
    schema,
    referencia,
    etapaPath: etapas[0].path,
    info: { dados, eventoEscola },
    init,
    resetApp,
  }

  return (
    <Switch>
      <Route
        path={`/inscricao/confirmacao`}
        render={() => <Final config={appConfig} />}
        exact
      />

      <Route
        path={`${match.path}`}
        render={() => <Inicial config={appConfig} />}
        exact
      />

      {/* Etapas */}
      {capturaEtapaAtual().path && !capturaEtapaAtual().path.includes('confirmacao') && (
        <Route path={capturaEtapaAtual().path} exact>
          <Etapas etapas={etapas} etapaAtual={capturaEtapaAtual()} config={appConfig} />
        </Route>
      )}
    </Switch>
  )
}