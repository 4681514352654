import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { TextSmall } from '../../../componentes/Interface';

// função para validar dominio de email
// import { validaDominioPorDnsGoogle } from '../../../_recursos/js/util';
// import useDebounce from '../../../_hooks/useDebounce';

interface IEmailProps {
  obrigatorio?: boolean
}

// regex padrão RFC5322
const regex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([\]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/;

export default function Email({ obrigatorio }: IEmailProps) {
  const [checkboxNaoPossuo, setCheckboxNaoPossuo] = useState(false);
  const endereco = useSelector((state: any) => state.contatos.email?.endereco || '');
  const naoPossuoEmail = useSelector((state: any) => state.app.naoPossuoEmail);
  const dispatch = useDispatch();
  const [valido, setValido] = useState<boolean>(false);
  const refValor = useRef('');

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'email' }), [dispatch]);

  const validaEtapa = useCallback(function (trueOrFalse: boolean) {
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: trueOrFalse });
  }, [dispatch]);


  /**
   * Algoritmo de Validação por DNS.
   * Possivelmente utilizar uma API como proxy para validar ao invés
   * de diretamente chamar na aplicação resolva o problema e serviria de melhoria
   * para validação de emails, impossibilitando emails sem registros MX
   */
  // const validaDominio = useCallback(async (dominio: string) => {
  //   const valido = await validaDominioPorDnsGoogle(dominio);
  //   setValido(valido);
  // }, []);

  // const debouncedValidaDominio = useDebounce(validaDominio);

  const valida = useCallback((valor: string) => {
    setValido(regex.test(valor));
  }, []);

  const alteraCheckboxNaoPossuo = useCallback((_: any, checked: boolean) => {
    setCheckboxNaoPossuo(checked)
    dispatch({ type: `REMOVE_EMAIL` });
    dispatch({ type: `ALTERA_NAO_POSSUO_EMAIL`, naoPossuoEmail: checked });
  }, [dispatch]);

  function altera(event: ChangeEvent<HTMLInputElement>) {
    refValor.current = event.target.value.toLowerCase();
    dispatch({ type: `ALTERA_EMAIL`, tipo: 1, endereco: refValor.current });
  }

  useEffect(() => {
    if (naoPossuoEmail)
      alteraCheckboxNaoPossuo(undefined, true)
  }, [alteraCheckboxNaoPossuo, naoPossuoEmail]);

  useEffect(() => valida(endereco), [checkboxNaoPossuo, endereco, valida]);

  const validacaoEtapa = useMemo(() => (
    obrigatorio
      ? valido
      : (!checkboxNaoPossuo ? valido : true)
  ), [checkboxNaoPossuo, obrigatorio, valido]);

  useEffect(() => validaEtapa(validacaoEtapa), [validaEtapa, validacaoEtapa]);

  return (
    <div className="etapa-formulario">
      <TextField
        fullWidth
        label='E-mail'
        type='email'
        disabled={!obrigatorio && checkboxNaoPossuo}
        error={!validacaoEtapa}
        value={endereco}
        onChange={altera}
        autoComplete="email"
      />

      {!obrigatorio && (
        <FormControlLabel
          label={<TextSmall>Não possuo e-mail</TextSmall>}
          control={
            <Checkbox
              checked={checkboxNaoPossuo}
              color='primary'
              onChange={alteraCheckboxNaoPossuo}
            />
          }
        />
      )}
    </div>
  );
}
