import { createMuiTheme } from "@material-ui/core/styles";

const temaPadrao = createMuiTheme({
  palette: {
    primary: {
      main: '#034b7b',
      light: 'rgba(0, 255, 255)',
    },
  },
  typography: {
    fontSize: 20,
    htmlFontSize: 20
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 24,
      },
    },
  },
});

export default temaPadrao;
