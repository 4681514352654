import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, CircularProgress } from '@material-ui/core';
import { Check, Event } from '@material-ui/icons';

import { post } from '../../../../_axios/requisicao';

import { Container, Content, Text, Title, Subtitle } from '../../../../componentes/Interface';

import { ContainerStyled, IconeVoluntario } from '../../../Cadastro/paginas/Final/Confirmacao/styles';

import { ObterLinkEvento } from '../../../Cadastro/paginas/Final/Confirmacao/styles';

import { BotaoEventoAgenda, IconeCopiar, ContainerIcone } from './styles';
import { IStoreRedux } from 'Types/Reducers';
import { getDominio } from '../../../../_recursos/js/util';

function DiaEvento() {
  return Date.UTC(2022, 9, 8, 14);
}

function IrParaEvento(schema: string, cpf?: string) {
  const domain: any = {
    z00001ra: 'https://evento.reinaldoalguz.com.br',
    z03843tf: 'https://evento.taniafruchi.com.br',
    z01453em: 'https://evento.enricomisasi.com.br',
    z00254js: 'https://evento.rogeriossantos.com.br',
  }
  let base = domain[schema] || 'https://evento.infogab.com.br';
  if (document.domain.match(/develop|localhost/)) {
    base = base.replace('evento', 'evento-develop');
  }

  if (cpf) {
    return base + `?cpf=${cpf}`;
  }

  return base;
}

function AdicionarGoogleAgenda(schema: string, hash: string) {
  const base = 'https://calendar.google.com/calendar/u/0/r/eventedit?';
  const evento = IrParaEvento(schema) + '/' + hash;
  const data = '20221008T150000/20221008T163000';
  const query = new URLSearchParams({
    text: 'REUNIÃO PROJETO',
    dates: data,
    location: evento,
    details: `Para entrar no evento, acesse ${evento}`,
  });

  window.open(base + query.toString());
}

export default function Final({ config }: IPageConfigParams) {
  let { schema } = config;
  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);

  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [httpStatus, setHttpStatus] = useState(0);
  const [copiado, setCopiado] = useState(false);
  const [cpf] = useState(dados.cpf);

  const enviaInscricao = useCallback(async () => {
    if (stateApp.inscrito) {
      setHttpStatus(200);
      return;
    }

    try {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
      const inscricao = {
        dados,
        contatos,
        endereco: getDominio() !== 'infogab' ? endereco : undefined,
        termos
      };

      const hashOuRef = stateApp.hash
        ? { hash: stateApp.hash }
        : { referencia: stateApp.referencia, usuario: stateApp.idUsuario }

      const header = {
        ...hashOuRef,
        schema,
      }

      if (!stateApp.inscrito) {
        const retorno = await post('api-pessoas',  '/inscricoes',  inscricao, header);
        if (retorno) {
          setHttpStatus(retorno.status);
          dispatch({ type: 'ALTERA_IDPESSOA', idPessoa: retorno.data.dados.idpessoa });

          const evento = retorno.data.evento;

          if(evento) dispatch({ type: 'ALTERA_EVENTO', ...evento });
          if (!stateApp.idUsuario)
            dispatch({
              type: 'ALTERA_IDUSUARIO',
              idUsuario: retorno.data.dados.usualtera
                ? retorno.data.dados.usualtera
                : retorno.data.dados.usucadastro
            });
        }
      } else {
        setHttpStatus(200);
      }
    }
    catch (error) {
      setHttpStatus(500);
    }
    finally {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
    }
  },[stateApp.inscrito, stateApp.hash, stateApp.referencia, stateApp.idUsuario, dispatch, dados, contatos, endereco, termos, schema])

  const limpaInscricao = useCallback(async () => {
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_TERMOS'})
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: true });
  },[dispatch]);

  useEffect(() => {
    enviaInscricao();
  }, [enviaInscricao, schema, stateApp.hash, stateApp.referencia]);

  useEffect(()=>{
    if (httpStatus === 200)
      limpaInscricao();
  }, [httpStatus, limpaInscricao]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <CircularProgress color='primary' size={42}/>
            <br/><Title variant='h6'>Aguarde um momento...</Title>
          </Content>
        </Container>
      }

      {/* Concluido */}
      {
        (pathname.match('/s/') && httpStatus === 200) && (
          <ContainerStyled>
            <Content full verticalCenter horizontalCenter padding>
              <IconeVoluntario />
              <Title>
                Muito obrigado,
              </Title>
              <Subtitle fw="300">
                seu cadastro foi realizado com sucesso.
              </Subtitle>
            </Content>
          </ContainerStyled>
        )
      }
      {
        (pathname.match('/s2/') && httpStatus === 200) && (
          <ContainerStyled>
            <Content full verticalCenter horizontalCenter>
              <IconeVoluntario />
              <Title>
                Pronto!
              </Title>
              <Subtitle align="center" fw="400">
                Sua participação no evento já está confirmada.
                <br />
                Ficamos muito felizes e estamos ansiosos para estarmos juntos.
                Nos encontramos lá!
              </Subtitle>
              {
                Date.now() < DiaEvento()
                  ? (
                    <Subtitle
                      align="center"
                      fw="300"
                      style={{ marginTop: '1rem' }}
                    >
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: 'rgba(255, 255, 255, 0.6)',
                        }}
                      >
                        O evento será sábado, dia 8, às 15:00.
                      </span>
                      <br />
                      <CopyToClipboard
                        text={IrParaEvento(schema) + '/' + stateApp.hash}
                        onCopy={() => setCopiado(true)}
                      >
                        <ObterLinkEvento style={{ marginTop: 25 }}>
                          <ContainerIcone>
                            {
                              !copiado
                                ? <IconeCopiar />
                                : <Check />
                            }
                          </ContainerIcone>
                          {
                            !copiado
                              ? 'Copie o link do evento'
                              : 'Copiado'
                          }
                        </ObterLinkEvento>
                      </CopyToClipboard>
                      <br />
                      <BotaoEventoAgenda onClick={() => AdicionarGoogleAgenda(schema, stateApp.hash || '')}>
                        <Event />
                        Adicionar ao Google Agenda
                      </BotaoEventoAgenda>
                    </Subtitle>
                  )
                  : (
                    <ObterLinkEvento
                      style={{ marginTop: 25 }}
                      onClick={() => window.open(IrParaEvento(schema, (cpf || undefined)), '_self')}
                    >
                      Ir para evento
                    </ObterLinkEvento>
                  )
              }
            </Content>
          </ContainerStyled>
        )
      }

      {/* Falhou */}
      {httpStatus === 500 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <Title gutterBottom>Ops...</Title>
            <Text gutterBottom align='center'>
              Parece que o servidor respondeu de forma inesperada.
            </Text>
            <br/>

            <Button
              variant='outlined'
              color='inherit'
              onClick={() => window.location.reload()}>
                TENTAR NOVAMENTE
            </Button>
          </Content>
        </Container>
      }
    </>
  );
}
