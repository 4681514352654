import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputMask from 'react-input-mask';

import { getAnoAtual } from '../../../../_recursos/js/util';
import { CampoDeTextoOutlined } from '../styles';
import { IStoreRedux } from 'Types/Reducers';

function validaCoerenciaData(valor: string) {
  const anoAtual = getAnoAtual();
  const anoInformado = Number(valor.split('/')[2]);
  return (anoInformado < anoAtual && anoInformado > (anoAtual - 130));
}

const regex = /(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))/;

function CampoDataNascimento() {
  const dataNascimento = useSelector<IStoreRedux, string>((state) => state.dados.dataNascimento || '');
  const dispatch = useDispatch();

  const [valido, setValido] = useState(false);

  const valor = useMemo(() => dataNascimento.split('-').reverse().join('/'), [dataNascimento])

  const aoAlterar = useCallback((e) => {
    dispatch({ type: `ALTERA_DATA_NASCIMENTO`, dataNascimento: e.target.value.split('/').reverse().join('-') });
  }, [dispatch])

  useEffect(() => {
    const resultado = regex.test(valor) && validaCoerenciaData(valor);
    setValido(resultado)
    dispatch({ type: 'VALIDA_CAMPO', dataNascimento: resultado });
    if (resultado)
      dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: valor.split('/').reverse().join('-') });
  }, [valor, dispatch])

  useEffect(() => {
    if (dataNascimento === '')
      dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: null })
  }, [dataNascimento, dispatch])

  return (
    <div className="campo-formulario">
      <InputMask
        mask="99/99/9999"
        maskChar=" "
        type="tel"
        value={valor}
        onChange={aoAlterar}
      >
        {
          (inputProps: any) =>
            <CampoDeTextoOutlined
              {...inputProps}
              autoComplete="bday"
              fullWidth
              label="Data de nascimento"
              error={dataNascimento !== '' && !valido}
            />
        }
      </InputMask>
    </div>
  );
}

export default CampoDataNascimento