import styled from 'styled-components';
import withTheme from '@material-ui/styles/withTheme';
import TextField from '@material-ui/core/TextField';

export const CamposEmLinha = styled.div.attrs((props) => ({ className: `${props.className || ''} campo-formulario campos-em-linha` }))`
  display: flex;
  flex-flow: row nowrap;
`;
export const ConteudoDialog = styled.div`
  && {
    padding: 2rem;

    h1 {
      margin-top: 0;
      font-size: 1.5rem;
      font-weight: normal;
    }

    .MuiAlert-root,
    .MuiInputBase-root {
      margin-bottom: 1.25rem;
    }

    .actions {
      display: flex;

      button {
        flex: 1;
      }
    }

    @media screen and (max-width: 768px) {
      .actions {
        flex-direction: column;
        gap: 1.5rem;
      }
    }
  }
`;

export const CampoDeTextoOutlined = withTheme(
  styled(TextField).attrs({ variant: 'outlined' })`
    & {
      &.MuiFormControl-root {
        margin: 0;
      }

      .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled) {
        color: ${props => props.theme.palette.primary.main};
        border-radius: 6px;

        :hover .MuiOutlinedInput-notchedOutline {
          border-color: currentColor;
        }
      }

      .MuiOutlinedInput-input {
        padding: 12px;
        color: initial;

        &.Mui-disabled {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      .MuiInputLabel-outlined {
        transform: translate(12px, 14px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(12px, -6px) scale(0.75);
        }
      }
    }
  `
);
