const INITIAL_STATE = {
  data: null,
  horario: null,
  duracao: null,
  zoom: null
};

function evento(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ALTERA_EVENTO':
      return {
        ...state,
        data: action.data,
        horario: action.horario,
        duracao: action.duracao,
        zoom: action.zoom
      };

    case 'RESET_EVENTO':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default evento;