import React, { useContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import InscricaoContext from '../../context';

import { Content, Subtitle, Title } from '../../../../componentes/Interface';
import { ContainerStyled, IconeInstituicao, IconeVoluntario } from './styles';
import { ehSistemaInstituto, getDominio } from '../../../../_recursos/js/util';

function Final({ icone }: any) {
  const { inscricao } = useContext(InscricaoContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_TERMOS' });
    dispatch({ type: 'RESET_EVENTO' });
  }, [dispatch]);

  return (
    <ContainerStyled>
      <Content full verticalCenter horizontalCenter padding>
        {
          ehSistemaInstituto(getDominio())
            ? <IconeInstituicao src={icone} alt={`Icone ${inscricao.schema}`} className={inscricao.schema} />
            : <IconeVoluntario />
        }
        <Title gutterBottom>Pronto!</Title>
        <Subtitle gutterBottom align='center'>
          Dados enviados com sucesso. Obrigado.
        </Subtitle>
      </Content>
    </ContainerStyled>
  )
}

export default Final;
