import React, { memo } from 'react';
import { Container, Content, Title, Text } from '../../Interface';

function NotFound() {
  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom align='center'>404</Title>
        <Text gutterBottom align='center'>
          Ops... página não encontrada!
        </Text>
      </Content>
    </Container>
  )
}

export default memo(NotFound)