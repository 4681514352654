import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Termo from '../../../../componentes/Termo';

import { EFL, EFLRodape } from './termos/EFL';

export default function Inicial({ config }: IPageConfigParams) {
  const { icone, etapaPath, init, resetApp } = config;

  const historicoNavegacao = useHistory();

  const navegaParaFormulario = useCallback(() => {
    historicoNavegacao.push(`${etapaPath}`)
  }, [etapaPath, historicoNavegacao]);

  useEffect(() => {
    resetApp && resetApp();
    init && init()
  }, []);

  return (
    <Termo
      textoTermo={<EFL />}
      botaoPrimario={"Confirme sua Inscrição"}
      acaoBotaoPrimario={navegaParaFormulario}
      icone={icone}
      rodape={<EFLRodape />}
    />
  );
}