import React, { useEffect } from 'react';

import { Container } from './styles';
import { Button } from '@material-ui/core';

interface IToastParams {
  exibe: boolean,
  setExibe: (value: boolean) => void,
  mensagem: string,
  botao?: boolean,
  cor?: string,
  duration?: number,
}

export default function Toast({ exibe, setExibe, mensagem, botao, cor, duration }: IToastParams) {

  useEffect(() => {
    setExibe(exibe);
    
    return () => {
      setTimeout(() => setExibe(false), duration || 3500);
    }
  }, [duration, exibe, setExibe]);

  return (
    <Container open={exibe} cor={cor} botao={botao}>
      <p>{mensagem}</p>
      {botao && <Button onClick={() => setExibe(false)}>Entendi</Button>}
    </Container>
  )
}
