import axios from 'axios';

import { ApiName, obtemUrlApi } from "./url";

type Methods = 'get' | 'post' | 'put' | 'delete'

const cabecalhoComum = {
  'Content-Type': 'application/json',
};

const ServicoAxios = axios.create();

async function requisicao(metodo: Methods, api: ApiName, url: string, corpo: IState | null, cabecalho: IState) {
  // Se metodo for de escrita, adiciona id do app no cabecalho
  if (metodo.toLowerCase() !== 'get')
    cabecalho.app = '3';

  const urlCompleta = obtemUrlApi(api) + url;
  const headers = cabecalho ? { ...cabecalhoComum, ...cabecalho } : cabecalhoComum;

  const config: any = {
    method: metodo,
    url: urlCompleta,
    data: corpo ? JSON.stringify(corpo) : null,
    headers,
  };

  if (api !== 'api-eventos')
    config.withCredentials = true;

  try {
    return await ServicoAxios(config);
  }
  catch(err) {
    if (err.response && err.response.status >= 400 && err.response.status <= 500)
      console.error(err.response);

      // TODO: proposta Pedro
      // throw Error(err.response.data);

      throw {
        ...err.response.data,
        status: err.response && err.response.status
      }
  }
}

export const get = (api: ApiName, url: string, cabecalho: IState = {}) => requisicao('get', api, url, null, cabecalho);
export const post = (api: ApiName, url: string, corpo: IState, cabecalho: IState) => requisicao('post', api, url, corpo, cabecalho);
export const put = (api: ApiName, url: string, corpo: IState, cabecalho: IState) => requisicao('put', api, url, corpo, cabecalho);