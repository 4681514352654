import React, { useCallback, useEffect, useState, ChangeEvent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';

import { get, post } from '../../../../../../_axios/requisicao';

import Checkbox from '../../../../../../componentes/Checkbox';
import { Container, Content, Text, Title, Subtitle } from '../../../../../../componentes/Interface';
import { IStoreRedux } from 'Types/Reducers';

interface IPerfilProps {
  apelido: string,
  codigo: string,
  descricao: string,
  idPerfil: number,
}


export default function QueroSerVoluntario() {
  const historicoNavegacao = useHistory();

  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dispatch = useDispatch();

  const codigoReferencia = stateApp.hash
    ? stateApp.hash.substring(0, 6)
    : stateApp.referencia

  const perfisPermitidos: number[] = [15, 16, 18, 19, 20];
  const [carregandoPerfis, setCarregandoPerfis] = useState(false);
  const [perfis, setPerfis] = useState<IPerfilProps[]>([]);
  const [perfisSelecionados, setPerfisSelecionados] = useState<number[]>([]);
  const [urlVoluntario, setUrlVoluntario] = useState('');
  const [httpStatus, setHttpStatus] = useState(0);

  useEffect(() => {
    if (stateApp.inscritoVoluntariado)
      historicoNavegacao.push('cadastro/voluntario');
    else
      init();
  }, []);

  const init = useCallback(async () => {
    await obtemUrlVoluntario();
    await obtemPerfisVoluntario();

    // TODO: criar state para carregando ao invés dessa gambis.
    setHttpStatus(200);
  }, []);

  const obtemPerfisVoluntario = useCallback(async () => {
    try {
      setCarregandoPerfis(true);
      const uriPerfis = '/perfis-voluntario';
      const retorno = await get('api-pessoas', uriPerfis,
        { referencia: codigoReferencia }
      );
      retorno && setPerfis(retorno.data);
    } catch (error) {
      setHttpStatus(500);
    } finally {
      setCarregandoPerfis(false);
    }
  }, [codigoReferencia]);

  const obtemUrlVoluntario = useCallback(async () => {
    try {
      const retorno = await get(
        'api-pessoas',
        `/pessoas/${stateApp.idPessoa}/tornar-voluntario/${codigoReferencia}`,
        { referencia: codigoReferencia }
      );
      retorno && setUrlVoluntario(retorno.data.url);
    } catch (error) {
      setHttpStatus(500);
    }
  }, [codigoReferencia, stateApp.idPessoa]);

  const salvaVoluntario = useCallback(async () => {
    try {
      setHttpStatus(0);
      const body = {
        idPerfil: perfisSelecionados,
        usuarioCadastro: stateApp.idUsuario
      };
      const retorno = await post('api-pessoas', urlVoluntario, body,
        { referencia: codigoReferencia }
      );
      retorno && setHttpStatus(retorno.status);
      if (retorno && retorno.status === 200) {
        dispatch({ type: `ALTERA_INSCRITO_VOLUNTARIADO`, inscritoVoluntariado: true });
        historicoNavegacao.push('/cadastro/voluntario');
      }
    }
    catch (error) {
      setHttpStatus(500);
    }
  }, [codigoReferencia, dispatch, historicoNavegacao, perfisSelecionados, stateApp.idUsuario, urlVoluntario]);

  const alteraPerfisSelecionados = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = +event.target.value;
    let perfisSelecionadosNovo = perfisSelecionados.slice();

    if (checked && !perfisSelecionadosNovo.includes(value)) {
      perfisSelecionadosNovo.push(value);
    }
    else if (!checked && perfisSelecionadosNovo.includes(value)) {
      perfisSelecionadosNovo = perfisSelecionadosNovo.filter(idPerfil => idPerfil !== value);
    }
    setPerfisSelecionados(perfisSelecionadosNovo);
  }, []);

  const obterOpcoesCheckbox = useMemo(() => (
    perfis
      .filter(perfil => perfisPermitidos.includes(perfil.idPerfil))
      .map(perfil => (
        <Checkbox
          gutterBottom
          key={perfil.idPerfil}
          rotulo={<Text>{perfil.descricao}</Text>}
          value={perfil.idPerfil}
          onChange={alteraPerfisSelecionados}
        />
      )
    )
  ), [alteraPerfisSelecionados, perfis, perfisPermitidos]);

  return (
    <>
      {/* Carregando */}
      {(httpStatus === 0 || httpStatus === 200 && carregandoPerfis) &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <CircularProgress color='primary' size={42} />
            <br /><Subtitle>Aguarde um momento...</Subtitle>
          </Content>
        </Container>
      }

      {/* Formulario */}
      {httpStatus === 200 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
          <Subtitle>Como você pode nos ajudar?</Subtitle>
          <Text gutterBottom light>(selecione uma ou mais opções)</Text>
          <div>{obterOpcoesCheckbox}</div>
            <Button
              fullWidth
              disabled={perfisSelecionados.length === 0}
              variant='contained'
              color='primary'
              onClick={salvaVoluntario}>
              Confirmar
            </Button>
          </Content>
        </Container>
      }

      {/* Falhou */}
      {httpStatus === 500 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <Title gutterBottom>Ops...</Title>
            <Text gutterBottom align='center'>
              Parece que o servidor respondeu de forma inesperada.
            </Text>
            <br />

            <Button
              variant='outlined'
              color='inherit'
              onClick={() => window.location.reload()}>
              TENTAR NOVAMENTE
            </Button>
          </Content>
        </Container>
      }
    </>
  );
}
