import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Box, createMuiTheme, CssBaseline, responsiveFontSizes, Theme, ThemeProvider } from '@material-ui/core';
import { persistor, store } from "./_redux/store";

import {
  Cadastro,
  Privacidade,
  Voluntariado,
  Evento
} from './fluxos';

import {
  temaPadrao,
  temaEnrico,
  temaReinaldo,
  temaRogerio,
  temaTania,
  temaLibcom,
  temaArco,
} from './styles/themes';

import useStyles from './styles';
import './_recursos/css/base.css';
import { ehSistemaDeputado, getDominio } from './_recursos/js/util';

import AcessoAplicativo from './fluxos/AcessoAplicativo';
import Confirmacao from './fluxos/AcessoAplicativo/paginas/Final';
import CadastroSimples from './fluxos/CadastroSimples';
import EventoPublico from './fluxos/EventoPublico';
import Inscricao from './fluxos/Inscricao';
// import _404 from './paginas/Erro/_404';


function App() {
  const classes = useStyles();
  const dominioAtual = getDominio();
  const [schema, setSchema] = useState<IState>();
  const dispatch = useDispatch();

  let stateApp = useSelector((state: IState) => state.app);

  useEffect(() => {
    let temaSelecionado: Theme;

    // TODO: remover quando link antigo entrar em desuso.
    const codigoReferencia = stateApp.hash
      ? stateApp.hash.substring(0, 6)
      : stateApp.referencia;

    const deputado = ehSistemaDeputado(dominioAtual)

    dispatch({ type: 'ALTERA_DEPUTADO', deputado });

    if (deputado) {
      switch (dominioAtual) {
        case 'enricomisasi':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaEnrico.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaEnrico));
          break;

        case 'reinaldoalguz':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaReinaldo.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaReinaldo));
          break;

        case 'rogeriossantos':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaRogerio.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaRogerio));
          break;

        case 'taniafruchi':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaTania.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaTania));
          break;

        default: // nunca entrará aqui, apenas para sempre associar um valor à `temaSelecionado`
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaPadrao.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaPadrao));
          break;
      }
    } else {
      switch (dominioAtual) {
        case 'arco':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaArco.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaArco));
          break;

        case 'libcom':
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaLibcom.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaLibcom));
          break;

        default:
          dispatch({ type: 'ALTERA_COR_PRIMARIA', corPrimaria: temaPadrao.palette.primary.main });
          temaSelecionado = responsiveFontSizes(createMuiTheme(temaPadrao));
      }
    }


    setSchema({
      referencia: stateApp.referencia || stateApp.hash,
      valor: codigoReferencia,
      tema: temaSelecionado,
    });
  }, [stateApp.referencia, stateApp.hash, dominioAtual, dispatch]);

  return (
    <>
      {
        schema &&
          <ThemeProvider theme={schema.tema}>
            <Box bgcolor="primary.main" className={classes.box}></Box>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <Switch>
                  {/* <Route path="/:referencia([0-9]{4}[a-z]{2})/:idUsuario?/:assinaturaUrl?" component={Inicial} exact /> */}
                  <Route path="/confirmacao" component={Confirmacao} />
                  <Route path="/acesso-aplicativo" component={AcessoAplicativo} />

                  <Route path="/inscricao/:hash" component={Evento} />
                  <Route path="/convite-publico/:hash" component={EventoPublico} />

                  <Route path="/termo/voluntariado/:hash?" component={Voluntariado} />
                  <Route path="/privacidade/:hash" component={Privacidade} />
                  <Route path="/termo/privacidade/:hash" component={Privacidade} />

                  <Route path="/s/:referencia" component={CadastroSimples} />
                  <Route path="/s2/:referencia" component={CadastroSimples} />

                  <Route path="/cadastro/:referencia(bcom.+)" component={Cadastro} />
                  <Route path="/cadastro/:referencia(co.+)" component={Cadastro} />
                  <Route path="/cadastro/:referencia([0-9]{4}[a-z]{2}.*)" component={Cadastro} />
                  <Route path="/cadastro" component={Cadastro} />

                  <Route path="/:hash?" component={Inscricao} />
                  {/* TODO: Rota fallback global indicando erro na rota sem quebrar os fluxos */}
                  {/* <Route path="*" component={_404} /> */}
                </Switch>
              </BrowserRouter>
            </PersistGate>
          </ThemeProvider>
      }
    </>
  );
}

if (navigator.vendor.match(/apple/i) && navigator.userAgent.match(/version\/15/i)) {
  document.body.style.paddingBottom = 'env(safe-area-inset-bottom)';
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
