import React, { memo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';

import { Content } from '../Interface';

import { DialogContentStyled } from './styles';

interface IDialogProps {
  abrir: boolean,
  setAbrir: any,
  titulo: string,
  conteudo: string,
  idTermoTipo?: number,
  carregando?: boolean,
}

function MuiDialog({ abrir, setAbrir, titulo, conteudo, carregando, idTermoTipo }: IDialogProps) {

  const handleClose = () => {
    setAbrir(false);
  };

  const baixarContrato = () => {
    window.print();
  }

  return (
    <div>
      <Dialog
        open={abrir}
        onClose={handleClose}
      >
        <DialogTitle>{titulo}</DialogTitle>
        {!carregando ? (
          <DialogContentStyled dangerouslySetInnerHTML={{__html: `<div> ${conteudo} </div>`}} />
          ) : (
            <Content verticalCenter horizontalCenter padding>
              <CircularProgress color='primary' size={42} />
            </Content>
          )}
        <DialogActions>
          {(idTermoTipo === 7 || idTermoTipo === 2) && (
            <Button onClick={baixarContrato} color="primary">
              Imprimir Contrato
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(MuiDialog);