import { createMuiTheme } from "@material-ui/core/styles";

const temaLibcom = createMuiTheme({
  palette: {
    primary: {
      main: '#D7B24C',
      light: 'rgb(255,255,0)'
    },
  },
  typography: {
    fontSize: 20,
    htmlFontSize: 20
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 24,
      },
    },
  },
});

export default temaLibcom;
