import React, { memo } from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, Box } from '@material-ui/core';
import { Container, Content, Title, Subtitle } from "../Interface";

interface IFeedbackProps {
  titulo?: string,
  texto?: string,
}
interface IStyles {
  containerStyle: {
    color: string,
    background: string,
  },
  fw300: {
    fontSize: string,
    fontWeight: string,
  },
};

const useStyles = makeStyles<Theme, IStyles>((theme) => ({
  containerStyle: {
    color: '#fff',
    background: `linear-gradient(to bottom, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
  },
  fw300: {
    fontSize: '1.4rem',
    fontWeight: '400',
  },
} as any));


function Feedback({ titulo, texto = 'Sua escolha foi salva.' }: IFeedbackProps) {
  const classes = useStyles({} as IStyles);

  return (
    <Container className={classes.containerStyle}>
      <Content full verticalCenter horizontalCenter padding>
        <Title>
          {titulo}
        </Title>
        <Subtitle className={classes.fw300} align='center'>
          {texto}
        </Subtitle>
        <Box 
          mt='auto' 
          bottom={22}
          position='fixed'
          fontSize={12}
        >
          <Button
            fullWidth
            style={{ color: "white" } }
            size='large'
            onClick={ () => window.location.reload() }>
            Redefinir sua escolha
          </Button>
        </Box>
      </Content>
    </Container>
  );
}

export default memo(Feedback);