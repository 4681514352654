import React from 'react';
// import { Button } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';

import { Container, Content, Subtitle, Title } from '../../../../../componentes/Interface';

export default function Vereador() {
  // const historicoNavegacao = useHistory();
  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom>Pronto!</Title>
        <Subtitle gutterBottom align='center'>
          Dados enviados com sucesso. Obrigado.
        </Subtitle>
        {/* -<br/><br/>
        <Subtitle gutterBottom align='center'>
          Queremos contar com sua ajuda neste projeto.
          Deseja se tornar Voluntário?
        </Subtitle>
        <br/>

        <Button
          size='large'
          variant='contained'
          color='primary'
          onClick={() => historicoNavegacao.push('/cadastro/quero-ser-voluntario')}>
            SAIBA MAIS
        </Button> */}
      </Content>
    </Container>
  )
}