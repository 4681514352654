import { obtemAmbiente } from '../_recursos/js/util';

export type ApiName = 'api-enderecos' | 'api-pessoas' | 'api-eventos';

interface IApiProps {
  [api: string]: {
    local: string,
    develop: string,
    production: string
  }
}

const API: IApiProps = {
  'api-enderecos': {
    // local: 'http://localhost:3000',
    local: 'https://api-enderecos-develop.rj.r.appspot.com',
    develop: 'https://api-enderecos-develop.rj.r.appspot.com',
    production: 'https://api-enderecos-production.rj.r.appspot.com',
  },
  'api-pessoas': {
    // local: 'http://localhost:4040',
    local: 'https://api-pessoas-develop.rj.r.appspot.com',
    develop: 'https://api-pessoas-develop.rj.r.appspot.com',
    production: 'https://api-pessoas-production.rj.r.appspot.com',
  },
  'api-eventos': {
    // local: 'http://localhost:3030',
    local: 'https://api-eventos-develop-279420.rj.r.appspot.com',
    develop: 'https://api-eventos-develop-279420.rj.r.appspot.com',
    production: 'https://api-eventos-252119.appspot.com',
  },
};

export function obtemUrlApi(api: ApiName) {
  const ambiente = obtemAmbiente();
  return API[api][ambiente];
}