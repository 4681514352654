import React from 'react';
import { Button, CircularProgress, IconButton, Toolbar } from '@material-ui/core';

import { Container, Content } from '../../../componentes/Interface';
import Spinner from '../../../componentes/Spinner';
import { ArrowBack } from '@material-ui/icons';

export default function EstruturaEtapaFormulario({ etapaAtual, posicaoEtapaAtual, callbackAvancar, callbackRecuar, desabilitaBotao, spinner }: any) {

  const TEXTO_SPINNER_CPF = <>
    Aguarde um momento
  </>;

  return (
    <Container>
      {spinner && <Spinner texto={TEXTO_SPINNER_CPF}/>}
      {/* Toolbar */}
      <Content>
        <Toolbar>
          {posicaoEtapaAtual > 0 && 
            <IconButton onClick={callbackRecuar}>
              <ArrowBack />
            </IconButton>
          }
          {spinner && <CircularProgress color='primary' size={24}/>}
        </Toolbar>
      </Content>
      <Content full padding pb="3rem">
        {/* Campo do Input */}
        {etapaAtual.component}
        <br/><br/>

        <Button
          disabled={desabilitaBotao}
          size='large'
          variant='contained'
          color='primary'
          onClick={() => callbackAvancar()}
        >
          Continuar
        </Button>
      </Content>
    </Container>
  );
}