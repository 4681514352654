import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { get } from '../../../../_axios/requisicao';
import { primeiraMaiusculaTodasPalavras } from '../../../../_recursos/js/util';

import { INITIAL_STATE } from '../../../../_redux/reducers/endereco';

import { CamposEmLinha, CampoDeTextoOutlined } from '../styles';

import { regex, estadosOpcoes } from './constants';
import { IStoreRedux } from 'Types/Reducers';

interface EnderecoSimples {
  cidade: string;
  estado: string;
  idCidade: number | null;
}

interface ValidaEnderecoSimples {
  cidade: boolean;
  estado: boolean;
  idCidade: boolean;
}

const valoresPadrao = {
  ...INITIAL_STATE,
  bairro: "Não informado",
  logradouro: "Não informado",
  cep: "00000-000",
  numero: "S/N",
};

function CampoEnderecoSimples() {
  const cidade = useSelector<IStoreRedux, string>((store) => store.endereco?.cidade || '');
  const estado = useSelector<IStoreRedux, string>((store) => store.endereco?.estado || '');
  const idCidade = useSelector<IStoreRedux, string>((store) => store.endereco?.idCidade);
  const dispatch = useDispatch();

  const [valido, setValido] = useState<ValidaEnderecoSimples>({
    cidade: false,
    estado: false,
    idCidade: false
  });

  const aoSelecionarCidade = useCallback((_, valor: Pick<EnderecoSimples, 'cidade' | 'idCidade'> | null) => {
    if (!valor)
      return;

    const { cidade, idCidade } = valor;

    dispatch({
      type: 'ALTERA_ENDERECO',
      cidade,
      idCidade,
    });
  }, [dispatch]);

  const [cidadesOpcoes, setCidadesOpcoes] = useState<EnderecoSimples[]>([]);

  const refPesquisa = useRef(-1);

  const pesquisaCidades = useCallback(async (cidade) => {
    if (!estado) return;

    const retorno = await get('api-enderecos', `/cidades?uf=${estado}&nome=${cidade}`, {});

    if (retorno.status === 200) {
      const opcoes: EnderecoSimples[] = retorno.data
        .map((item: any) => ({
          idCidade: item.idcidade,
          cidade: item.cidade
        }));
      setCidadesOpcoes(opcoes);

      if (opcoes.length > 0 && opcoes[0].cidade.trim().toLowerCase() === cidade.toLowerCase())
        aoSelecionarCidade(undefined, opcoes[0]);
    }
  }, [aoSelecionarCidade, estado]);

  const aoSelecionarEstado = useCallback((e) =>
    dispatch({ type: 'ALTERA_ENDERECO', estado: e.target.value })
  , [dispatch]);

  const aoBuscarCidade = useCallback((_, value: string) => {
    const valor = primeiraMaiusculaTodasPalavras(value);

    window.clearTimeout(refPesquisa.current);
    dispatch({ type: 'ALTERA_ENDERECO', cidade: valor, idCidade: null });
    refPesquisa.current = window.setTimeout(() => pesquisaCidades(valor), 800);
  }, [dispatch, pesquisaCidades]);

  const listaDeEstados = useMemo(() => (
    estadosOpcoes.map(estado => (
      <MenuItem key={estado.sigla} value={estado.sigla}>
        {estado.nome}
      </MenuItem>
    ))
  ), []);

  useEffect(() => {
    dispatch({ type: 'ALTERA_ENDERECO', cidade: '', idCidade: null });
    pesquisaCidades('')
  }, [dispatch, pesquisaCidades]);

  useEffect(() => {
    const resultado = {
      cidade: regex.cidade.test(cidade),
      estado: regex.estado.test(estado),
      idCidade: idCidade ? regex.idCidade.test(idCidade.toString()) : false
    };
    setValido(resultado);
    const validado = Object.values(resultado).every(booleano => booleano);
    dispatch({ type: 'VALIDA_CAMPO', endereco: validado });
    if (validado)
      dispatch({ type: 'ALTERA_ENDERECO', cidade, estado, idCidade });
  }, [cidade, dispatch, estado, idCidade]);

  useEffect(() => {
    if (cidade === '')
      dispatch({ type: 'ALTERA_ENDERECO', cidade: null, idCidade: null });
  }, [cidade, dispatch]);

  /* Efeito de inicialização do componente */
  useEffect(() => {
    dispatch({ type: 'ALTERA_ENDERECO', ...valoresPadrao });
  }, [dispatch]);

  return (
    <CamposEmLinha>
      <CampoDeTextoOutlined
        select
        label='Estado'
        value={estado || ''}
        onChange={aoSelecionarEstado}
        style={{ marginRight: 12, width: '75%' }}
        SelectProps={{
          MenuProps: {
            style: { maxHeight: 400 },
          }
        }}
      >
        {listaDeEstados}
      </CampoDeTextoOutlined>

      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        fullWidth
        disabled={estado === ''}
        getOptionLabel={option => option.cidade}
        options={cidadesOpcoes}
        onChange={aoSelecionarCidade}
        onInputChange={aoBuscarCidade}
        renderInput={(params) =>
          <CampoDeTextoOutlined
            {...params}
            InputProps={{
              ref: params.InputProps.ref,
              title: params.disabled ? 'Selecione um estado para definir a cidade' : undefined,
            }}
            fullWidth
            label='Cidade'
            error={cidade !== '' && (!valido.cidade || !valido.idCidade)}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              value: cidade || ''
            }}
          />
        }
      />
    </CamposEmLinha>
  );
}

export default CampoEnderecoSimples;