import React from 'react';

import { Apelido, ComoConheceu, Cpf, DataNascimento, Escolaridade, EstadoCivil, Filhos, Nome, Profissao, Sexo } from '../../../paginas/Formulario/Dados';
import { Celular, Email } from '../../../paginas/Formulario/Contatos';
import { Endereco, EnderecoSimples } from '../../../paginas/Formulario/Endereco';
import { TermoEscola } from '../../../paginas/Formulario/Termos';

export function formataEtapas(etapas: any) {
  const etapasCustomizadas = [] as any[]
  function adicionar(caminho: string, render: () => JSX.Element) {
    etapasCustomizadas.push({
      path: caminho,
      render,
    });
  }

  // CPF sempre primeiro, caso possamos buscar os dados da pessoas no sistema
  if (/^inscricao-(padrao|com-cpf)$/.test(etapas.cpf)) adicionar('/formulario/dados/cpf', () => <Cpf />)

  // Nome, celular e email são os dados principais
  if (etapas.nome) adicionar('/formulario/dados/nome', () => <Nome />)

  // Caso tenha etapa apelido, vem depois do nome
  if (etapas.apelido) adicionar('/formulario/dados/apelido', () => <Apelido />)

  // Informações de contato
  if (etapas.celular) adicionar('/formulario/contatos/celular', () => <Celular />)

  if (/^(obrigatorio|simples)$/.test(etapas.email))
    adicionar('/formulario/contatos/email', () => <Email obrigatorio={etapas.email === 'obrigatorio'} />)

  // Informações pessoais
  if (etapas.data_nascimento)
    adicionar('/formulario/dados/data-nascimento', () => <DataNascimento />)

  if (etapas.sexo) adicionar('/formulario/dados/sexo', () => <Sexo />)

  // Informações familiares
  if (etapas.estado_civil) adicionar('/formulario/dados/estado-civil', () => <EstadoCivil />)

  if (etapas.filhos) adicionar('/formulario/dados/filhos', () => <Filhos />)

  // Informações profissionais
  if (etapas.escolaridade) adicionar('/formulario/dados/escolaridade', () => <Escolaridade />)

  if (etapas.profissao) adicionar('/formulario/dados/profissao', () => <Profissao />)

  // Endereco por ultimo, etapa mais complexa
  if (etapas.endereco)
    adicionar('/formulario/endereco', etapas.endereco === 'completo' ? () => <Endereco /> : () => <EnderecoSimples />)

  // Etapa de feedback
  if (etapas.como_conheceu) adicionar('/formulario/como-conheceu', () => <ComoConheceu />)

  // Configuração de termos
  if (etapas.termos.find((t: any) => t.idTermoTipo === 8))
    adicionar('/formulario/termo-adesao', () => <TermoEscola schema="libcom" />)

  return etapasCustomizadas
}