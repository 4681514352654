import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';

import { post } from '../../../../_axios/requisicao';

import { Container, Content, Text, Title } from '../../../../componentes/Interface';
import { IDados } from 'Types/Reducers/Dados';

import ArcoIcon from '../../../../_recursos/imagens/arco-icon.svg';
import LibcomIcon from '../../../../_recursos/imagens/libcom-icon.svg';

import Vereador from './Vereador';
import Confirmacao from './Confirmacao';
import { IStoreRedux } from 'Types/Reducers';


export default function Final({ config }: IPageConfigParams) {
  let { schema, irParaEvento = false } = config;
  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector<IStoreRedux, IDados>((state: IStoreRedux) => state.dados);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);

  const dispatch = useDispatch();

  const [httpStatus, setHttpStatus] = useState(0);
  const [schemaTemp, setSchemaTemp] = useState('');

  const { current: redirParaEvento } = useRef({ irParaEvento, cpf: dados.cpf || undefined });

  const enviaInscricao = useCallback(async () => {
    if (stateApp.inscrito) {
      setHttpStatus(200);
      return;
    }

    try {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
      const inscricao = { dados, contatos, endereco, termos };

      // TODO: melhorar esse tipo de configuração especifica do RA.
      // TODO: remover checagem especifica do ra quadno entrar em desuso.

      const hashOuRef = stateApp.hash
        ? { hash: stateApp.hash }
        : { referencia: stateApp.referencia/* , usuario: stateApp.idUsuario */ }

      const header = {
        ...hashOuRef,
        schema,
      }

      if (!stateApp.inscrito) {
        const retorno = await post('api-pessoas',  '/inscricoes',  inscricao, header);
        if (retorno) {
          setHttpStatus(retorno.status);
          dispatch({ type: 'ALTERA_IDPESSOA', idPessoa: retorno.data.dados.idpessoa });

          const evento = retorno.data.evento;

          if(evento) dispatch({ type: 'ALTERA_EVENTO', ...evento });
          if (!stateApp.idUsuario)
            dispatch({
              type: 'ALTERA_IDUSUARIO',
              idUsuario: retorno.data.dados.usualtera
                ? retorno.data.dados.usualtera
                : retorno.data.dados.usucadastro
            });
        }
      } else {
        setHttpStatus(200);
      }
    }
    catch (error) {
      setHttpStatus(500);
    }
    finally {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
    }
  },[stateApp.inscrito, stateApp.hash, stateApp.referencia, stateApp.idUsuario, dispatch, dados, contatos, endereco, termos, schema])

  const limpaInscricao = useCallback(async () => {
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_TERMOS'})
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: true });
  },[dispatch]);

  const getPaginaFinal = useCallback(() => {
    const verificacao = schema || schemaTemp;

    switch (verificacao) {
      case 'arco':
        return <Confirmacao redirParaEvento={redirParaEvento} tipoConfirmacao="arco" icone={ArcoIcon} />;

      case 'libcom':
        return <Confirmacao redirParaEvento={redirParaEvento} tipoConfirmacao="libcom" icone={LibcomIcon} />;

      case 'z00001ra':
      case 'z01453em':
      case 'z03843tf':
      case 'z00254js':
        return <Confirmacao redirParaEvento={redirParaEvento} tipoConfirmacao="deputado" />;

      default:
        return <Vereador />
    }
  }, [redirParaEvento, schema, schemaTemp]);

  useEffect(() => {
    enviaInscricao();
    if (!schema)
      setSchemaTemp(stateApp.referencia || `z0${stateApp.hash?.substring(6,0)}`)
  }, [enviaInscricao, schema, stateApp.hash, stateApp.referencia]);

  useEffect(()=>{
    if (httpStatus === 200)
      limpaInscricao();
  }, [httpStatus, limpaInscricao]);

  return (
    <>
      {/* Enviando */}
      {httpStatus === 0 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <CircularProgress color='primary' size={42}/>
            <br/><Title variant='h6'>Aguarde um momento...</Title>
          </Content>
        </Container>
      }

      {/* Concluido */}
      {httpStatus === 200 && getPaginaFinal()}

      {/* Falhou */}
      {httpStatus === 500 &&
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <Title gutterBottom>Ops...</Title>
            <Text gutterBottom align='center'>
              Parece que o servidor respondeu de forma inesperada.
            </Text>
            <br/>

            <Button
              variant='outlined'
              color='inherit'
              onClick={() => window.location.reload()}>
                TENTAR NOVAMENTE
            </Button>
          </Content>
        </Container>
      }
    </>
  );
}
