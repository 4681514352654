import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { isValid } from 'cpf';

export default function Cpf() {
  const valor = useSelector(state => state.dados.cpf);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);

  useEffect(() => valida(valor), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'cpf' }), [dispatch]);

  function altera(event) {
    let valorAlterado = event.target.value;
    valida(valorAlterado);
    dispatch({ type: `ALTERA_CPF`, cpf: valorAlterado });
  }

  function valida(valor) {
    const resultado = isValid(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <InputMask
        fullWidth
        label='CPF'
        mask='999.999.999-99'
        maskChar=' '
        type='tel'
        error={valido !== null && !valido}
        value={valor || ''}
        onChange={altera}
      >
        {(inputProps) => <TextField {...inputProps} />}
      </InputMask>
    </div>
  );
}
