import { Button } from '@material-ui/core';
import React, { memo } from 'react';
import { Container, Content, Text, Title } from '../../Interface';

function ServerError() {
  return (
    <Container>
      <Content full verticalCenter horizontalCenter padding>
        <Title gutterBottom>Ops...</Title>
        <Text gutterBottom align='center'>
          Parece que o servidor respondeu de forma inesperada.
        </Text>
        <br/>

        <Button
          variant='outlined'
          color='inherit'
          onClick={() => window.location.reload()}>
            TENTAR NOVAMENTE
        </Button>
      </Content>
    </Container>
  )
}

export default memo(ServerError)