import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

import { Container } from '../../../../../componentes/Interface';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Button from '@material-ui/core/Button';


export const ContainerStyled = withTheme(styled(Container)`
  && {
    color: #FFF;
    background: ${(props) => `linear-gradient(to bottom, ${props.theme.palette.primary.dark} 50%, ${props.theme.palette.primary.main});`}
  }
`);

export const IconeVoluntario = styled(HowToRegIcon)`
  margin-bottom: 0.2em;
  font-size: 5em;
`;

export const IconeInstituicao = styled.img`
  & {
    &.arco {
      width: 120px;
    }

    &.libcom {
      width: 100px;
    }

    margin-bottom: 3rem;
  }
`;

export const MarcaTexto = withTheme(styled.div`
  display: inline-block;
  padding: 0 3px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.primary.light};
`);

export const ObterLinkEvento = styled(Button)`
  && {
    color: #fff;
    font-size: 1.25rem;
    margin-top: 15px;
    background-color: rgba(255, 255, 255, .1);
    padding: 10px 25px;
  }
`;