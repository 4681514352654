import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

import { Container } from '../../../../componentes/Interface';
import HowToRegIcon from '@material-ui/icons/HowToReg';


export const ContainerStyled = withTheme(styled(Container)`

  && {
    color: #FFF;
    background: ${(props) => `linear-gradient(to bottom, ${props.theme.palette.primary.dark} 60%, ${props.theme.palette.primary.main})`};

    overflow-y: hidden;
  }
`);

export const IconeVoluntario = styled(HowToRegIcon)`
  margin-bottom: 0.2em;
  font-size: 5em;
`;

export const IconeLibcom = styled.img`
  width: 100px;
  margin-bottom: 3rem;
`;

export const BoletoContainer = styled.div`
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid rgba(255,255,255,0.1);
  border-width: thick;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;

  span {
    display: block;
    margin: 8px 0;
  }
`;

export const BoletoUrl = withTheme(styled.a`
  color: inherit;
  font-weight: 500;
  display: block;

  &:hover {
    color: ${(props) => props.theme.palette.primary.light};
  }
`);
