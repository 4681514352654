import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get } from "../../../_axios/requisicao";
import { INITIAL_STATE } from "../../../_redux/reducers/endereco";
import { primeiraMaiusculaTodasPalavras } from '../../../_recursos/js/util';

import { estadosOpcoes } from './constants';

const valoresPadrao = {
  ...INITIAL_STATE,
  bairro: "Não informado",
  logradouro: "Não informado",
  cep: "00000-000",
  numero: "S/N",
};

const regex = {
  idCidade: /^\d{1,}$/,
  estado: /[A-Z]{2}/i,
  cidade: /^[.a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/
};

export default function EnderecoSimples() {
  const cidade = useSelector(store => store.endereco?.cidade || '');
  const estado = useSelector(store => store.endereco?.estado || '');
  const idCidade = useSelector(store => store.endereco?.idCidade ?? null);
  const dispatch = useDispatch();

  const [valido, setValido] = useState({
    cidade: false,
    estado: false,
    idCidade: false
  });

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'endereco' }), [dispatch]);

  const aoSelecionarCidade = useCallback((_, valor) => {
    if (!valor)
      return;

    const { cidade, idCidade } = valor;

    dispatch({
      type: 'ALTERA_ENDERECO',
      cidade: primeiraMaiusculaTodasPalavras(cidade),
      idCidade,
    });
  }, [dispatch]);

  const aoSelecionarEstado = useCallback((e) =>
    dispatch({ type: 'ALTERA_ENDERECO', estado: e.target.value })
  , [dispatch]);

  const [cidadesOpcoes, setCidadesOpcoes] = useState([]);
  const refPesquisa = useRef(-1);

  const pesquisaCidades = useCallback(async (cidade) => {
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: true });
    const retorno = await get('api-enderecos', `/cidades?uf=SP&nome=${cidade}`);
    if (retorno.status === 200) {
      const opcoes = retorno.data.map(item => ({
        idCidade: item.idcidade,
        cidade: item.cidade
      }));
      setCidadesOpcoes(opcoes);

      if (opcoes.length === 1 && opcoes[0].cidade.trim().toLowerCase() === cidade.toLowerCase()) {
        aoSelecionarCidade(undefined, opcoes[0]);
      }
    }
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, [aoSelecionarCidade, dispatch]);

  const aoBuscarCidade = useCallback((_, value) => {
    window.clearTimeout(refPesquisa.current);
    dispatch({ type: 'ALTERA_ENDERECO', cidade: value, idCidade: null });
    refPesquisa.current = window.setTimeout(() => pesquisaCidades(value), 800);
  }, [dispatch, pesquisaCidades]);

  useEffect(() => {
    const { cidade: regraCidade, estado: regraEstado, idCidade: regraId } = regex;

    const resultado = {
      cidade: regraCidade.test(cidade),
      estado: regraEstado.test(estado),
      idCidade: idCidade ? regraId.test(idCidade.toString()) : false
    };
    setValido(resultado);
    const validado = Object.values(resultado).every(booleano => booleano);
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: validado });
  }, [cidade, dispatch, estado, idCidade]);

  useEffect(() => {
    dispatch({ type: `ALTERA_ENDERECO`, ...valoresPadrao });
  }, [dispatch]);

  return (
    <div className="etapa-formulario">
      <TextField
        fullWidth
        select
        label='Estado'
        onChange={aoSelecionarEstado}
        value={estado || ''}
      >
        {
          estadosOpcoes.map(estado =>
            <MenuItem key={estado.sigla} value={estado.sigla}>
              {estado.nome}
            </MenuItem>
          )
        }
      </TextField>

      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        getOptionLabel={option => option.cidade}
        options={cidadesOpcoes}
        onChange={aoSelecionarCidade}
        onInputChange={aoBuscarCidade}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Cidade'
            error={cidade !== '' && (!valido.cidade || !valido.idCidade)}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              value: (cidade || '')
            }}
          />
        }
      />
    </div>
  );
}
