import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

import { Container } from '../../../../../componentes/Interface';
import HowToRegIcon from '@material-ui/icons/HowToReg';


export const ContainerStyled = withTheme(styled(Container)`
  && {
    color: #FFF;
    background: ${(props) => `linear-gradient(to bottom, ${props.theme.palette.primary.dark} 60%, ${props.theme.palette.primary.main})`};

    overflow-y: hidden;
  }
`);

export const IconeVoluntario = styled(HowToRegIcon)`
  margin-bottom: 0.2em;
  font-size: 5em;
`;

export const IconeInstituicao = styled.img`
  & {
    &.arco {
      width: 120px;
    }

    &.libcom {
      width: 100px;
    }

    margin-bottom: 3rem;
  }
`;