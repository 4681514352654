import { ITermo, ITermoAction } from 'Types/Reducers/Termos';

const INITIAL_STATE = [] as ITermo[];

function termos(state = INITIAL_STATE, action: ITermoAction) {

  switch (action.type) {
    case 'ALTERA_TERMOS':
      return action.termos;

    case 'ADICIONA_TERMOS':
      const colecao = new Set(state.map(item => item.idTermo))
      const sobreescrito = state.map(termo => {
        const existe = action.termos.find(item => item.idTermo === termo.idTermo)
        if (existe) return existe
        else return termo
      });
      return [...sobreescrito, ...action.termos.filter(({ idTermo }) => !colecao.has(idTermo))];

    case 'RESET_TERMOS':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default termos;