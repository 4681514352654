import React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Subtitle, TextSmall } from '../Interface';
import { useState } from 'react';


const useStyles = makeStyles((Tema) => ({
  opcaoDetalhes: {
    paddingLeft: 35
  },
  MarginBottom: {
    marginBottom: 5
  }
}));


function Checkbox(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState(props.checked || false);
  
  function alteraCheckbox(event) {
    setChecked(event.target.checked);
    props.onChange(event);
  }

  return (
    <div className={props.gutterBottom && classes.MarginBottom }>
      <FormControlLabel
        label={props.rotulo}
        control={
          <MuiCheckbox
            color='primary'
            value={props.value}
            checked={checked}
            onChange={alteraCheckbox}
          />
        }
      />
      <br/>

      {props.descricao && 
        <TextSmall gutterBottom className={classes.opcaoDetalhes}>
          {props.descricao}
        </TextSmall>
      }
      <br/>
    </div>
  );
}


export default Checkbox;