import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import ArcoIcon from '../../_recursos/imagens/arco-icon.svg';
import LibcomIcon from '../../_recursos/imagens/libcom-icon.svg';

import { getDominio, getReferenciaPeloDominio, getSchema } from '../../_recursos/js/util';
import { Etapas, Inicial, Final } from './paginas';
import { Cpf, Nome } from '../../paginas/Formulario/Dados';
import { Celular, Email } from '../../paginas/Formulario/Contatos';
import { EnderecoSimples } from '../../paginas/Formulario/Endereco';

interface IRouterParams {
  referencia: string,
  idUsuario: string,
  assinaturaUrl: string,
}

export default function CadastroSimples() {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const historicoNavegacao = useHistory();

  const {
    referencia = getReferenciaPeloDominio(), // TODO: Permitir cadastro simples sem hash/referencia?
    // idUsuario,
    // assinaturaUrl
  } = useParams<IRouterParams>();


  const [schema, setSchema] = useState<string>('');
  const [termo, setTermo] = useState<IState | null>(null);
  const [icone, setIcone] = useState<string | null>(null);
  const [codigoReferencia, setCodigoReferencia] = useState<string>('');

  const [etapasCadastroSimples] = useState(() => {
    const caminhoInicial = match.path.match('/s/') ? '/s' : '/s2';

    const base = [
      { path: `${caminhoInicial}/formulario/dados/nome`, component: <Nome />},
      { path: `${caminhoInicial}/formulario/contatos/celular`, component: <Celular />},
      { path: `${caminhoInicial}/formulario/contatos/email`, component: <Email />},
      { path: `${caminhoInicial}/formulario/endereco`, component: <EnderecoSimples />},
    ];

    // remove ultima etapa (Endereco) quando é vereador
    if (getDominio() === 'infogab') base.pop();

    // adiciona etapa cpf no inicio do array
    if (caminhoInicial === '/s2') {
      base.unshift({ path: `${caminhoInicial}/formulario/dados/cpf`, component: <Cpf /> })
    }

    // une etapas de formulario à etapa final
    return [
      ...base,
      { path: `${caminhoInicial}/confirmacao`, component: <Redirect to={`${caminhoInicial}/confirmacao`} push />},
    ];
  });

  const capturaUri = useMemo(() => historicoNavegacao.location.pathname, [historicoNavegacao.location.pathname])

  const capturaEtapaAtual = useCallback((): IState | any => {
    if (etapasCadastroSimples.length) {
      let [etapaSelecionada] = etapasCadastroSimples.filter((etapa: any, index: number) => {
        if (etapa.path === capturaUri) {
          etapa.posicao = index;
          return etapa;
        }
        return false;
      });

      if (etapaSelecionada && Object.keys(etapaSelecionada).length)
        return etapaSelecionada;
      else
        return { posicao: 0, componente: <Redirect to={etapasCadastroSimples[0].path} push/> }
    }

    return;
  }, [capturaUri, etapasCadastroSimples]);


  const resetApp = useCallback(() => {
    dispatch({ type: 'ALTERA_HASH', hash: null });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_EVENTO' });
    dispatch({ type: 'RESET_TERMOS' });
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: false});
    dispatch({ type: 'ALTERA_INSCRITO_VOLUNTARIADO', inscritoVoluntariado: false});
    // dispatch({ type: 'ALTERA_IDUSUARIO', idUsuario });
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
  }, [dispatch]);

  const init = useCallback(() => {
    resetApp();

    if (referencia.match(/libcom|arco/) ) {
      dispatch({ type: 'ALTERA_REFERENCIA', referencia });
      setCodigoReferencia(referencia);
      setIcone(referencia === 'libcom' ? LibcomIcon : ArcoIcon);
    } else if (referencia.length < 10) {
      dispatch({ type: 'ALTERA_REFERENCIA', referencia });
      setCodigoReferencia(referencia);
    } else {
      dispatch({ type: 'ALTERA_HASH', hash: referencia });
      setCodigoReferencia(referencia.substring(0,6));
    }

    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
  }, [dispatch, referencia, resetApp]);

  useEffect(() => {
    if (codigoReferencia) {
      setSchema(getSchema(codigoReferencia?.substring(0, 6)));
    }
  }, [codigoReferencia]);

  const appConfig = {
    schema,
    referencia,
    // idUsuario,
    // assinaturaUrl,
    icone,
    termo,
    etapaPath: etapasCadastroSimples[0].path,
    setTermo,
    init
  }

  return (
    <Switch>
      {/* Página de Confirmação */}
      <Route
        path={etapasCadastroSimples[etapasCadastroSimples.length - 1]?.path} // ultima etapa
        render={() => <Final config={appConfig} />}
      />

      <Route
        path={`${match.path}/`}
        render={() => <Inicial config={appConfig} />}
        exact
      />

      {/* Etapas */}
      {capturaEtapaAtual().path
        && !capturaEtapaAtual().path.includes('confirmacao') && (
          <Route path={capturaEtapaAtual().path} exact>
            <Etapas
              etapas={etapasCadastroSimples}
              etapaAtual={capturaEtapaAtual()}
            />
          </Route>
      )}
    </Switch>
  )
}