import React, { memo, useContext } from 'react';
import InscricaoContext from '../../../../context';

const TextoVereador = () => {
  const { inscricao: { proprietario } } = useContext(InscricaoContext);

  return (
    <>
      Ao prosseguir, você será cadastrado gratuitamente
      na base de dados {proprietario.sexo === 1 ? 'do' : 'da'} <strong>{proprietario.apelido}</strong>.
      <br /><br />
      Ao clicar em <strong>ACEITO</strong>, você manifesta expressa concordância
      e aderência com a "Política de Privacidade de Dados", bem como nos fornece seu consentimento,
      para coletar, usar, processar, armazenar, ceder, tratar seus dados pessoais.
    </>
  );
}

export default memo(TextoVereador);
