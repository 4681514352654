import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import withTheme from '@material-ui/styles/withTheme';

export const FormularioSingular = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  & > .campo-formulario {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Subtitulo = styled.h3`
  margin: 1.5rem 0 1rem;
  font-weight: normal;
`;

export const BotaoCupom = withTheme(styled(Button)`
  & {
    align-self: flex-start;
    margin: .75rem 0;
    padding: .5rem 1.5rem;
    color: #000;
    background-color: ${props => props.theme.palette.primary.main} !important;

    &:hover {
      filter: brightness(1.1);
    }

    & svg {
      margin-right: .5rem;
    }
  }
`);