import React, { memo, useContext } from 'react';

import InscricaoContext from '../../../../context';

const getDeputadoNome = (schema: string) => {
  switch (schema) {
    case 'z00001ra': return 'Reinaldo Alguz'
    case 'z01453em': return 'Enrico Misasi'
    case 'z03843tf': return 'Tânia Fruchi'
    case 'z00254js': return 'Rogério Santos'
  }
}

// TODO: fix prefixo por sexo.
const TextoDeputado = () => {
  const { inscricao: { schema, idevento }} = useContext(InscricaoContext);

  const texto = (
    idevento
    ? ( // texto para eventos
      <>
        Você foi convidado para um evento.
        Precisamos de algumas informações suas para melhor organizar o mesmo, é simples e rápido.
      </>
    )
    : ( // texto padrao
      <>
        Ao prosseguir, você será cadastrado gratuitamente
        na base de dados {schema === 'z03843tf' ? 'da' : 'do'} <strong>{getDeputadoNome(schema)}</strong>.
      </>
    )
  )

  return (
    <>
      {texto}
      <br /><br />
      Clique em <strong>ACEITO</strong> para podermos continuar.

      {/* Ao clicar em <strong>ACEITO</strong>, você manifesta expressa concordância
      e aderência com a "Política de Privacidade de Dados", bem como nos fornece seu consentimento,
      para coletar, usar, processar, armazenar, ceder, tratar seus dados pessoais. */}
    </>
  )
};

export default memo(TextoDeputado);
