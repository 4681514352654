export const INITIAL_STATE = {
  // Endereço Residencial
  tipo: 1,
  cep: null,
  estado: null,
  idCidade: null,
  cidade: null,
  idBairro: null,
  bairro: null,
  idLogradouro: null,
  logradouro: null,
  numero: null,
  complemento: null
};

function endereco(state = null, action) {
  switch (action.type) {
    case 'ALTERA_ENDERECO':
      const trueAction = { ...action }
      delete trueAction.type;
      return {
        ...(state === null ? INITIAL_STATE : state),
        ...trueAction,
      };

    case 'RESET_ENDERECO':
      return null;

    default:
      return state;
  }
}

export default endereco;