import React from 'react';

import { Container, Content, Title } from '../Interface';
import { CircularProgress } from '@material-ui/core';

import useStyles from './styles';

interface ISpinnerProps {
  texto?: string | JSX.Element,
}

function Spinner({ texto = 'Aguarde um momento...' }: ISpinnerProps) {
  const classes = useStyles({} as any);

  return (
    <Container className={classes.container}>
      <Content full verticalCenter horizontalCenter padding>
        <CircularProgress color='primary' size={42} />
        <br /><Title align='center' variant='h6'>{texto}</Title>
      </Content>
    </Container>
  );
}

export default Spinner;