import React, { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { isValid } from 'cpf';

import InputMask from 'react-input-mask';

import { CampoDeTextoOutlined } from '../styles';
import { IStoreRedux } from 'Types/Reducers';

function CampoCpf() {
  const cpf = useSelector<IStoreRedux, string>((store) => store.dados.cpf || '');
  const dispatch = useDispatch();

  const [valido, setValido] = useState<boolean>(false);

  const aoAlterar = useCallback((e) =>
    dispatch({ type: 'ALTERA_CPF', cpf: e.target.value })
  , [dispatch]);

  useEffect(() => {
    const resultado = isValid(cpf);
    setValido(resultado);
    dispatch({ type: 'VALIDA_CAMPO', cpf: resultado });
    if (resultado)
      dispatch({ type: 'ALTERA_CPF', cpf: cpf });
  }, [dispatch, cpf]);

  useEffect(() => {
    if (cpf === '')
      dispatch({ type: 'ALTERA_CPF', cpf: null });
  }, [dispatch, cpf]);

  return (
    <div className="campo-formulario">
      <InputMask
        mask='999.999.999-99'
        maskChar=' '
        type='tel'
        value={cpf}
        onChange={aoAlterar}
      >
        {
          (inputProps: any) =>
            <CampoDeTextoOutlined
              {...inputProps}
              fullWidth
              label='CPF'
              error={cpf !== '' && !valido}
              autoComplete="off"
            />
        }
    </InputMask>
    </div>
  );
}

export default CampoCpf;