import { createMuiTheme } from "@material-ui/core/styles";

// Rogério Santos: #6FC099
const temaRogerio = createMuiTheme({
  palette: {
    primary: {
      main: '#009384',
      light: '#02af71',
    },
  },
  typography: {
    fontSize: 20,
    htmlFontSize: 20
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 24,
      },
    },
  },
});

export default temaRogerio;
