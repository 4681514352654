import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, TextField } from '@material-ui/core';
import { get } from "../../../_axios/requisicao";

export default function EstadoCivil() {
  const valor = useSelector(state => state.dados.idEstadoCivil);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);
  const [opcoes, setOpcoes] = useState(null);
  let valorAlterado = null;

  useEffect(() => {
    valida(valor);
    obtemOpcoes();
  }, []);

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'estado_civil' }), [dispatch]);

  function altera(event) {
    valorAlterado = event.target.value;
    dispatch({ type: `ALTERA_ESTADO_CIVIL`, idEstadoCivil: valorAlterado })
    valida(valorAlterado);
  }

  async function obtemOpcoes() {
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: true });
    const retorno = await get('api-pessoas', '/estados-civis?nome');
    if (retorno.status === 200) {
      const opcoes = retorno.data
        .filter(item => (item.id !== 7))
        .map(item => ({ id: item.id, descricao: item.nome }));
      setOpcoes(opcoes);
    }
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }

  function valida(valor) {
    const regex = /^[1-9][0-9]*$/;
    const resultado = regex.test(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <TextField
        fullWidth
        select
        label='Estado civil'
        error={valido !== null && !valido}
        value={valor || ''}
        autoComplete="off"
        onChange={altera}>
          {
            opcoes
              ? (
                opcoes.map(opcao =>
                  <MenuItem key={opcao.id} value={opcao.id}>
                    {opcao.descricao}
                  </MenuItem>
                )
              )
              : (
                <MenuItem key='carregando' value=''>
                  Carregando...
                </MenuItem>
              )
          }
      </TextField>
    </div>
  );
}
