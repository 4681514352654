import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get } from "../../../_axios/requisicao";

export default function Profissao() {
  const valor = useSelector(state => state.dados.idProfissao);
  const descricao = useSelector(state => state.dados.profissao);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);
  const [opcoes, setOpcoes] = useState([]);

  useEffect(() => {
    valida(valor);
    obtemProfissoes(descricao);
  }, []);

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'profissao' }), [dispatch]);

  function altera(event, value) {
    const idProfissao = value ? value.id : null;
    const profissao = value ? value.nome : '';

    obtemProfissoes(profissao)
    dispatch({ type: `ALTERA_PROFISSAO`, idProfissao, profissao });
    valida(idProfissao);
  }

  async function obtemProfissoes(palavraChave) {
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: true });
    const retorno = await get('api-pessoas', `/profissoes?nome=${palavraChave}`);
    if (retorno.status === 200) {
      setOpcoes(retorno.data);
    }
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }

  function valida(valor) {
    const regex = /^\d{1,}$/;
    const resultado = valor === null ? true : regex.test(valor);
    (valor !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  return (
    <div className="etapa-formulario">
      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        getOptionLabel={option => option.nome}
        options={opcoes}
        onChange={altera}
        onInputChange={(event, value) => {
          dispatch({
            type: `ALTERA_PROFISSAO`,
            idProfissao: null,
            profissao: value
          });
          obtemProfissoes(value)
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Profissão (opcional)'
            error={valido !== null && !valido}
            autoComplete='off'
            inputProps={{ ...params.inputProps, value: (descricao || '') }}
          />
        }
      />
    </div>
  );
}
