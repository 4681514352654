import React from 'react';

import { Redirect } from 'react-router-dom';

import { Celular, Email } from '../../../../paginas/Formulario/Contatos';
import { ComoConheceu, Cpf, DataNascimento, Nome } from '../../../../paginas/Formulario/Dados';
// import { Endereco, EnderecoSimples } from '../../paginas/Formulario/Endereco';
import { Endereco } from '../../../../paginas/Formulario/Endereco';
import { TermoEscola } from '../../../../paginas/Formulario/Termos';

import { EFLPublica } from '../../../../paginas/FormularioSingular';

type EtapaElement = { path: string, component: JSX.Element, posicao?: number };

export const multiEtapas = [
  { path: '/convite-publico/formulario/dados/cpf', component: <Cpf />},
  { path: '/convite-publico/formulario/dados/nome', component: <Nome />},
  { path: '/convite-publico/formulario/dados/data-nascimento', component: <DataNascimento />},
  { path: '/convite-publico/formulario/contatos/celular', component: <Celular />},
  { path: '/convite-publico/formulario/contatos/email', component: <Email obrigatorio />},
  { path: '/convite-publico/formulario/endereco', component: <Endereco />},
  { path: '/convite-publico/formulario/como-conheceu', component: <ComoConheceu /> },
  { path: '/convite-publico/formulario/termo-adesao', component: <TermoEscola schema="libcom" />},
  { path: '/convite-publico/confirmacao', component: <Redirect to="/convite-publico/confirmacao" push />},
] as EtapaElement[];

export const singleEtapas = [{ path: '/convite-publico/formulario', component: <EFLPublica /> }] as EtapaElement[];