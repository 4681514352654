import styled from 'styled-components';

export const Importante = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Mensagem = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top: 0;
`;