import styled from 'styled-components';

import HowToRegIcon from '@material-ui/icons/HowToReg';
import withTheme from '@material-ui/core/styles/withTheme';

import { Container } from '../../../../componentes/Interface';

export const ContainerStyled = withTheme(styled(Container)`
  && {
    color: #FFF;
    background: ${(props) => `linear-gradient(to bottom, ${props.theme.palette.primary.dark} 50%, ${props.theme.palette.primary.main});`}
  }
`);

export const IconeVoluntario = styled(HowToRegIcon)`
  margin-bottom: 0.2em;
  font-size: 5em;
`;

export const IconeInstituicao = styled.img`
  & {
    &.arco {
      width: 120px;
    }

    &.libcom {
      width: 100px;
    }

    margin-bottom: 3rem;
  }
`;