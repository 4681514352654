import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DataNascimento, Nome } from '../../paginas/Formulario/Dados';
import { Celular } from '../../paginas/Formulario/Contatos';
import { Endereco } from '../../paginas/Formulario/Endereco';
import { Container, Content, Text, Title } from '../../componentes/Interface';

import { useQuery } from '../../_hooks';

import { Etapas } from './paginas';

export default function AcessoAplicativo() {
  const dispatch = useDispatch();
  const historicoNavegacao = useHistory();
  const query = useQuery();

  const [schema, setSchema] = useState<string>('');
  const [erro, setErro] = useState<boolean>(false);

  const etapas = useMemo(() => {
    return [
      { path: '/acesso-aplicativo/formulario/dados/nome', component: <Nome />},
      { path: '/acesso-aplicativo/formulario/dados/data-nascimento', component: <DataNascimento />},
      { path: '/acesso-aplicativo/formulario/contatos/celular', component: <Celular />},
      { path: '/acesso-aplicativo/formulario/endereco', component: <Endereco />},
      { path: '/confirmacao', component: <Redirect to='/confirmacao' push/>},

    ];
  }, []);

  const init = useCallback(() => {
    document.title = 'App Instituição';

    const email = query.get('email');
    const provedor = query.get('provedor');
    const uid = query.get('uid');
    const schema = query.get('schema');

    if (email === null || provedor === null || uid === null || schema === null) {
      setErro(true);
      return;
    } else {
      setErro(false);
    }

    dispatch({ type: 'ALTERA_EMAIL', tipo: 1, endereco: email });
    dispatch({ type: 'ALTERA_PROVEDOR', provedor });
    dispatch({ type: 'ALTERA_UID', uid });
    dispatch({ type: 'ALTERA_REFERENCIA', referencia: schema });
    setSchema(schema);

    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
    historicoNavegacao.push(etapas[0].path);
  }, []);

  const capturaUri = useMemo(() => historicoNavegacao.location.pathname, [historicoNavegacao.location.pathname])

  const capturaEtapaAtual = useCallback((): IState => {
    let etapaSelecionada = etapas.filter((etapa: any, index) => {
      if (etapa.path === capturaUri) {
        etapa.posicao = index;
        return etapa;
      }
    })[0];
    if (etapaSelecionada && Object.keys(etapaSelecionada).length)
      return etapaSelecionada;
    else
      return { posicao: 0, componente: <Redirect to={etapas[0].path} push/> }
  }, [capturaUri, etapas]);

  useEffect(() => {
    if (historicoNavegacao.location.search !== '') {
      init();
    }
  }, []);

  return (
    <>
      {erro && (
        <Container>
          <Content full verticalCenter horizontalCenter padding>
            <Title gutterBottom align='center'>Ops...</Title>
            <Text gutterBottom align='center'>
              Acesso negado, necessário enviar <b>e-mail</b>, <b>provedor</b> e <b>uid</b> obtido pelo Google ou Apple.
            </Text>
          </Content>
        </Container>
      )}

      <Switch>
        {/* Etapas */}
        {capturaEtapaAtual().path && (
          <Route path={capturaEtapaAtual().path} exact>
            <Etapas etapas={etapas} etapaAtual={capturaEtapaAtual()} referencia={schema}/>
          </Route>
        )}
      </Switch>
    </>
  )
}