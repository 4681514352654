import { useEffect, useState } from 'react';

function useTentarNovamente(callback: (...args: any[]) => any) {
  const [tentarNovamente, setTentarNovamente] = useState(true);
  const retry = () => setTentarNovamente(true)

  useEffect(() => {
    if (tentarNovamente) {
      callback();
      setTentarNovamente(false);
    }
  }, [callback, tentarNovamente]);

  return retry;
}

export default useTentarNovamente;
