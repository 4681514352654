import { IVereador, IVereadorAction } from 'Types/Reducers/Vereador';


const INITIAL_STATE: IVereador = {
  nome: null,
  email: null,
  sexo: null,
  apelido: null,
};

function vereador(state = INITIAL_STATE, action: IVereadorAction) {
  switch (action.type) {
    case 'ALTERA_VEREADOR_NOME':
      return { ...state, nome: action.nome };

    case 'ALTERA_VEREADOR_EMAIL':
      return { ...state, email: action.email };

    case 'ALTERA_VEREADOR_SEXO':
      return { ...state, sexo: action.sexo };

    case 'ALTERA_VEREADOR_APELIDO':
      return { ...state, apelido: action.apelido };

    case 'RESET_VEREADOR':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default vereador;