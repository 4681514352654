import React from 'react';
import { useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { Inicial } from './paginas';

export default function Privacidade() {
  const match = useRouteMatch();

  document.title = 'Privacidade';
  
  return (
    <Switch>
      <Route 
        path={`${match.path}`}
        render={() => <Inicial />}
        exact
      />
    </Switch>
  )
}